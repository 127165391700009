"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Link = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const ViewComposer_1 = require("../../core/ViewComposer/ViewComposer");
const Link = (props) => {
    const sunContext = (0, ViewComposer_1.useSunContext)();
    return ((0, jsx_runtime_1.jsx)("a", { className: `Link${props.className ? ` ${props.className}` : ""}`, style: props.style, href: "javascript:;", onClick: () => {
            sunContext.router.navigate(props.href, props.params);
        }, children: props.children }));
};
exports.Link = Link;
