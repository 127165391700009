"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const store = {
    page: 0,
    menu: true,
    searchTypeStringValue: "",
    searchTarifStringValue: "",
    selectedTypes: [],
    removedTypesListView: false,
    selectedTarifs: [],
    removedTarifsListView: false
};
exports.default = store;
