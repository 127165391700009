"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NewCDCForm = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const astra_1 = require("astra");
const CDC_model_1 = require("../../../../../../models/CDC.model");
const TeachersPage_1 = require("./formPages/TeachersPage");
const FacultiesPage_1 = require("./formPages/FacultiesPage");
const TasksPage_1 = require("./formPages/TasksPage");
const getTeachersCDCs_1 = require("./getTeachersCDCs");
require("./newCDCFrom.scss");
function NewCDCForm(props) {
    const [state, setState] = (0, react_1.useState)({
        page: 0,
        teacherCDCs: null,
        teacherFaculty: null,
        tasks: null
    });
    const [teachersCDCs] = (0, react_1.useState)((0, getTeachersCDCs_1.getTeachersCDCs)(props.user, props.repositories));
    return ((0, jsx_runtime_1.jsx)(astra_1.TabsPages, { active: state.page, children: [
            (0, jsx_runtime_1.jsx)(TeachersPage_1.TeachersPage, { teachersCDCs: teachersCDCs, onPick: (teacherCDCs) => setState({ ...state, teacherCDCs, page: 1 }) }),
            (0, jsx_runtime_1.jsx)(FacultiesPage_1.FacultiesPage, { teacherCDCs: state.teacherCDCs, onPick: (teacherFaculty) => setState({ ...state, teacherFaculty, page: 2 }), back: () => setState({ ...state, page: 0, teacherCDCs: null }) }),
            (0, jsx_runtime_1.jsx)(TasksPage_1.TasksPage, { teacherFaculty: state.teacherFaculty, onPick: (tasks) => {
                    if (state.teacherCDCs && state.teacherFaculty && props.user && tasks.length > 0) {
                        let startDate, endDate;
                        tasks.forEach((task) => {
                            if (!startDate || startDate > task.date) {
                                startDate = new Date(task.date);
                            }
                            if (!endDate || endDate < task.date) {
                                endDate = new Date(task.date);
                            }
                        });
                        props.resolve(new CDC_model_1.CDC({
                            teacher: state.teacherCDCs?.teacher._id,
                            faculty: state.teacherFaculty?.faculty._id,
                            tasks: tasks.map(task => task._id),
                            author: props.user._id,
                            startDate,
                            endDate
                        }));
                    }
                }, back: () => setState({ ...state, page: 1, teacherFaculty: null }) }),
        ] }));
}
exports.NewCDCForm = NewCDCForm;
