"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Editor = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
require("./Editor.scss");
function Editor(props) {
    const width = 400;
    const margin = 16;
    const style = { ...props.style, width: `${width}px` };
    const arrowStyle = {};
    const side = props.config.days === 1
        ? "center"
        : props.taskContainer.left + (props.taskContainer.width / 2) < 50
            ? "right" : "left";
    if (props.taskContainer) {
        style.top = `${props.taskContainer.top + props.taskContainer.height / 2}%`;
        style.transform = `translateY(-${props.taskContainer.top + props.taskContainer.height / 2}%)`;
        arrowStyle.top = `calc(${(props.taskContainer.top + props.taskContainer.height / 2) / 100} * (100% - ${margin * 2}px) + ${margin / 3}px)`;
        if (side === "center") {
            style.left = `calc(${props.taskContainer.left + props.taskContainer.width / 1.5}% + ${margin * 0.66}px)`;
            style.transformOrigin = "left " + arrowStyle.top;
            arrowStyle.left = `-${margin * 0.66}px`;
        }
        else if (side === "right") {
            style.left = `calc(${props.taskContainer.left + props.taskContainer.width}% + ${margin * 0.66}px)`;
            style.transformOrigin = "left " + arrowStyle.top;
            arrowStyle.left = `-${margin * 0.66}px`;
        }
        else {
            style.left = `calc(${props.taskContainer.left}% - (${style.width} + ${margin * 0.5}px)`;
            style.transformOrigin = "right " + arrowStyle.top;
            arrowStyle.left = `${width - margin}px`;
        }
    }
    return ((0, jsx_runtime_1.jsx)("div", { className: `EditorPosition ${side}`, style: style, children: (0, jsx_runtime_1.jsxs)("div", { className: "EditorTransition", children: [(0, jsx_runtime_1.jsx)("div", { className: "editorArrow", style: arrowStyle }), (0, jsx_runtime_1.jsx)("div", { className: "editorContent", children: props.children })] }) }));
}
exports.Editor = Editor;
