"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Skills = exports.Skill = void 0;
const astra_1 = require("astra");
const repositoryName = "skill";
class Skill extends astra_1.Document {
    mainSkill;
    code;
    title;
    description;
    semester;
    parent;
    constructor(skill) {
        super(repositoryName, skill);
        this.mainSkill = skill.mainSkill || "";
        this.code = skill.code || "";
        this.title = skill.title || "";
        this.description = skill.description || "";
        this.semester = skill.semester;
        this.parent = skill.parent;
    }
    getCode = () => {
        const mainSkill = this.getRepositories().mainSkill.find(ms => ms._id === this.mainSkill);
        if (!mainSkill)
            return "erreur";
        const sameLevelSkills = !this.parent ? this.getRepositories().skill.filter(s => !s.parent) : this.getRepositories().skill.filter(s => s.parent === this.parent);
        if (!sameLevelSkills)
            return "erreur";
        const faculty = this.getRepositories().faculty.findById(mainSkill?.faculty);
        if (!faculty)
            return "erreur";
        let level = 0;
        let parent = this.getRepositories().skill.find(s => s._id === this.parent);
        while (parent) {
            level++;
            parent = this.getRepositories().skill.find(s => parent && s._id === parent.parent);
        }
        if (!this.semester) {
            return `${faculty.skillsLevels[level]?.sigle || ""}${mainSkill?.code || ""}`;
        }
        return `${faculty.skillsLevels[level]?.sigle || ""}${mainSkill?.code || ""}${this.semester && !(faculty.years.length === 1 && faculty.years[0] === 1) ? `.S${this.semester}` : ""}${sameLevelSkills.length > 1 && this.code ? `.M${this.code}` : ""}`;
    };
}
exports.Skill = Skill;
class Skills extends astra_1.Repository {
    constructor(docs) {
        super(repositoryName, Skills, Skill, docs);
    }
}
exports.Skills = Skills;
exports.default = new Skills();
