"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MonthNav = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_fontawesome_1 = require("@fortawesome/react-fontawesome");
const free_solid_svg_icons_1 = require("@fortawesome/free-solid-svg-icons");
const free_solid_svg_icons_2 = require("@fortawesome/free-solid-svg-icons");
const free_solid_svg_icons_3 = require("@fortawesome/free-solid-svg-icons");
const free_solid_svg_icons_4 = require("@fortawesome/free-solid-svg-icons");
require("./MonthNav.scss");
const MonthNav = (props) => {
    return ((0, jsx_runtime_1.jsxs)("div", { className: "MonthNav", children: [(0, jsx_runtime_1.jsx)(react_fontawesome_1.FontAwesomeIcon, { onClick: () => {
                    console.log("MiniCalendar, monthNav <= year");
                    const newDate = new Date(props.monthNavDate);
                    newDate.setFullYear(newDate.getFullYear() - 1);
                    if (!props.start || new Date(props.start) <= newDate) {
                        props.setMonthNavDate(newDate);
                    }
                    else if (props.start) {
                        props.setMonthNavDate(new Date(props.start));
                    }
                }, className: "monthNavIcon", icon: free_solid_svg_icons_2.faAngleDoubleLeft, size: "sm" }), (0, jsx_runtime_1.jsx)(react_fontawesome_1.FontAwesomeIcon, { onClick: () => {
                    console.log("MiniCalendar, monthNav <= month");
                    const newDate = new Date(props.monthNavDate);
                    newDate.setMonth(newDate.getMonth() - 1);
                    console.log("new monthNav date", newDate);
                    console.log("start", props.start);
                    console.log("end", props.end);
                    if (!props.start || new Date(props.start) <= newDate) {
                        console.log("1");
                        props.setMonthNavDate(newDate);
                    }
                    else if (props.start) {
                        console.log("2");
                        props.setMonthNavDate(new Date(props.start));
                    }
                }, className: "monthNavIcon", icon: free_solid_svg_icons_1.faAngleLeft, size: "sm" }), (0, jsx_runtime_1.jsx)("p", { children: props.monthNavDate.toLocaleDateString("fr", {
                    month: "long",
                    year: "numeric",
                }) }), (0, jsx_runtime_1.jsx)(react_fontawesome_1.FontAwesomeIcon, { onClick: () => {
                    console.log("MiniCalendar, monthNav => month");
                    const newDate = new Date(props.monthNavDate);
                    newDate.setMonth(newDate.getMonth() + 1);
                    if (!props.end || newDate <= new Date(props.end)) {
                        props.setMonthNavDate(newDate);
                    }
                    else if (props.end) {
                        props.setMonthNavDate(new Date(props.end));
                    }
                }, className: "monthNavIcon", icon: free_solid_svg_icons_3.faAngleRight, size: "sm" }), (0, jsx_runtime_1.jsx)(react_fontawesome_1.FontAwesomeIcon, { onClick: () => {
                    console.log("MiniCalendar, monthNav => year");
                    const newDate = new Date(props.monthNavDate);
                    newDate.setFullYear(newDate.getFullYear() + 1);
                    if (!props.end || newDate <= new Date(props.end)) {
                        props.setMonthNavDate(newDate);
                    }
                    else if (props.end) {
                        props.setMonthNavDate(new Date(props.end));
                    }
                }, className: "monthNavIcon", icon: free_solid_svg_icons_4.faAngleDoubleRight, size: "sm" })] }));
};
exports.MonthNav = MonthNav;
