"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const store = {
    menu: true,
    tabPage: 0,
    cdcPage: 1,
    cdcRowPerPage: 20,
    searchCDCStringValue: "",
    cdcStateFilter: ["sent", "validated"],
    cdcSelectionStartDate: null,
    cdcSelectionEndDate: null,
    cdcSelectionStrictDate: false,
    selectedCDCs: [],
    removedCDCsListView: false,
    contratPage: 1,
    contratRowPerPage: 20,
    searchContratStringValue: "",
    contratStateFilter: ["1"],
    contratTypeFilter: ["contrat"],
    contratSelectionStartDate: null,
    contratSelectionEndDate: null,
    contratSelectionStrictDate: false,
    selectedContrats: [],
    removedContratsListView: false,
};
exports.default = store;
