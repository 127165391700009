"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Tasks = exports.Task = void 0;
const astra_1 = require("astra");
const repositoryName = "task";
class Task extends astra_1.Document {
    author;
    date;
    duration;
    title;
    description;
    tarif;
    promotions;
    teachers;
    deadline;
    cancelled;
    cancelledTeachers;
    movedFrom;
    overlapingTasks;
    overlapingTask;
    cdcs;
    contrats;
    constructor(task) {
        super(repositoryName, task);
        this.date = new Date(task.date);
        this.author = task.author;
        this.title = task.title ? task.title : "";
        this.description = task.description ? task.description : "";
        this.duration = task.duration || 15;
        this.tarif = task.tarif;
        this.promotions = task.promotions || [];
        this.teachers = task.teachers || [];
        this.deadline = task.deadline;
        this.cancelled = task.cancelled;
        this.cancelledTeachers = task.cancelledTeachers || [];
        this.movedFrom = task.movedFrom ? new Date(task.movedFrom) : undefined;
        this.overlapingTasks = task.overlapingTasks;
        this.overlapingTask = task.overlapingTask;
        this.cdcs = task.cdcs;
        this.contrats = task.contrats;
    }
    getTeachersStates = () => {
        return this.memo(() => {
            const states = {
                cancelled: [],
                contratSigned: [],
                contratSent: [],
                cdcSigned: [],
                cdcValidated: [],
                cdcSent: [],
                pending: []
            };
            const [taskCdcs, taskContrats] = this.getContratAndCDC();
            const taskTeachers = this.getTeachers();
            taskTeachers.forEach(teacher => {
                const teacherContrat = taskContrats.find(contrat => contrat.teacher === teacher._id);
                if (this.cancelledTeachers?.includes(teacher._id)) {
                    return states.cancelled.push({ teacher, index: 0 });
                }
                if (teacherContrat) {
                    if (teacherContrat.signatureDate) {
                        return states.contratSigned.push({ teacher, index: 0 });
                    }
                    else {
                        return states.contratSent.push({ teacher, index: 0 });
                    }
                }
                else {
                    const teacherCdc = taskCdcs.find(cdc => cdc.teacher === teacher._id);
                    if (teacherCdc) {
                        if (teacherCdc.signatureDate) {
                            return states.cdcSigned.push({ teacher, index: 0 });
                        }
                        else if (teacherCdc.validated) {
                            return states.cdcValidated.push({ teacher, index: 0 });
                        }
                        else {
                            return states.cdcSent.push({ teacher, index: 0 });
                        }
                    }
                    else {
                        return states.pending.push({ teacher, index: 0 });
                    }
                }
            });
            let index = 0;
            const setIndex = (array) => array.forEach((ts) => {
                ts.index = index;
                index++;
            });
            setIndex(states.cancelled);
            setIndex(states.contratSigned);
            setIndex(states.contratSent);
            setIndex(states.cdcSigned);
            setIndex(states.cdcValidated);
            setIndex(states.cdcSent);
            setIndex(states.pending);
            return [states, index];
        }, [this.cancelled, ...this.teachers], "getTeachersState");
    };
    getPromotionsString = () => {
        return this.memo(() => {
            return this.promotions.reduce((string, promoSkill, i) => {
                const promotion = this.getRepositories().promotion.findById(promoSkill.promotion);
                if (promotion)
                    return `${string}${promotion.name}${i < this.promotions.length - 1 ? ", " : ""}`;
                else
                    return string;
            }, "");
        }, [...this.promotions.map(p => p.promotion)], "getPromotionsString");
    };
    getTeachers = () => {
        return this.memo(() => {
            return this.getRepositories().teacher.filter((teacher) => this.teachers.includes(teacher._id));
        }, [this.teachers.length], "getTeachers");
    };
    getType = () => {
        return this.memo(() => {
            const tarif = this.getRepositories().tarif.find((tarif) => tarif._id === this.tarif);
            if (!tarif)
                return;
            const type = this.getRepositories().taskType.find((type) => type._id === tarif.type);
            return type;
        }, [this.tarif], "getType");
    };
    getContratAndCDC = () => {
        return this.memo(() => {
            this.cdcs = this.getRepositories().cdc
                .filter((c) => c.tasks.includes(this._id));
            this.contrats = this.getRepositories().contrat
                .filter((contrat) => this.cdcs?.some(cdc => contrat.cdcs.includes(cdc._id)));
            return [this.cdcs, this.contrats];
        }, [this.getRepositories().cdc, this.getRepositories().contrat], "getContratAndCDC");
    };
}
exports.Task = Task;
class Tasks extends astra_1.Repository {
    constructor(docs) {
        super(repositoryName, Tasks, Task, docs);
    }
}
exports.Tasks = Tasks;
exports.default = new Tasks();
