"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const store = {
    clickDate: null,
    clickTask: null,
    editorState: "close",
    editing: null,
    mouseAction: null,
    message: "",
    lastEdited: null,
    firstDayOfTheWeek: null,
    tempTask: null,
};
exports.default = store;
