"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TaskTypes = exports.TaskType = void 0;
const astra_1 = require("astra");
const repositoryName = "taskType";
class TaskType extends astra_1.Document {
    sigle;
    name;
    annexe;
    planning;
    salle;
    constructor(taskType) {
        super(repositoryName, taskType);
        this.sigle = taskType.sigle || "";
        this.name = taskType.name || "";
        this.annexe = taskType.annexe ? true : false;
        this.planning = taskType.planning ? true : false;
        this.salle = taskType.salle ? true : false;
    }
}
exports.TaskType = TaskType;
class TaskTypes extends astra_1.Repository {
    constructor(docs) {
        super(repositoryName, TaskTypes, TaskType, docs);
    }
}
exports.TaskTypes = TaskTypes;
exports.default = new TaskTypes();
