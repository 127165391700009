"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Tarifs = exports.Tarif = void 0;
const astra_1 = require("astra");
const repositoryName = "tarif";
class Tarif extends astra_1.Document {
    type;
    name;
    faculties;
    contratTarif;
    prestationTarif;
    constructor(tarif) {
        super(repositoryName, tarif);
        this.type = tarif.type || "";
        this.name = tarif.name || "";
        this.faculties = tarif.faculties || [];
        this.contratTarif = tarif.contratTarif || 0;
        this.prestationTarif = tarif.prestationTarif || 0;
    }
}
exports.Tarif = Tarif;
class Tarifs extends astra_1.Repository {
    constructor(docs) {
        super(repositoryName, Tarifs, Tarif, docs);
    }
}
exports.Tarifs = Tarifs;
exports.default = new Tarifs();
