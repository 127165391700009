"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const astra_1 = require("astra");
const Task_model_1 = require("@/models/Task.model");
const reducer = (store, type, action, config, tasks, dispatch, onPost, onInternalPatch, onDelete, onClick) => {
    if (type !== "mousemove_grid") {
        console.log("ACTION", type, action);
    }
    switch (type) {
        default: return;
        case "changeId": {
            if (store.editing === action.oldId) {
                return { editing: action.newId, clickTask: { ...store.clickTask, _id: action.newId } };
            }
            return;
        }
        case "cancel": {
            if (store.editing) {
                return { editing: action.newId };
            }
            return;
        }
        case "close_editor": {
            return {
                editing: null,
                editorState: "close",
                clickDate: null,
                clickTask: null,
                mouseAction: null
            };
            return;
        }
        case "mousedown_filter": {
            return {
                editing: null,
                editorState: "close",
                clickDate: null,
                clickTask: null,
                mouseAction: null
            };
            return;
        }
        case "mousedown_grid": {
            const event = action.event;
            if (event.target.id !== "grid")
                return;
            if (!action.date)
                return console.error("mousedown. Aucune date.");
            const temp_id = (0, astra_1.randomId)();
            const forwardId = (newId) => {
                dispatch("changeId", { oldId: temp_id, newId });
            };
            if (onPost) {
                const newTask = onPost({
                    _id: temp_id,
                    date: action.date,
                    duration: config.step,
                }, forwardId);
                if (newTask) {
                    addClassToBody("resize");
                    return {
                        clickDate: new Date(action.date),
                        clickTask: newTask,
                        editing: temp_id,
                        mouseAction: "creating",
                        editorState: "close",
                    };
                }
            }
            return;
        }
        case "mousedown_top":
        case "mousedown_mid":
        case "mousedown_bot": {
            const event = action.event;
            if (event.target.className !== "eventListener top" && event.target.className !== "eventListener mid" && event.target.className !== "eventListener mid locked" && event.target.className !== "eventListener bot")
                return;
            if (!action.taskId)
                return console.error("action.taskID undefined");
            if (type === "mousedown_top" || type === "mousedown_bot")
                addClassToBody("resize");
            const clickedTask = tasks.find(task => task._id === action.taskId);
            if (!clickedTask)
                return console.error("clickedTask undefined");
            return {
                editorState: action.taskId === store.editing ? store.editorState : store.editorState === "open" ? "changing" : "close",
                editing: action.taskId,
                lastEdited: store.editing,
                clickTask: new Task_model_1.Task(clickedTask),
                mouseAction: type === "mousedown_top" ? "drag_top" : type === "mousedown_mid" ? "down_mid" : "drag_bot"
            };
            return;
        }
        case "mousemove_grid": {
            if (!action.date)
                return console.error("action.date undefined");
            if (!store.editing)
                return;
            if (!store.clickTask)
                return;
            if (!store.mouseAction)
                return;
            switch (store.mouseAction) {
                default: return;
                case "down_clone": {
                    if (!store.clickDate)
                        return { clickDate: action.date };
                    if (store.clickDate.getTime() !== action.date?.getDate()) {
                        const temp_id = (0, astra_1.randomId)();
                        const tempTask = { ...store.clickTask, _id: temp_id, ghost: true };
                        const tempTaskStart = new Date(tempTask.date);
                        tempTaskStart.setMinutes(tempTaskStart.getMinutes() + (tempTask.duration / 2));
                        return {
                            tempTask: tempTask,
                            mouseAction: "drag_clone",
                            editing: temp_id,
                            clickDate: tempTaskStart,
                            editorState: "close"
                        };
                    }
                    return;
                }
                case "drag_clone": {
                    if (!store.clickDate)
                        return;
                    if (store.tempTask) {
                        const newDate = new Date(action.date);
                        const clickDateMinutes = store.clickDate.getHours() * 60 + store.clickDate.getMinutes();
                        const clickTaskMinutes = store.clickTask.date.getHours() * 60 + store.clickTask.date.getMinutes();
                        newDate.setMinutes(newDate.getMinutes() - (clickDateMinutes - clickTaskMinutes));
                        if (newDate.getHours() < config.startHour) {
                            newDate.setHours(config.startHour);
                            newDate.setMinutes(0);
                        }
                        const taskEndDate = new Date(newDate);
                        taskEndDate.setMinutes((taskEndDate.getMinutes() + store.tempTask.duration));
                        if (taskEndDate.getHours() >= config.endHour) {
                            newDate.setHours(config.endHour);
                            newDate.setMinutes(-store.tempTask.duration);
                        }
                        return {
                            tempTask: { ...store.tempTask, date: newDate }
                        };
                    }
                    return;
                }
                case "down_mid": {
                    if (!store.clickDate)
                        return { clickDate: new Date(action.date) };
                    if (store.clickTask.cancelled || Boolean(store.clickTask.cdcs && store.clickTask.cdcs.length > 0))
                        return;
                    if (action.date.getTime() !== store.clickDate.getTime()) {
                        addClassToBody("grabbing");
                        return {
                            mouseAction: "drag_mid"
                        };
                    }
                    return;
                }
                case "drag_mid": {
                    if (!store.clickDate)
                        return { clickDate: action.date };
                    let [taskToPatch, delta] = getTaskAndDelta(tasks, config, store, action, store.clickDate);
                    if (taskToPatch) {
                        const newDate = new Date(action.date);
                        const clickDateMinutes = store.clickDate.getHours() * 60 + store.clickDate.getMinutes();
                        const clickTaskMinutes = store.clickTask.date.getHours() * 60 + store.clickTask.date.getMinutes();
                        newDate.setMinutes(newDate.getMinutes() - (clickDateMinutes - clickTaskMinutes));
                        if (newDate.getHours() < config.startHour) {
                            newDate.setHours(config.startHour);
                            newDate.setMinutes(0);
                        }
                        const taskEndDate = new Date(newDate);
                        taskEndDate.setMinutes((taskEndDate.getMinutes() + taskToPatch.duration));
                        if (taskEndDate.getHours() >= config.endHour) {
                            newDate.setHours(config.endHour);
                            newDate.setMinutes(-taskToPatch.duration);
                        }
                        if (taskToPatch.date.getTime() !== newDate.getTime()) {
                            return onInternalPatch({ date: newDate }, false);
                        }
                    }
                    return;
                }
                case "drag_top": {
                    let [taskToPatch, delta] = getTaskAndDelta(tasks, config, store, action);
                    if (taskToPatch) {
                        const newDate = new Date(store.clickTask.date);
                        let newDuration = taskToPatch.duration;
                        if (delta < store.clickTask.duration) {
                            newDate.setMinutes(newDate.getMinutes() + delta);
                            newDuration = store.clickTask.duration - delta;
                        }
                        else {
                            newDate.setMinutes(newDate.getMinutes() + store.clickTask.duration - config.step);
                            newDuration = delta - store.clickTask.duration + config.step * 2;
                        }
                        if (taskToPatch.duration !== newDuration || taskToPatch.date.getTime() !== newDate.getTime()) {
                            return onInternalPatch({ duration: newDuration, date: newDate }, false);
                        }
                    }
                    return;
                }
                case "creating":
                case "drag_bot": {
                    let [taskToPatch, delta] = getTaskAndDelta(tasks, config, store, action);
                    if (taskToPatch) {
                        const newDate = new Date(store.clickTask.date);
                        let newDuration = 0;
                        if (delta > 0) {
                            newDuration = delta + config.step;
                        }
                        else {
                            newDate.setMinutes(newDate.getMinutes() + delta);
                            newDuration = (delta * -1) + config.step;
                        }
                        if (taskToPatch.duration !== newDuration || taskToPatch.date.getTime() !== newDate.getTime()) {
                            return onInternalPatch({ date: newDate, duration: newDuration }, false);
                        }
                    }
                }
            }
            return;
        }
        case "mouseup_task": {
            removeClassFromBody("resize");
            removeClassFromBody("grabbing");
            if (action.taskId === store.editing) {
                if (store.mouseAction === "down_mid" && store.clickTask) {
                    onClick && onClick(store.clickTask);
                }
                if (store.editorState === "open") {
                    return {
                        clickDate: null,
                        lastEdited: null,
                        editorState: store.mouseAction !== "down_mid" ? "open" : "close",
                        editing: store.mouseAction !== "down_mid" ? store.editing : null,
                        mouseAction: null,
                        clickTask: null,
                    };
                }
                else if (store.mouseAction === "down_clone" && store.clickTask) {
                    const temp_id = (0, astra_1.randomId)();
                    const forwardId = (newId) => {
                        dispatch("changeId", { oldId: temp_id, newId });
                    };
                    if (onPost) {
                        onPost({ ...store.clickTask, _id: undefined }, forwardId);
                        return {
                            clickDate: new Date(action.date),
                            clickTask: null,
                            editing: temp_id,
                            mouseAction: null,
                            editorState: "open",
                        };
                    }
                    return;
                }
                else if (store.mouseAction === "drag_clone" && store.tempTask) {
                    const temp_id = store.tempTask._id;
                    const forwardId = (newId) => {
                        dispatch("changeId", { oldId: temp_id, newId });
                    };
                    if (onPost) {
                        const newTask = onPost(store.tempTask, forwardId);
                        return {
                            mouseAction: null,
                            editing: null,
                            tempTask: null,
                            clickDate: null,
                            clickTask: null
                        };
                    }
                    return;
                }
                else if ((store.mouseAction === "drag_top" || store.mouseAction === "drag_bot" || store.mouseAction === "drag_mid")) {
                    const taskToPatch = tasks.find(t => t._id === store.editing);
                    if (taskToPatch) {
                        onInternalPatch({ date: taskToPatch.date, duration: taskToPatch.duration }, true);
                        return {
                            mouseAction: null,
                            editing: null,
                            tempTask: null,
                            clickDate: null,
                            clickTask: null
                        };
                    }
                }
                else {
                    if (store.mouseAction === "creating") {
                        const taskToPatch = tasks.find(t => t._id === store.editing);
                        if (taskToPatch) {
                            console.log(taskToPatch);
                            onInternalPatch({ date: taskToPatch.date, duration: taskToPatch.duration }, true);
                        }
                        return {
                            editorState: "open",
                            mouseAction: null
                        };
                    }
                    else {
                        return {
                            clickTask: null,
                            clickDate: null,
                            lastEdited: null,
                            editorState: store.mouseAction === "down_mid" ? "open" : "close",
                            editing: store.mouseAction === "down_mid" ? store.editing : null,
                            mouseAction: null,
                        };
                    }
                }
            }
            else {
                return {
                    clickDate: null,
                    lastEdited: null,
                    editing: null,
                    mouseAction: null,
                };
            }
            return;
        }
        case "mouseup_grid": {
            removeClassFromBody("resize");
            removeClassFromBody("grabbing");
            if (store.mouseAction === "creating") {
                return {
                    editorState: "open",
                    mouseAction: null
                };
            }
            else if (store.mouseAction === "down_clone" && store.clickTask) {
                const temp_id = (0, astra_1.randomId)();
                const forwardId = (newId) => {
                    dispatch("changeId", { oldId: temp_id, newId });
                };
                if (onPost) {
                    const newTask = onPost({ ...store.clickTask, _id: undefined }, forwardId);
                    return {
                        clickDate: new Date(action.date),
                        clickTask: null,
                        editing: temp_id,
                        mouseAction: null,
                        editorState: "open",
                    };
                }
            }
            else if (store.mouseAction === "drag_clone" && store.tempTask) {
                const temp_id = store.tempTask._id;
                if (onPost) {
                    const newTask = onPost(store.tempTask, (newId) => {
                        dispatch("changeId", { oldId: temp_id, newId });
                    });
                    return {
                        mouseAction: null,
                        editing: null,
                        tempTask: null,
                        clickDate: null,
                        clickTask: null
                    };
                }
            }
            else if ((store.mouseAction === "drag_top" || store.mouseAction === "drag_bot" || store.mouseAction === "drag_mid")) {
                const taskToPatch = tasks.find(t => t._id === store.editing);
                if (taskToPatch) {
                    onInternalPatch({ date: taskToPatch.date, duration: taskToPatch.duration }, true);
                    return {
                        mouseAction: null,
                        editing: null,
                        tempTask: null,
                        clickDate: null,
                        clickTask: null
                    };
                }
            }
            else {
                return {
                    clickDate: null,
                    clickTask: null,
                    lastEdited: store.editing,
                    editing: store.editorState === "open" ? store.editing : null,
                    mouseAction: null,
                };
            }
            return;
        }
        case "mousedown_clone": {
            return {
                mouseAction: "down_clone",
                clickTask: action.task,
            };
        }
    }
};
exports.default = reducer;
function addClassToBody(className) {
    if (!document.body.className.includes(className)) {
        document.body.className += " " + className;
    }
}
function removeClassFromBody(className) {
    if (document.body.className.includes(className)) {
        document.body.className = document.body.className.replace(" " + className, "");
    }
}
function getTaskAndDelta(tasks, config, store, action, clickDate) {
    if (!store.editing) {
        console.error("store.editing undefined");
        return [null, 0];
    }
    clickDate ||= store.clickTask?.date;
    if (!clickDate) {
        console.error("clickDate undefined");
        return [null, 0];
    }
    if (!action.date) {
        console.error("action.date undefined");
        return [null, 0];
    }
    const taskToPatch = tasks.find(t => t._id === store.editing);
    if (!taskToPatch) {
        console.error("tackToPatch undefined");
        return [null, 0];
    }
    const clickMinutes = clickDate.getHours() * 60 + clickDate.getMinutes();
    const mouseMinutes = action.date.getHours() * 60 + action.date.getMinutes();
    let delta = mouseMinutes - clickMinutes;
    return [taskToPatch, delta];
}
