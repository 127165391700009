"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function emailBH(value) {
    if (value.length) {
        let string = value;
        const matchAt = string.match(/@/g);
        const matchDomain = string.match(/@[A-Za-z0-9]/g);
        if (string.search("@") < 0) {
            return string;
        }
        else if (matchAt && matchAt.length > 1) {
            return string;
        }
        else if (!matchDomain) {
            return string;
        }
        else {
            return string;
        }
    }
    else {
        return "";
    }
}
exports.default = emailBH;
