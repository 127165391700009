"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Row = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
require("./Row.scss");
const Row = (props) => {
    return ((0, jsx_runtime_1.jsx)("div", { className: `Row ${props.className || ""} ${props.flex ? "autoflex" : ""}`, style: {
            marginTop: typeof props.marginTop === "number" ? `${props.marginTop}em` : props.marginTop,
            marginBottom: typeof props.marginBottom === "number" ? `${props.marginBottom}em` : props.marginBottom,
            marginLeft: typeof props.marginLeft === "number" ? `${props.marginLeft}em` : props.marginLeft,
            marginRight: typeof props.marginRight === "number" ? `${props.marginRight}em` : props.marginRight,
            ...props.style
        }, children: props.children }));
};
exports.Row = Row;
