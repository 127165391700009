"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.dateFormatFR = exports.monthsLength = exports.weekDays = exports.monthList = void 0;
const monthList = [
    'janvier',
    'février',
    'mars',
    'avril',
    'mai',
    'juin',
    'juillet',
    'août',
    'septembre',
    'octobre',
    'novembre',
    'décembre',
];
exports.monthList = monthList;
const weekDays = ['lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi', 'dimanche'];
exports.weekDays = weekDays;
const monthsLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
exports.monthsLength = monthsLength;
const dateFormatFR = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
};
exports.dateFormatFR = dateFormatFR;
