"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function emailCH(value) {
    if (value.length) {
        let string = value;
        string = string.replace(/[^A-Za-zÀ-ÖØ-öø-ÿ0-9!#$%&'*+/=?^_`{}|~;.(),:;<>@[\]\\"-]+/g, "");
        string = string.replace(/^[!#$%&'*+/=?^_`,:;@|~;\\.-]*/g, "");
        string = string.replace(/([!#$%&'*+/=?^_`{}()[\],:;.<>@|~;\\-])\1+/g, match => {
            return match[0];
        });
        return string;
    }
    else {
        return "";
    }
}
exports.default = emailCH;
