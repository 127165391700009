"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MiniDaysBar = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
require("./MiniDaysBar.scss");
const MiniDaysBar = (props) => {
    const weekdays = props.week.days.map((day, index) => ((0, jsx_runtime_1.jsx)("span", { className: "miniWeekDay", children: day.weekDay[0] }, index)));
    return (0, jsx_runtime_1.jsx)("div", { className: "MiniDaysBar", children: weekdays });
};
exports.MiniDaysBar = MiniDaysBar;
