"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.search = void 0;
function search(array, searchKeys, searchString) {
    if (searchString.length > 0) {
        const words = searchString.trim().toLowerCase().split(" ");
        const searchScore = array.map((item) => {
            let score = 0;
            searchKeys.forEach((key) => {
                words.forEach((word) => {
                    if (item[key]) {
                        const objectProperty = String(item[key]).trim().toLowerCase();
                        if (objectProperty.includes(word)) {
                            score += 1;
                        }
                        if (word.includes(objectProperty)) {
                            score += 1;
                        }
                        if (objectProperty === word.toLowerCase()) {
                            score += 1;
                        }
                        if (objectProperty === searchString.trim().toLowerCase()) {
                            score += 1;
                        }
                    }
                });
            });
            return score;
        });
        const maxScore = Math.max(...searchScore);
        let searchResults = [];
        if (searchString.length > 0 && maxScore > 0) {
            for (let n = maxScore; n > 0; n -= 1) {
                searchScore.forEach((score, index) => {
                    if (score === n) {
                        searchResults.push(array[index]);
                    }
                });
            }
        }
        return searchResults;
    }
    else {
        return array;
    }
}
exports.search = search;
