"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.restore = void 0;
function restore(repo, ids) {
    const newRepoDocuments = [];
    const newRepoArchives = [];
    const newRepoRemovedArray = [];
    repo.removed.forEach((doc) => {
        if (ids.includes(doc._id)) {
            doc.removed = false;
            if (doc.archived) {
                newRepoArchives.push(doc);
            }
            else {
                newRepoDocuments.push(doc);
            }
        }
        else {
            newRepoRemovedArray.push(doc);
        }
    });
    const newRepoInstance = repo.set(repo.concat(newRepoDocuments));
    newRepoInstance.archives = [...newRepoInstance.archives, ...newRepoArchives];
    newRepoInstance.removed = newRepoRemovedArray;
    return newRepoInstance;
}
exports.restore = restore;
