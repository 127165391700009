"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Images = exports.Image = void 0;
const astra_1 = require("astra");
const repositoryName = "image";
class Image extends astra_1.Document {
    author;
    url;
    type;
    constructor(image) {
        super(repositoryName, image);
        this.author = image.author;
        this.type = image.type;
        this.url = image.url;
    }
}
exports.Image = Image;
class Images extends astra_1.Repository {
    constructor(docs) {
        super(repositoryName, Images, Image, docs);
    }
}
exports.Images = Images;
exports.default = new Images();
