"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RepositoriesLoadingBar = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
require("./RepositoriesLoadingBar.scss");
const RepositoriesLoadingBar = (props) => {
    const [animate, setAnimate] = (0, react_1.useState)(false);
    const [done, setDone] = (0, react_1.useState)(false);
    const keys = Object.keys(props.repositories);
    const max = keys.length;
    const loaded = keys.reduce((count, repositoryName) => {
        return count + (props.repositories[repositoryName].loaded ? 1 : 0);
    }, 0);
    const state = loaded / max * 100;
    if (loaded !== max && !animate)
        setAnimate(true);
    return ((0, jsx_runtime_1.jsx)("div", { className: `RepositoriesLoadingBar ${loaded === max ? "done" : ""} ${animate ? "animate" : ""}`, children: (0, jsx_runtime_1.jsxs)("div", { className: "backgroundBar", children: [(0, jsx_runtime_1.jsx)("div", { className: "innerBar", style: {
                        width: `${state.toFixed(2)}%`,
                        left: `${(50 - (state / 2)).toFixed(2)}%`
                    } }), (0, jsx_runtime_1.jsx)("p", { className: "loading", children: "CHARGEMENT" }), (0, jsx_runtime_1.jsx)("p", { className: "checkmark", children: "\u2713" })] }) }));
};
exports.RepositoriesLoadingBar = RepositoriesLoadingBar;
