"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var PERMISSIONS;
(function (PERMISSIONS) {
    PERMISSIONS["SUPERADMIN"] = "SUPERADMIN";
    PERMISSIONS["OVERVIEW_EVERY_FACULTIES"] = "OVERVIEW_EVERY_FACULTIES";
    PERMISSIONS["RESPONSABLE_PEDAGOGIQUE"] = "RESPONSABLE_PEDAGOGIQUE";
    PERMISSIONS["FORMATEUR_REFERENT"] = "FORMATEUR_REFERENT";
    PERMISSIONS["EXPORT_FORMATEURS"] = "EXPORT_FORMATEURS";
    PERMISSIONS["RELEVE_FORMATEURS_SIMPLE"] = "RELEVE_FORMATEURS_SIMPLE";
    PERMISSIONS["RELEVE_FORMATEURS"] = "RELEVE_FORMATEURS";
    PERMISSIONS["VIEW_CALENDRIER"] = "VIEW_CALENDRIER";
    PERMISSIONS["TASK_POST"] = "TASK_POST";
    PERMISSIONS["TASK_PATCH"] = "TASK_PATCH";
    PERMISSIONS["TASK_REMOVE"] = "TASK_REMOVE";
    PERMISSIONS["TASK_RESTORE"] = "TASK_RESTORE";
    PERMISSIONS["TASK_ARCHIVE"] = "TASK_ARCHIVE";
    PERMISSIONS["TASK_UNARCHIVE"] = "TASK_UNARCHIVE";
    PERMISSIONS["TASK_DESTROY"] = "TASK_DESTROY";
    PERMISSIONS["TASK_CANCEL"] = "TASK_CANCEL";
    PERMISSIONS["VIEW_CDCS"] = "VIEW_CDCS";
    PERMISSIONS["VIEW_CDCS_SITE"] = "VIEW_CDCS_SITE";
    PERMISSIONS["VIEW_CDCS_FACULTY"] = "VIEW_CDCS_FACULTY";
    PERMISSIONS["VIEW_ALL_CDCS"] = "VIEW_ALL_CDCS";
    PERMISSIONS["CDC_POST"] = "CDC_POST";
    PERMISSIONS["CDC_PATCH"] = "CDC_PATCH";
    PERMISSIONS["CDC_REMOVE"] = "CDC_REMOVE";
    PERMISSIONS["CDC_RESTORE"] = "CDC_RESTORE";
    PERMISSIONS["CDC_ARCHIVE"] = "CDC_ARCHIVE";
    PERMISSIONS["CDC_UNARCHIVE"] = "CDC_UNARCHIVE";
    PERMISSIONS["CDC_DESTROY"] = "CDC_DESTROY";
    PERMISSIONS["CDC_SIGN"] = "CDC_SIGN";
    PERMISSIONS["CDC_EDIT_SIGN"] = "CDC_EDIT_SIGN";
    PERMISSIONS["CDC_CANCEL_SIGN"] = "CDC_CANCEL_SIGN";
    PERMISSIONS["CDC_DOWNLOAD"] = "CDC_DOWNLOAD";
    PERMISSIONS["CDC_CANCEL"] = "CDC_CANCEL";
    PERMISSIONS["CDC_VALIDATE"] = "CDC_VALIDATE";
    PERMISSIONS["VIEW_CONTRATS"] = "VIEW_CONTRATS";
    PERMISSIONS["CONTRAT_POST"] = "CONTRAT_POST";
    PERMISSIONS["CONTRAT_PATCH"] = "CONTRAT_PATCH";
    PERMISSIONS["CONTRAT_REMOVE"] = "CONTRAT_REMOVE";
    PERMISSIONS["CONTRAT_RESTORE"] = "CONTRAT_RESTORE";
    PERMISSIONS["CONTRAT_ARCHIVE"] = "CONTRAT_ARCHIVE";
    PERMISSIONS["CONTRAT_UNARCHIVE"] = "CONTRAT_UNARCHIVE";
    PERMISSIONS["CONTRAT_DESTROY"] = "CONTRAT_DESTROY";
    PERMISSIONS["CONTRAT_SIGN"] = "CONTRAT_SIGN";
    PERMISSIONS["CONTRAT_EDIT_SIGN"] = "CONTRAT_EDIT_SIGN";
    PERMISSIONS["CONTRAT_CANCEL_SIGN"] = "CONTRAT_CANCEL_SIGN";
    PERMISSIONS["CONTRAT_DOWNLOAD"] = "CONTRAT_DOWNLOAD";
    PERMISSIONS["VIEW_FILIERES"] = "VIEW_FILIERES";
    PERMISSIONS["VIEW_COMPETENCES"] = "VIEW_COMPETENCES";
    PERMISSIONS["VIEW_PROMOTIONS"] = "VIEW_PROMOTIONS";
    PERMISSIONS["VIEW_FORMATEURS"] = "VIEW_FORMATEURS";
    PERMISSIONS["VIEW_SITES"] = "VIEW_SITES";
    PERMISSIONS["VIEW_TYPES"] = "VIEW_TYPES";
    PERMISSIONS["VIEW_TARIFS"] = "VIEW_TARIFS";
    PERMISSIONS["VIEW_UTILISATEURS"] = "VIEW_UTILISATEURS";
    PERMISSIONS["VIEW_ROLES"] = "VIEW_ROLES";
    PERMISSIONS["FACULTY_POST"] = "FACULTY_POST";
    PERMISSIONS["FACULTY_PATCH"] = "FACULTY_PATCH";
    PERMISSIONS["FACULTY_REMOVE"] = "FACULTY_REMOVE";
    PERMISSIONS["FACULTY_RESTORE"] = "FACULTY_RESTORE";
    PERMISSIONS["FACULTY_ARCHIVE"] = "FACULTY_ARCHIVE";
    PERMISSIONS["FACULTY_UNARCHIVE"] = "FACULTY_UNARCHIVE";
    PERMISSIONS["FACULTY_DESTROY"] = "FACULTY_DESTROY";
    PERMISSIONS["IMAGE_POST"] = "IMAGE_POST";
    PERMISSIONS["IMAGE_PATCH"] = "IMAGE_PATCH";
    PERMISSIONS["IMAGE_REMOVE"] = "IMAGE_REMOVE";
    PERMISSIONS["IMAGE_RESTORE"] = "IMAGE_RESTORE";
    PERMISSIONS["IMAGE_ARCHIVE"] = "IMAGE_ARCHIVE";
    PERMISSIONS["IMAGE_UNARCHIVE"] = "IMAGE_UNARCHIVE";
    PERMISSIONS["IMAGE_DESTROY"] = "IMAGE_DESTROY";
    PERMISSIONS["MAINSKILL_POST"] = "MAINSKILL_POST";
    PERMISSIONS["MAINSKILL_PATCH"] = "MAINSKILL_PATCH";
    PERMISSIONS["MAINSKILL_REMOVE"] = "MAINSKILL_REMOVE";
    PERMISSIONS["MAINSKILL_RESTORE"] = "MAINSKILL_RESTORE";
    PERMISSIONS["MAINSKILL_ARCHIVE"] = "MAINSKILL_ARCHIVE";
    PERMISSIONS["MAINSKILL_UNARCHIVE"] = "MAINSKILL_UNARCHIVE";
    PERMISSIONS["MAINSKILL_DESTROY"] = "MAINSKILL_DESTROY";
    PERMISSIONS["ORGANIZATION_POST"] = "ORGANIZATION_POST";
    PERMISSIONS["ORGANIZATION_PATCH"] = "ORGANIZATION_PATCH";
    PERMISSIONS["ORGANIZATION_REMOVE"] = "ORGANIZATION_REMOVE";
    PERMISSIONS["ORGANIZATION_RESTORE"] = "ORGANIZATION_RESTORE";
    PERMISSIONS["ORGANIZATION_ARCHIVE"] = "ORGANIZATION_ARCHIVE";
    PERMISSIONS["ORGANIZATION_UNARCHIVE"] = "ORGANIZATION_UNARCHIVE";
    PERMISSIONS["ORGANIZATION_DESTROY"] = "ORGANIZATION_DESTROY";
    PERMISSIONS["PROMOTION_POST"] = "PROMOTION_POST";
    PERMISSIONS["PROMOTION_PATCH"] = "PROMOTION_PATCH";
    PERMISSIONS["PROMOTION_REMOVE"] = "PROMOTION_REMOVE";
    PERMISSIONS["PROMOTION_RESTORE"] = "PROMOTION_RESTORE";
    PERMISSIONS["PROMOTION_ARCHIVE"] = "PROMOTION_ARCHIVE";
    PERMISSIONS["PROMOTION_UNARCHIVE"] = "PROMOTION_UNARCHIVE";
    PERMISSIONS["PROMOTION_DESTROY"] = "PROMOTION_DESTROY";
    PERMISSIONS["ROLE_POST"] = "ROLE_POST";
    PERMISSIONS["ROLE_PATCH"] = "ROLE_PATCH";
    PERMISSIONS["ROLE_REMOVE"] = "ROLE_REMOVE";
    PERMISSIONS["ROLE_RESTORE"] = "ROLE_RESTORE";
    PERMISSIONS["ROLE_ARCHIVE"] = "ROLE_ARCHIVE";
    PERMISSIONS["ROLE_UNARCHIVE"] = "ROLE_UNARCHIVE";
    PERMISSIONS["ROLE_DESTROY"] = "ROLE_DESTROY";
    PERMISSIONS["SITE_POST"] = "SITE_POST";
    PERMISSIONS["SITE_PATCH"] = "SITE_PATCH";
    PERMISSIONS["SITE_REMOVE"] = "SITE_REMOVE";
    PERMISSIONS["SITE_RESTORE"] = "SITE_RESTORE";
    PERMISSIONS["SITE_ARCHIVE"] = "SITE_ARCHIVE";
    PERMISSIONS["SITE_UNARCHIVE"] = "SITE_UNARCHIVE";
    PERMISSIONS["SITE_DESTROY"] = "SITE_DESTROY";
    PERMISSIONS["SKILL_POST"] = "SKILL_POST";
    PERMISSIONS["SKILL_PATCH"] = "SKILL_PATCH";
    PERMISSIONS["SKILL_REMOVE"] = "SKILL_REMOVE";
    PERMISSIONS["SKILL_RESTORE"] = "SKILL_RESTORE";
    PERMISSIONS["SKILL_ARCHIVE"] = "SKILL_ARCHIVE";
    PERMISSIONS["SKILL_UNARCHIVE"] = "SKILL_UNARCHIVE";
    PERMISSIONS["SKILL_DESTROY"] = "SKILL_DESTROY";
    PERMISSIONS["TARIF_POST"] = "TARIF_POST";
    PERMISSIONS["TARIF_PATCH"] = "TARIF_PATCH";
    PERMISSIONS["TARIF_REMOVE"] = "TARIF_REMOVE";
    PERMISSIONS["TARIF_RESTORE"] = "TARIF_RESTORE";
    PERMISSIONS["TARIF_ARCHIVE"] = "TARIF_ARCHIVE";
    PERMISSIONS["TARIF_UNARCHIVE"] = "TARIF_UNARCHIVE";
    PERMISSIONS["TARIF_DESTROY"] = "TARIF_DESTROY";
    PERMISSIONS["TASKTYPE_POST"] = "TASKTYPE_POST";
    PERMISSIONS["TASKTYPE_PATCH"] = "TASKTYPE_PATCH";
    PERMISSIONS["TASKTYPE_REMOVE"] = "TASKTYPE_REMOVE";
    PERMISSIONS["TASKTYPE_RESTORE"] = "TASKTYPE_RESTORE";
    PERMISSIONS["TASKTYPE_ARCHIVE"] = "TASKTYPE_ARCHIVE";
    PERMISSIONS["TASKTYPE_UNARCHIVE"] = "TASKTYPE_UNARCHIVE";
    PERMISSIONS["TASKTYPE_DESTROY"] = "TASKTYPE_DESTROY";
    PERMISSIONS["TEACHER_POST"] = "TEACHER_POST";
    PERMISSIONS["TEACHER_PATCH"] = "TEACHER_PATCH";
    PERMISSIONS["TEACHER_REMOVE"] = "TEACHER_REMOVE";
    PERMISSIONS["TEACHER_RESTORE"] = "TEACHER_RESTORE";
    PERMISSIONS["TEACHER_ARCHIVE"] = "TEACHER_ARCHIVE";
    PERMISSIONS["TEACHER_UNARCHIVE"] = "TEACHER_UNARCHIVE";
    PERMISSIONS["TEACHER_DESTROY"] = "TEACHER_DESTROY";
    PERMISSIONS["USER_POST"] = "USER_POST";
    PERMISSIONS["USER_PATCH"] = "USER_PATCH";
    PERMISSIONS["USER_REMOVE"] = "USER_REMOVE";
    PERMISSIONS["USER_RESTORE"] = "USER_RESTORE";
    PERMISSIONS["USER_ARCHIVE"] = "USER_ARCHIVE";
    PERMISSIONS["USER_UNARCHIVE"] = "USER_UNARCHIVE";
    PERMISSIONS["USER_DESTROY"] = "USER_DESTROY";
})(PERMISSIONS || (PERMISSIONS = {}));
exports.default = PERMISSIONS;
