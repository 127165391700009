"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Sites = exports.Site = void 0;
const astra_1 = require("astra");
const repositoryName = "site";
class Site extends astra_1.Document {
    name;
    fullName;
    location;
    referent;
    directorTitle;
    directorName;
    directorCivilite;
    address;
    structure;
    webLink;
    signatureFile;
    constructor(site) {
        super(repositoryName, site);
        this.name = site.name || "";
        this.fullName = site.fullName || "";
        this.location = site.location || "";
        this.referent = site.referent;
        this.directorTitle = site.directorTitle || "";
        this.directorCivilite = site.directorCivilite || "";
        this.directorName = site.directorName || "";
        this.address = site.address || "";
        this.structure = site.structure || "";
        this.webLink = site.webLink || "";
        this.signatureFile = site.signatureFile || "";
    }
    toString = () => `${this.name} (${this.location})`;
}
exports.Site = Site;
class Sites extends astra_1.Repository {
    constructor(docs) {
        super(repositoryName, Sites, Site, docs);
    }
}
exports.Sites = Sites;
exports.default = new Sites();
