"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const store = {
    page: 1,
    rowPerPage: 20,
    menu: true,
    searchStringValue: "",
    selected: [],
    removedListView: false
};
exports.default = store;
