"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MainSkills = exports.MainSkill = void 0;
const astra_1 = require("astra");
const repositoryName = "mainSkill";
class MainSkill extends astra_1.Document {
    faculty;
    code;
    title;
    referents;
    constructor(mainSkill) {
        super(repositoryName, mainSkill);
        this.faculty = mainSkill.faculty;
        this.code = mainSkill.code || "";
        this.title = mainSkill.title || "";
        this.referents = mainSkill.referents || [];
    }
}
exports.MainSkill = MainSkill;
class MainSkills extends astra_1.Repository {
    constructor(docs) {
        super(repositoryName, MainSkills, MainSkill, docs);
    }
}
exports.MainSkills = MainSkills;
exports.default = new MainSkills();
