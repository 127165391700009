"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Contrats = exports.Contrat = exports.formatNumber = void 0;
const astra_1 = require("astra");
const pdfs_1 = require("@/pdfs");
function formatNumber(match) {
    match = String(match);
    return match.length === 1 ? "0" + match : match;
}
exports.formatNumber = formatNumber;
const repositoryName = "contrat";
const minutesToHourString = (minutes) => {
    const totalMinutes = minutes % 60;
    const totalHours = (minutes - totalMinutes) / 60;
    return `${totalHours}h${totalMinutes > 0 ? String(totalMinutes).padStart(2, "0") : ""}`;
};
const shortDate = (date) => `${String(date.getDate()).padStart(2, "0")}/${String(date.getMonth() + 1).padStart(2, "0")}/${date.getFullYear()}`;
const commaOrAnd = (i, length) => i < length - 2 ? ", " : i === length - 2 ? " et " : "";
class Contrat extends astra_1.Document {
    cdcs;
    teacher;
    avenantOf;
    signatureDate;
    startDate;
    endDate;
    constructor(contrat) {
        super(repositoryName, contrat);
        this.cdcs = contrat.cdcs || [];
        this.teacher = contrat.teacher;
        this.avenantOf = contrat.avenantOf;
        this.signatureDate = contrat.signatureDate ? new Date(contrat.signatureDate) : null;
        this.startDate = new Date(contrat.startDate);
        this.endDate = new Date(contrat.endDate);
    }
    forEachTasks = (cdcs, tasks, callback) => {
        const contratCDCs = cdcs.filter(cdc => this.cdcs.includes(cdc._id));
        contratCDCs.forEach((cdc) => {
            const cdcTasks = tasks.filter(task => cdc.tasks.includes(task._id));
            cdcTasks.forEach((t, taskI) => {
                if (!t.cancelled && !t.cancelledTeachers.includes(this.teacher)) {
                    callback(t, taskI, cdcTasks);
                }
            });
        });
    };
    getTableInfos = () => this.memo(() => {
        if (!this.getRepositories().cdc.loaded ||
            !this.getRepositories().contrat.loaded ||
            !this.getRepositories().faculty.loaded ||
            !this.getRepositories().mainSkill.loaded ||
            !this.getRepositories().promotion.loaded ||
            !this.getRepositories().site.loaded ||
            !this.getRepositories().skill.loaded ||
            !this.getRepositories().tarif.loaded ||
            !this.getRepositories().task.loaded ||
            !this.getRepositories().taskType.loaded ||
            !this.getRepositories().teacher.loaded) {
            return new Error("Chargement");
        }
        const tableInfos = {
            _id: this._id,
            contrat: this,
            CDCs: [],
            tasks: [],
            faculties: [],
            totalMinutes: 0,
            totalCost: 0,
            contratType: this.avenantOf ? "Avenant" : "Contrat",
            created_at: this.created_at,
            startDate: this.startDate,
            endDate: this.endDate,
        };
        tableInfos.teacher = this.getRepositories().teacher.find((t) => t._id === this.teacher);
        if (!tableInfos.teacher)
            return new Error("Formateur introuvable.");
        tableInfos.shortTeacherString = `${tableInfos.teacher.prenom} ${tableInfos.teacher.nom}`;
        tableInfos.statut = tableInfos.teacher.statut === 1 ? "Formateur permanent"
            : tableInfos.teacher.statut === 2 ? "Intervenant à titre gracieux"
                : tableInfos.teacher.statut === 3 ? "Prestataire de service"
                    : "Vacataire";
        cdcsLoop: for (let cdcIndex = 0; cdcIndex < this.cdcs.length; cdcIndex++) {
            const cdc = this.getRepositories().cdc.find(c => c._id === this.cdcs[cdcIndex]);
            if (!cdc)
                continue cdcsLoop;
            tableInfos.CDCs.push(cdc);
            if (!tableInfos.faculties.some(f => f._id === cdc.faculty)) {
                const faculty = this.getRepositories().faculty.find(f => f._id === cdc.faculty);
                if (faculty)
                    tableInfos.faculties.push(faculty);
            }
            tasksLoop: for (let taskIndex = 0; taskIndex < cdc.tasks.length; taskIndex++) {
                const task = this.getRepositories().task.find(t => t._id === cdc.tasks[taskIndex]);
                if (!task || task.cancelledTeachers.includes(this.teacher) || task.cancelled)
                    continue tasksLoop;
                tableInfos.tasks.push(task);
                if (task.duration) {
                    tableInfos.totalMinutes += task.duration;
                    const tarif = this.getRepositories().tarif.find((t) => t._id === task.tarif);
                    if (tarif) {
                        const hourCost = tableInfos.teacher?.statut === 4 ? tarif.contratTarif : tarif.prestationTarif;
                        tableInfos.totalCost += hourCost * (task.duration / 60);
                    }
                }
            }
        }
        if (!tableInfos.startDate || !tableInfos.endDate)
            return new Error("Dates introuvables");
        tableInfos.site = this.getRepositories().site.find(s => s._id === tableInfos.faculties[0]?.site);
        if (!tableInfos.site)
            return new Error("Site introuvable");
        tableInfos.siteString = tableInfos.site.toString();
        return tableInfos;
    }, [
        this.getRepositories().cdc,
        this.getRepositories().contrat,
        this.getRepositories().faculty,
        this.getRepositories().mainSkill,
        this.getRepositories().promotion,
        this.getRepositories().site,
        this.getRepositories().skill,
        this.getRepositories().tarif,
        this.getRepositories().task,
        this.getRepositories().taskType,
        this.getRepositories().teacher,
    ], "getTableInfos");
    getDocInfos = () => this.memo(() => {
        if (!this.getRepositories().cdc.loaded ||
            !this.getRepositories().contrat.loaded ||
            !this.getRepositories().faculty.loaded ||
            !this.getRepositories().mainSkill.loaded ||
            !this.getRepositories().promotion.loaded ||
            !this.getRepositories().site.loaded ||
            !this.getRepositories().skill.loaded ||
            !this.getRepositories().tarif.loaded ||
            !this.getRepositories().task.loaded ||
            !this.getRepositories().taskType.loaded ||
            !this.getRepositories().teacher.loaded) {
            return new Error("Chargement");
        }
        const getContratStartAndEndDate = (contrat) => {
            let startDate;
            let endDate;
            contrat.forEachTasks(this.getRepositories().cdc, this.getRepositories().task, (task) => {
                if (!startDate || task.date < startDate) {
                    startDate = new Date(task.date);
                }
                if (!endDate || task.date > endDate) {
                    endDate = new Date(task.date);
                }
            });
            return [startDate, endDate];
        };
        const contratTeacher = this.getRepositories().teacher.find((t) => t._id === this.teacher);
        if (!contratTeacher)
            return new Error("Formateur introuvable.");
        const teacherInfos = contratTeacher.getInfos();
        const contratCDCs = this.cdcs.map(cdcId => this.getRepositories().cdc.findById(cdcId)).filter(cdc => cdc);
        if (!contratCDCs || contratCDCs.length === 0 || !contratCDCs[0])
            return new Error("Cahiers des charges introuvables.");
        const facultyOfFirstCDC = this.getRepositories().faculty.findById(contratCDCs[0].faculty);
        if (!facultyOfFirstCDC)
            return new Error("Filières introuvables.");
        const contratSite = this.getRepositories().site.findById(facultyOfFirstCDC.site);
        if (!contratSite)
            return new Error("Site introuvable.");
        const contratSiteString = `${contratSite.name} (${contratSite.location})`;
        const [startDate, endDate] = getContratStartAndEndDate(this);
        if (!startDate || !endDate)
            return new Error("Date introuvables.");
        const contratSignatureDateString = this.signatureDate?.toLocaleDateString("fr", { day: "numeric", month: "long", year: "numeric" });
        let initialContrat;
        let initialContratStartDate;
        let initialContratEndDate;
        if (this.avenantOf) {
            initialContrat = this.getRepositories().contrat.findById(this.avenantOf);
            if (initialContrat) {
                [initialContratStartDate, initialContratEndDate] = getContratStartAndEndDate(initialContrat);
            }
        }
        const contratFaculties = [];
        const contratPedagogiqueTaskTypes = [];
        const contratAnnexeTaskTypes = [];
        const contratFacultiesHoursTrees = [];
        const contratTarifDurations = [];
        let contratTotalMinutes = 0;
        let contratTotalCost = 0;
        const tasksList = [];
        const tasks = [];
        this.forEachTasks(this.getRepositories().cdc, this.getRepositories().task, (task) => {
            const taskPromoGroup = this.getRepositories().promotion.filter(promo => task.promotions.some(promoSkill => promoSkill.promotion === promo._id));
            const taskSkill = [];
            this.getRepositories().skill.forEach(sk => {
                if (task.promotions.some(promoSkill => promoSkill.skill === sk._id
                    && !taskSkill.some(s => sk._i === s._id))) {
                    taskSkill.push(sk);
                }
            });
            const taskTarif = this.getRepositories().tarif.find(t => t._id === task.tarif);
            const taskSkills = this.getRepositories().skill.filter(skill => task.promotions.some(promoSkill => promoSkill.skill === skill._id));
            if (taskTarif) {
                const taskTaskType = this.getRepositories().taskType.find(tt => tt._id === taskTarif.type);
                if (taskTaskType) {
                    tasks.push(task);
                    if (taskTaskType.annexe && !contratAnnexeTaskTypes.some(tt => tt._id === taskTaskType._id)) {
                        contratAnnexeTaskTypes.push(taskTaskType);
                    }
                    else if (!contratPedagogiqueTaskTypes.some(tt => tt._id === taskTaskType._id)) {
                        contratPedagogiqueTaskTypes.push(taskTaskType);
                    }
                    const taskTarifAmount = (contratTeacher.statut === 4 ? taskTarif.contratTarif : taskTarif.prestationTarif) || 0;
                    contratTotalCost += taskTarifAmount * (task.duration / 60);
                    const taskTarifDuration = contratTarifDurations.find(td => td.tarif._id === taskTarif._id);
                    if (taskTarifDuration) {
                        taskTarifDuration.minutes += task.duration;
                    }
                    else {
                        contratTarifDurations.push({
                            amount: taskTarifAmount,
                            minutes: task.duration,
                            tarif: taskTarif,
                            type: taskTaskType
                        });
                    }
                    const taskFacultyGroup = this.getRepositories().faculty.filter(faculty => taskPromoGroup.some(promo => promo.faculty === faculty._id));
                    taskFacultyGroup.forEach(faculty => {
                        if (!contratFaculties.some(f => f._id === faculty._id)) {
                            contratFaculties.push(faculty);
                        }
                    });
                    const facultyHoursTree = contratFacultiesHoursTrees.find(fht => fht.faculties.every(fhtFaculty => taskFacultyGroup.some(faculty => faculty._id === fhtFaculty._id)));
                    if (facultyHoursTree) {
                        const fhtPromotions = facultyHoursTree.promotionsGroups.find(pGroup => pGroup.promotions.every(promo => taskPromoGroup.some(p => p._id === promo._id)));
                        if (fhtPromotions) {
                            const fhtTaskType = fhtPromotions.taskTypesHours.find(fhtTT => fhtTT.taskType._id === taskTaskType._id);
                            if (fhtTaskType) {
                                contratTotalMinutes += task.duration;
                                facultyHoursTree.minutes += task.duration;
                                fhtPromotions.minutes += task.duration;
                                fhtTaskType.minutes += task.duration;
                                taskSkills.forEach(skill => {
                                    if (!fhtTaskType.skills.some(s => s._id === skill._id)) {
                                        fhtTaskType.skills.push(skill);
                                    }
                                });
                            }
                            else {
                                contratTotalMinutes += task.duration;
                                facultyHoursTree.minutes += task.duration;
                                fhtPromotions.minutes += task.duration;
                                fhtPromotions.taskTypesHours.push({
                                    taskType: taskTaskType,
                                    minutes: task.duration,
                                    skills: [...taskSkills]
                                });
                            }
                        }
                        else {
                            contratTotalMinutes += task.duration;
                            facultyHoursTree.minutes += task.duration;
                            facultyHoursTree.promotionsGroups.push({
                                promotions: [...taskPromoGroup],
                                taskTypesHours: [{
                                        taskType: taskTaskType,
                                        minutes: task.duration,
                                        skills: [...taskSkills]
                                    }],
                                minutes: task.duration
                            });
                        }
                    }
                    else {
                        contratTotalMinutes += task.duration;
                        contratFacultiesHoursTrees.push({
                            faculties: [...taskFacultyGroup],
                            promotionsGroups: [{
                                    promotions: [...taskPromoGroup],
                                    taskTypesHours: [{
                                            taskType: taskTaskType,
                                            minutes: task.duration,
                                            skills: [...taskSkills]
                                        }],
                                    minutes: task.duration
                                }],
                            minutes: task.duration
                        });
                    }
                }
                const endHour = new Date(task.date);
                endHour.setMinutes(endHour.getMinutes() + task.duration);
                tasksList.push({
                    task,
                    type: taskTaskType,
                    promotions: taskPromoGroup,
                    skills: taskSkill,
                    dateString: `${formatNumber(task.date.getDate())}/${formatNumber(task.date.getMonth() + 1)}/${task.date.getFullYear()}`,
                    hourString: `${formatNumber(task.date.getHours())}:${formatNumber(task.date.getMinutes())}${task.deadline ? "" : ` - ${formatNumber(endHour.getHours())}:${formatNumber(endHour.getMinutes())}`}`,
                    durationString: minutesToHourString(task.duration),
                });
            }
        });
        tasksList.sort((a, b) => a.task.date.getTime() - b.task.date.getTime());
        const contratTotalMinutesString = minutesToHourString(contratTotalMinutes);
        const contratFacultiesString = contratFaculties.reduce((string, faculty, i, a) => `${string}${faculty.sigle}${commaOrAnd(i, a.length)}`, "");
        const title = `${initialContrat ? "Avenant" : "Contrat"} de ${teacherInfos.teacherString} du ${shortDate(startDate)}${startDate.getTime() < endDate.getTime()
            ? ` au ${shortDate(endDate)}`
            : ""}`;
        return {
            title,
            _id: this._id,
            contrat: this,
            teacher: contratTeacher,
            ...teacherInfos,
            CDCs: contratCDCs,
            totalCDCs: contratCDCs.length,
            tasks: tasks,
            tasksList,
            site: contratSite,
            siteString: contratSiteString,
            startDate,
            endDate,
            signatureDateString: contratSignatureDateString,
            contratType: this.avenantOf ? "Avenant" : "Contrat",
            initialContrat,
            initialContratStartDate,
            initialContratEndDate,
            director: this.getRepositories().user.findById(contratSite.referent),
            faculties: contratFaculties,
            facultiesString: contratFacultiesString,
            pedagogiqueTaskTypes: contratPedagogiqueTaskTypes,
            annexeTaskTypes: contratAnnexeTaskTypes,
            facultiesHoursTrees: contratFacultiesHoursTrees,
            tarifDurations: contratTarifDurations,
            totalTasks: tasks.length,
            totalMinutes: contratTotalMinutes,
            totalMinutesString: contratTotalMinutesString,
            totalCost: contratTotalCost,
            created_at: this.created_at
        };
    }, [
        this.getRepositories().cdc,
        this.getRepositories().contrat,
        this.getRepositories().faculty,
        this.getRepositories().mainSkill,
        this.getRepositories().promotion,
        this.getRepositories().site,
        this.getRepositories().skill,
        this.getRepositories().tarif,
        this.getRepositories().task,
        this.getRepositories().taskType,
        this.getRepositories().teacher,
    ], "getDocInfos");
    pdf = (contratInfos) => this.memo(() => {
        return (0, pdfs_1.ContratPDF)(this.getRepositories(), contratInfos);
    }, [true], "pdf");
}
exports.Contrat = Contrat;
class Contrats extends astra_1.Repository {
    constructor(docs) {
        super(repositoryName, Contrats, Contrat, docs);
    }
}
exports.Contrats = Contrats;
exports.default = new Contrats();
