"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TasksPage = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const free_solid_svg_icons_1 = require("@fortawesome/free-solid-svg-icons");
const react_1 = require("react");
const react_2 = require("react");
const astra_1 = require("astra");
const tasksTableHead_1 = require("../../../../../../../utils/tasksTableHead");
function TasksPage(props) {
    const sunContext = (0, astra_1.useSunContext)();
    const [selected, setSelected] = (0, react_1.useState)(props.teacherFaculty?.tasks.map(task => task._id) || []);
    (0, react_2.useEffect)(() => {
        if (props.teacherFaculty && props.teacherFaculty.tasks.length > 0) {
            setSelected(props.teacherFaculty?.tasks.map(task => task._id));
        }
    }, [props.teacherFaculty?.tasks.length]);
    if (props.teacherFaculty) {
        return ((0, jsx_runtime_1.jsxs)("div", { className: "TeachersPages", children: [(0, jsx_runtime_1.jsx)(astra_1.Button, { icon: free_solid_svg_icons_1.faArrowLeft, onClick: props.back }), (0, jsx_runtime_1.jsx)("h3", { children: "T\u00E2ches" }), (0, jsx_runtime_1.jsx)(astra_1.Table, { array: props.teacherFaculty.tasks.map(task => ({ ...task, selected: selected.includes(task._id) })), head: (0, tasksTableHead_1.tasksTableHead)(sunContext.repositories, true), onClick: (task) => props.teacherFaculty &&
                        setSelected(props.teacherFaculty.tasks
                            .map((task) => task._id)
                            .filter(taskId => (taskId !== task._id && selected.includes(taskId))
                            || (taskId === task._id && !selected.includes(taskId)))), flex: true }), (0, jsx_runtime_1.jsx)(astra_1.Button, { name: "Cr\u00E9er le cahier des charges", className: "addButton", onClick: () => props.onPick(selected.map(taskId => props.teacherFaculty?.tasks.find(task => task._id === taskId))
                        .filter(t => t)), disabled: selected.length === 0 })] }));
    }
    else {
        return (0, jsx_runtime_1.jsx)("div", {});
    }
}
exports.TasksPage = TasksPage;
