"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ColorList = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
require("./ColorList.scss");
function ColorList(props) {
    const colorList = [];
    for (let hue = 0; hue < 360; hue += 5) {
        const tint = [];
        for (let sat = 40; sat <= 90; sat += 5) {
            const lux = 0.6 * sat + 20;
            tint.push(`hsl(${hue}, ${sat}%, ${lux}%)`);
        }
        colorList.push(tint);
    }
    return ((0, jsx_runtime_1.jsx)("div", { className: "ColorList", children: colorList.map((tint, index) => ((0, jsx_runtime_1.jsx)("div", { className: "tint", children: tint.map((shade) => ((0, jsx_runtime_1.jsx)("div", { className: "shade", style: { backgroundColor: shade }, onClick: () => props.resolve(shade) }, shade))) }, index))) }));
}
exports.ColorList = ColorList;
