"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tasksTableHead = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const tasksTableHead = (repos, selection) => {
    if (!repos) {
        return [];
    }
    else {
        let head = [
            {
                label: "Nom",
                key: "faculty",
                type: "function",
                function: (task => task.title)
            },
            {
                label: "Date",
                key: "date",
                type: "function",
                function: (task) => {
                    const date = `${String(task.date.getDate()).padStart(2, "0")}/${String(task.date.getMonth() + 1).padStart(2, "0")}/${task.date.getFullYear()}`;
                    if (task.deadline) {
                        const minutes = task.duration % 60;
                        const hours = (task.duration - minutes) / 60;
                        return `${hours}h${String(minutes).padStart(2, '0')}, date limite : ${date}`;
                    }
                    else {
                        const taskEnd = new Date(task.date);
                        taskEnd.setMinutes(taskEnd.getMinutes() + task.duration);
                        return `${date} ${String(task.date.getHours()).padStart(2, "0")}:${String(task.date.getMinutes()).padStart(2, "0")} - ${String(taskEnd.getHours()).padStart(2, "0")}:${String(taskEnd.getMinutes()).padStart(2, "0")}`;
                    }
                }
            },
            {
                label: "Promotion/Compétence",
                key: "promotions",
                type: "function",
                function: (task) => {
                    const promoSkills = task.promotions.map(ps => {
                        const promotion = repos.promotion.find(p => p._id === ps.promotion);
                        const skill = repos.skill.find(s => s._id === ps.skill);
                        return { promotion, skill };
                    });
                    const strings = promoSkills.reduce((lines, promoSkill, i, a) => {
                        lines.push((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [!promoSkill.promotion ? "" : promoSkill.promotion.name, !promoSkill.promotion || !promoSkill.skill ? "" : " - ", !promoSkill.skill ? "" : promoSkill.skill.getCode(), i < a.length - 1 && (0, jsx_runtime_1.jsx)("br", {})] }));
                        return lines;
                    }, []);
                    return (0, jsx_runtime_1.jsx)("p", { children: strings });
                }
            },
            {
                label: "Type",
                key: "promotions",
                type: "function",
                function: (task) => {
                    const tarif = repos.tarif.find(tar => tar._id === task.tarif);
                    const taskType = repos.taskType.find(tt => tt._id === tarif?.type);
                    return !taskType ? "" : `${taskType.sigle} - ${taskType.name}`;
                }
            }
        ];
        if (selection) {
            head = [
                {
                    label: "",
                    key: "selected",
                    type: "boolean",
                    size: 4
                },
                ...head
            ];
        }
        return head;
    }
};
exports.tasksTableHead = tasksTableHead;
