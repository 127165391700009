"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const free_solid_svg_icons_1 = require("@fortawesome/free-solid-svg-icons");
const astra_1 = require("astra");
const Organization_model_1 = require("../../../models/Organization.model");
require("./style.scss");
const orgaForm = (orga) => ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: (0, jsx_runtime_1.jsx)(astra_1.Input, { label: "nom", name: "name", value: orga?.name }) }));
const Main = (view) => {
    const edit = (orga) => {
        view.modal({
            form: orgaForm(orga),
            resolveButton: "Sauvegarder",
            rejectButton: "Annuler",
            title: "Sauvegarder une organisation"
        })
            .then((patch) => {
            orga.patch(patch)
                .send()
                .then(() => view.notify("Modifications sauvegardés", "green"))
                .catch(() => view.notify("Echec", "red"));
        });
    };
    (0, react_1.useEffect)(() => {
        view.repositories.user.getArchives().send();
        view.repositories.user.getRemoved().send();
    }, []);
    const head = [
        {
            label: "nom",
            key: "name",
            type: "string",
            sortable: true,
        },
    ];
    const mainHead = [
        ...head,
        {
            label: "",
            key: "name",
            type: "function",
            size: 4,
            function: (doc) => ((0, jsx_runtime_1.jsx)(astra_1.Button, { className: "removeButton", filled: true, icon: free_solid_svg_icons_1.faPen, onClick: () => edit(doc) }))
        },
        {
            label: "",
            type: 'function',
            key: "",
            size: 4,
            function: (orga) => ((0, jsx_runtime_1.jsx)(astra_1.Button, { className: "actionButton", filled: true, icon: free_solid_svg_icons_1.faArchive, onClick: (event) => {
                    event.stopPropagation();
                    view.modal({
                        title: "Archiver une organisation",
                        message: `Souhaitez vous archiver l'organisation ${orga.name} ?`,
                        rejectButton: "Annuler",
                        resolveButton: "Archiver"
                    })
                        .then(() => {
                        orga.archive()
                            .send()
                            .then(() => view.notify("Organisation archivée."))
                            .catch(() => view.notify("Erreur", "red"));
                    });
                } }))
        }
    ];
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: (0, jsx_runtime_1.jsxs)("section", { children: [(0, jsx_runtime_1.jsx)("h3", { children: "Organisations" }), (0, jsx_runtime_1.jsx)(astra_1.Button, { name: "nouvelle organisation", icon: free_solid_svg_icons_1.faPlus, className: "addButton", onClick: () => {
                        view.modal({
                            title: "Nouvelle organisation",
                            form: orgaForm(),
                            rejectButton: "Annuler",
                            resolveButton: "Ajouter"
                        })
                            .then((orga) => {
                            view.repositories.organization.post(new Organization_model_1.Organization(orga))
                                .send()
                                .then(() => view.notify("Nouvelle organisation sauvegardée.", "green"))
                                .catch(() => view.notify("Echec", "red"));
                        });
                    } }), (0, jsx_runtime_1.jsx)(astra_1.Table, { array: view.repositories.organization, head: mainHead, flex: true, onClick: edit, remove: (user) => {
                        view.modal({
                            title: "Supprimer une organisation",
                            message: "Souhaitez-vous supprimer cette organisation ?",
                            rejectButton: "Annuler",
                            resolveButton: "Supprimer"
                        })
                            .then(() => {
                            user.remove()
                                .send()
                                .then(() => view.notify("Organisation supprimée.", "green"))
                                .catch(() => view.notify("Organisation.", "red"));
                        });
                    } })] }) }));
};
exports.default = Main;
