"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImageUpload = void 0;
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = tslib_1.__importStar(require("react"));
const __1 = require("../");
const free_solid_svg_icons_1 = require("@fortawesome/free-solid-svg-icons");
const DropZone_1 = require("../DropZone/DropZone");
require("./ImageUpload.scss");
const ImageUpload = (props) => {
    const [image, setImage] = (0, react_1.useState)(props.currentImageURL || null);
    const [isDropActive, setIsDropActive] = react_1.default.useState(false);
    const ref = react_1.default.useRef();
    const onImageChange = (files) => {
        console.log("files", files);
        if (files && files[0]) {
            setImage(files[0]);
            setTimeout(() => props.onChange && props.onChange(files[0]));
        }
    };
    const onAddButtonClick = (0, react_1.useCallback)(() => {
        ref.current?.click();
    }, []);
    const onDeleteButtonClick = (0, react_1.useCallback)(() => {
        setImage(null);
    }, []);
    return ((0, jsx_runtime_1.jsxs)("div", { className: `ImageUpload ${props.className} ${isDropActive ? "drag" : ""}`, children: [(0, jsx_runtime_1.jsx)("input", { type: "file", onChange: (event) => onImageChange(event.target.files), style: { display: "none" }, ref: ref }), (0, jsx_runtime_1.jsx)("input", { type: "text", name: props.name, style: { display: "none" }, value: image || "" }), (0, jsx_runtime_1.jsxs)(DropZone_1.DropZone, { onFilesDrop: onImageChange, onDragStateChange: setIsDropActive, children: [props.label && (0, jsx_runtime_1.jsx)("label", { htmlFor: props.name, children: props.label }), isDropActive ? ((0, jsx_runtime_1.jsx)("p", { children: "D\u00E9posez le fichier ici" })) : image ? ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)("img", { alt: "erreur", src: typeof image === "string" ? image : URL.createObjectURL(image) }), (0, jsx_runtime_1.jsx)(__1.Button, { className: "deleteFileButton", icon: free_solid_svg_icons_1.faTrash, onClick: onDeleteButtonClick })] })) :
                        ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)("p", { children: "Faites glisser une image ici ou" }), (0, jsx_runtime_1.jsx)(__1.Button, { name: "Parcourez vos fichiers", className: "openFolderButton", onClick: onAddButtonClick })] }))] })] }));
};
exports.ImageUpload = ImageUpload;
