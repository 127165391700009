"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SemestersButtons = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const astra_1 = require("astra");
require("./SemestersButtons.scss");
const SemestersButtons = (props) => {
    let s = 1;
    const years = props.years.map(year => {
        const semestersArray = [];
        for (let i = s; i < s + year; i++) {
            semestersArray.push(i);
        }
        s = semestersArray[semestersArray.length - 1] + 1;
        return semestersArray;
    });
    return ((0, jsx_runtime_1.jsx)("div", { className: `SemestersButtons ${props.className || ""}`, style: props.style, children: years.map((year, i) => ((0, jsx_runtime_1.jsxs)("div", { className: "Year", style: { flex: year.length }, children: [(0, jsx_runtime_1.jsxs)("h4", { children: [props.sigle, i + 1] }), (0, jsx_runtime_1.jsx)("div", { className: "Semesters", children: year.map(semester => ((0, jsx_runtime_1.jsx)(astra_1.Button, { name: String(semester), onClick: () => props.onChange && props.onChange(semester), active: props.active === semester }))) })] }, i))) }));
};
exports.SemestersButtons = SemestersButtons;
