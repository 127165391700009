"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const store = {
    tab: 0,
    menu: true,
    users_page: 1,
    users_rowPerPage: 20,
    users_searchStringValue: "",
    users_roleFilter: [],
    users_showUsersWithoutRole: true,
    users_selected: [],
    users_removedListView: false,
    users_pageLoaded: false,
    roles_page: 1,
    roles_rowPerPage: 20,
    roles_searchStringValue: "",
    roles_roleFilter: [],
    roles_selected: [],
    roles_removedListView: false
};
exports.default = store;
