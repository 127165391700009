"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Promotions = exports.Promotion = void 0;
const astra_1 = require("astra");
const repositoryName = "promotion";
function initPeriods(periods) {
    return periods.map(period => ({
        start: new Date(period.start),
        end: new Date(period.end)
    }));
}
class Promotion extends astra_1.Document {
    name;
    year;
    faculty;
    semesters;
    internshipPeriods;
    constructor(promotion) {
        super(repositoryName, promotion);
        this.year = promotion.year || 1;
        this.name = promotion.name || "";
        this.faculty = promotion.faculty;
        this.semesters = promotion.semesters
            ? initPeriods(promotion.semesters)
            : [];
        this.internshipPeriods = promotion.internshipPeriods
            ? initPeriods(promotion.internshipPeriods)
            : [];
    }
}
exports.Promotion = Promotion;
class Promotions extends astra_1.Repository {
    constructor(docs) {
        super(repositoryName, Promotions, Promotion, docs);
    }
}
exports.Promotions = Promotions;
exports.default = new Promotions();
