"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Teachers = exports.Teacher = void 0;
const astra_1 = require("astra");
const repositoryName = "teacher";
class Teacher extends astra_1.Document {
    statut;
    nom;
    prenom;
    nationalite;
    nom_de_naissance;
    date_de_naissance;
    lieu_de_naissance;
    telephone_fixe;
    telephone_portable;
    email;
    numero;
    bis_ter;
    nature_de_la_voie;
    nom_de_la_voie;
    complement_d_adresse;
    code_postal;
    numero_de_securite_sociale;
    cle;
    employeur;
    diplome;
    agree_par_l_universite;
    chercheur;
    metier;
    nom_association;
    structure;
    matricule;
    bureau_distributeur;
    cv;
    date_dossier_adm;
    dossierValide;
    constructor(teacher) {
        super(repositoryName, teacher);
        this.statut = teacher.statut;
        this.nom = teacher.nom || "";
        this.prenom = teacher.prenom || "";
        this.nationalite = teacher.nationalite || "";
        this.nom_de_naissance = teacher.nom_de_naissance || "";
        this.date_de_naissance = teacher.date_de_naissance
            ? new Date(teacher.date_de_naissance)
            : undefined;
        this.lieu_de_naissance = teacher.lieu_de_naissance || "";
        this.telephone_fixe = teacher.telephone_fixe || "";
        this.telephone_portable = teacher.telephone_portable || "";
        this.email = teacher.email || "";
        this.numero = teacher.numero || "";
        this.bis_ter = teacher.bis_ter || "";
        this.nature_de_la_voie = teacher.nature_de_la_voie || "";
        this.nom_de_la_voie = teacher.nom_de_la_voie || "";
        this.complement_d_adresse = teacher.complement_d_adresse || "";
        this.code_postal = teacher.code_postal || "";
        this.numero_de_securite_sociale = teacher.numero_de_securite_sociale || "";
        this.metier = teacher.metier || "";
        this.employeur = teacher.employeur || "";
        this.diplome = teacher.diplome || "";
        this.agree_par_l_universite = teacher.agree_par_l_universite ? true : false;
        this.chercheur = teacher.chercheur ? true : false;
        this.nom_association = teacher.nom_association || "";
        this.structure = teacher.structure || "";
        this.cle = teacher.cle || "";
        this.matricule = teacher.matricule || "";
        this.bureau_distributeur = teacher.bureau_distributeur || "";
        this.cv = teacher.cv || "";
        this.date_dossier_adm = teacher.date_dossier_adm
            ? new Date(teacher.date_dossier_adm)
            : undefined;
        this.dossierValide = teacher.dossierValide ? true : false;
    }
    getTeacherString = () => `${this.prenom} ${this.nom.toUpperCase()}`;
    getStatusString = () => this.memo(() => this.statut === 1 ? "Formateur permanent"
        : this.statut === 2 ? "Intervenant à titre gracieux"
            : this.statut === 3 ? "Prestataire de service"
                : "Vacataire", [this.statut], "getStatusString");
    getInfos = () => this.memo(() => {
        const firstSecuNumber = this.numero_de_securite_sociale
            ? this.numero_de_securite_sociale.charAt(0) === "1"
                ? "1"
                : this.numero_de_securite_sociale.charAt(0) === "2"
                    ? "2"
                    : "0"
            : "0";
        const genderString = firstSecuNumber === "1" ? "Monsieur" : firstSecuNumber === "2" ? "Madame" : "";
        return {
            firstSecuNumber,
            genderString,
            teacherString: `${genderString}${genderString.length > 0 ? " " : ""}${this.prenom} ${this.nom.toUpperCase()}`,
            shortTeacherString: `${this.prenom} ${this.nom.toUpperCase()}`,
            accord: firstSecuNumber === "2" ? "e" : "",
            birthDateString: this.date_de_naissance?.toLocaleDateString("fr", { day: "numeric", month: "long", year: "numeric" }),
            statut: this.getStatusString(),
        };
    }, [
        this.numero_de_securite_sociale,
        this.prenom,
        this.nom,
        this.date_de_naissance,
        this.dossierValide
    ], "getInfos");
}
exports.Teacher = Teacher;
class Teachers extends astra_1.Repository {
    constructor(docs) {
        super(repositoryName, Teachers, Teacher, docs);
    }
}
exports.Teachers = Teachers;
exports.default = new Teachers();
