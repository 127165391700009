"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.deletePromotion = void 0;
function deletePromotion(promotion, repositories) {
    return new Promise(async (resolve, reject) => {
        repositories.promotion.remove(promotion._id).send()
            .then(async () => {
            const promotionTasks = repositories.task.filter((task) => task.promotions.some(promo => promotion._id === promo.promotion));
            const promotionCDCs = repositories.cdc.filter((cdc) => promotionTasks.some(pt => cdc.tasks.includes(pt._id)));
            const promotionContrats = repositories.contrat.filter((contrat) => promotionCDCs.some(fcdc => contrat.cdcs.includes(fcdc._id)));
            if (promotionTasks.length > 0) {
                try {
                    await repositories.task.remove(promotionTasks.map(task => task._id)).send();
                }
                catch (e) {
                    return reject("Erreur lors de la suppression des cours associés à la promotion.");
                }
            }
            if (promotionCDCs.length > 0) {
                try {
                    await repositories.cdc.remove(promotionCDCs.map(cdc => cdc._id)).send();
                }
                catch (e) {
                    return reject("Erreur lors de la suppression des cahiers des charges associés à la promotion.");
                }
            }
            if (promotionContrats.length > 0) {
                try {
                    await repositories.contrat.remove(promotionContrats.map(contrat => contrat._id)).send();
                }
                catch (e) {
                    return reject("Erreur lors de la suppression des contrats associés à la promotion.");
                }
            }
            return resolve("");
        });
    });
}
exports.deletePromotion = deletePromotion;
