"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FacultiesPage = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const free_solid_svg_icons_1 = require("@fortawesome/free-solid-svg-icons");
const astra_1 = require("astra");
function FacultiesPage(props) {
    const tableHead = [
        {
            label: "Nom",
            key: "faculty",
            type: "function",
            function: (teacherFaculty => teacherFaculty.faculty.name)
        },
    ];
    if (props.teacherCDCs) {
        return ((0, jsx_runtime_1.jsxs)("div", { className: "TeachersPages", children: [(0, jsx_runtime_1.jsx)(astra_1.Button, { icon: free_solid_svg_icons_1.faArrowLeft, onClick: props.back }), (0, jsx_runtime_1.jsx)("h3", { children: "Fili\u00E8res" }), (0, jsx_runtime_1.jsx)(astra_1.Table, { array: props.teacherCDCs.faculties, head: tableHead, onClick: props.onPick, flex: true })] }));
    }
    else {
        return (0, jsx_runtime_1.jsx)("div", {});
    }
}
exports.FacultiesPage = FacultiesPage;
