"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTeachersContrats = void 0;
function getTeachersContrats(repositories) {
    const teachersContrats = repositories.teacher.map(teacher => {
        const teacherContrats = repositories.contrat.filter(contrat => contrat.teacher === teacher._id);
        const teacherCDCs = repositories.cdc.filter(cdc => cdc.teacher === teacher._id);
        const unsentTeacherCDCs = teacherCDCs.filter((cdc) => {
            if (!cdc.validated ||
                cdc.signatureDate ||
                teacherContrats.some(contrat => contrat.cdcs.includes(cdc._id))) {
                return false;
            }
            const cdcTasks = repositories.task.filter(t => cdc.tasks.includes(t._id));
            if (cdcTasks.every((t) => t.cancelled || t.cancelledTeachers.includes(teacher._id))) {
                return false;
            }
            return true;
        });
        const teacherSites = unsentTeacherCDCs.reduce((teacherSits, cdc) => {
            const faculty = repositories.faculty.findById(cdc.faculty);
            if (faculty) {
                const site = repositories.site.findById(faculty.site);
                if (site) {
                    const siteIndex = teacherSits.findIndex(tSit => tSit.site._id === site._id);
                    if (siteIndex >= 0) {
                        teacherSits[siteIndex].cdcs.push(cdc);
                    }
                    else {
                        teacherSits.push({
                            site,
                            cdcs: [cdc]
                        });
                    }
                }
            }
            return teacherSits;
        }, []);
        return {
            teacher,
            site: teacherSites.filter(site => site.cdcs.length > 0)
        };
    });
    return teachersContrats.filter(contrat => contrat.site.length > 0);
}
exports.getTeachersContrats = getTeachersContrats;
