"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const Cell_1 = require("../Cell/Cell");
const Button_1 = require("../../Button/Button");
const free_solid_svg_icons_1 = require("@fortawesome/free-solid-svg-icons");
function Row(props) {
    const subLevel = props.subLevel || 0;
    function getPositionForFixedColumn(index) {
        const columnsBefore = props.fixedColumns.slice(0, index);
        if (columnsBefore.length === 0) {
            return 0;
        }
        else {
            return columnsBefore
                .map((col) => col.size)
                .reduce((total = 0, current) => (current ? total + current : total));
        }
    }
    const rowchildren = props.rowChildren ? props.rowChildren(props.row) : [];
    const warning = props.warning ? props.warning(props.row) : false;
    const error = props.error ? props.error(props.row) : false;
    const valid = props.valid ? props.valid(props.row) : false;
    const selected = props.selectedRows.includes(props.row._id);
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)("tr", { style: props.style, className: `${props.onClick ? " clickable" : ""}${error ? " error" : warning ? " warning" : valid ? " valid" : ""} sub${subLevel} ${selected ? 'selected' : ''}`, onClick: props.onClick ? () => props.onClick && props.onClick(props.row, props.rowIndex) : () => false, children: props.sortedColumns.map((col, colIndex) => {
                    if (col.key === "remove" && col.type === "feature" && props.remove) {
                        return ((0, jsx_runtime_1.jsx)(Cell_1.Cell, { colIndex: colIndex, col: col, rowIndex: props.rowIndex, first: props.head.indexOf(col) === 0, lastFixed: props.fixedColumns[props.fixedColumns.length - 1] === col, order: props.head.indexOf(col), left: getPositionForFixedColumn(props.head.indexOf(col)) + "rem", style: { justifyContent: "flex-end" }, row: props.row, remove: props.remove, disabled: rowchildren && rowchildren.length > 0, selected: selected }, colIndex));
                    }
                    else {
                        return ((0, jsx_runtime_1.jsx)(Cell_1.Cell, { colIndex: colIndex, col: col, rowIndex: props.rowIndex, first: props.head.indexOf(col) === 0, lastFixed: props.fixedColumns[props.fixedColumns.length - 1] === col, order: props.head.indexOf(col), left: getPositionForFixedColumn(props.head.indexOf(col)) + "rem", row: props.row, onSelect: props.onSelect, selectable: props.selectable, selected: selected }, colIndex));
                    }
                }) }, props.rowIndex), rowchildren && rowchildren.map((rowChild, i) => ((0, jsx_runtime_1.jsx)(Row, { head: props.head, rowIndex: i, row: rowChild, fixedColumns: props.fixedColumns, sortedColumns: props.sortedColumns, style: props.style, withoutMemo: props.withoutMemo, remove: props.remove, onClick: props.onClick, warning: props.warning, error: props.error, valid: props.valid, rowChildren: props.rowChildren, addChild: props.addChild, subLevel: subLevel + 1, maxChildrenLevel: props.maxChildrenLevel, onSelect: props.onSelect, selectedRows: props.selectedRows }, String(props.rowIndex) + " " + String(i)))), props.addChild && (!props.maxChildrenLevel || subLevel < props.maxChildrenLevel) && ((0, jsx_runtime_1.jsx)("tr", { className: `addChildRow sub${subLevel}`, children: (0, jsx_runtime_1.jsx)("td", { children: (0, jsx_runtime_1.jsx)(Button_1.Button, { style: props.style, className: "addButton", icon: free_solid_svg_icons_1.faPlus, onClick: () => props.addChild && props.addChild(props.row) }) }) }, props.rowIndex + " add"))] }));
}
exports.default = Row;
;
