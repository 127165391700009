"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.deleteFaculty = void 0;
function deleteFaculty(facultyId, repositories) {
    return new Promise(async (resolve, reject) => {
        repositories.faculty.remove(facultyId).send()
            .then(async () => {
            const facultyPromotions = repositories.promotion.filter((promo) => promo.faculty === facultyId);
            const facultyTasks = repositories.task.filter((task) => task.promotions.some(promo => facultyPromotions.some(fp => fp._id === promo.promotion)));
            const facultyMainSkills = repositories.mainSkill.filter((mainSkill) => mainSkill.faculty === facultyId);
            const facultySkills = repositories.skill.filter((skill) => facultyMainSkills.some(fms => skill.mainSkill === fms._id));
            const facultyCDCs = repositories.cdc.filter((cdc) => cdc.faculty === facultyId);
            const facultyContrats = repositories.contrat.filter((contrat) => facultyCDCs.some(fcdc => contrat.cdcs.includes(fcdc._id)));
            if (facultyPromotions.length > 0) {
                try {
                    await repositories.promotion.remove(facultyPromotions.map(promo => promo._id)).send();
                }
                catch (e) {
                    return reject("Erreur lors de la suppression des promotions associés à la filière.");
                }
            }
            if (facultyTasks.length > 0) {
                try {
                    await repositories.task.remove(facultyTasks.map(task => task._id)).send();
                }
                catch (e) {
                    return reject("Erreur lors de la suppression des cours associés à la filière.");
                }
            }
            if (facultyMainSkills.length > 0) {
                try {
                    await repositories.mainSkill.remove(facultyMainSkills.map(MS => MS._id)).send();
                }
                catch (e) {
                    return reject("Erreur lors de la suppression des unités de compétence principales associées à la filière.");
                }
            }
            if (facultySkills.length > 0) {
                try {
                    await repositories.skill.remove(facultySkills.map(skill => skill._id)).send();
                }
                catch (e) {
                    return reject("Erreur lors de la suppression des unités de compétence associées à la filière.");
                }
            }
            if (facultyCDCs.length > 0) {
                try {
                    await repositories.cdc.remove(facultyCDCs.map(cdc => cdc._id)).send();
                }
                catch (e) {
                    return reject("Erreur lors de la suppression des cahiers des charges associés à la filière.");
                }
            }
            if (facultyContrats.length > 0) {
                try {
                    await repositories.contrat.remove(facultyContrats.map(contrat => contrat._id)).send();
                }
                catch (e) {
                    return reject("Erreur lors de la suppression des contrats associés à la filière.");
                }
            }
            return resolve("");
        });
    });
}
exports.deleteFaculty = deleteFaculty;
