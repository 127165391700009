"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Organizations = exports.Organization = void 0;
const astra_1 = require("astra");
const repositoryName = "organization";
class Organization extends astra_1.Document {
    name;
    constructor(orga) {
        super(repositoryName, orga);
        this.name = orga.name || "";
    }
}
exports.Organization = Organization;
class Organizations extends astra_1.Repository {
    constructor(docs) {
        super(repositoryName, Organizations, Organization, docs);
    }
}
exports.Organizations = Organizations;
exports.default = new Organizations();
