"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const message = "Request timed out";
function promiseWithTimeout(promise, timeout, callback) {
    const timeoutPromise = new Promise((_resolve, reject) => {
        setTimeout(() => {
            if (callback) {
                callback(message);
            }
            reject(message);
        }, timeout * 1000);
    });
    return Promise.race([promise, timeoutPromise]);
}
exports.default = promiseWithTimeout;
