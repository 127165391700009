"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.style = void 0;
const renderer_1 = require("@react-pdf/renderer");
exports.style = renderer_1.StyleSheet.create({
    ffU: {
        fontFamily: "Helvetica",
    },
    ffUr: {
        fontFamily: "Helvetica",
    },
    ffUb: {
        fontFamily: "Helvetica-Bold",
    },
    ffUbl: {
        fontFamily: "Helvetica-Bold",
    },
    ffUl: {
        fontFamily: "Helvetica",
    },
    ffUc: {
        fontFamily: "Helvetica",
    },
    ffUrc: {
        fontFamily: "Helvetica",
    },
    ffUbc: {
        fontFamily: "Helvetica-Bold",
    },
    ffUlc: {
        fontFamily: "Helvetica-Bold",
    },
    ffR: {
        fontFamily: "Helvetica",
    },
    ffRr: {
        fontFamily: "Helvetica",
    },
    ffRl: {
        fontFamily: "Helvetica",
    },
    ffRm: {
        fontFamily: "Helvetica-Bold",
    },
    ffRsb: {
        fontFamily: "Helvetica-Bold",
    },
    ffRb: {
        fontFamily: "Helvetica",
    },
    ffReb: {
        fontFamily: "Helvetica-Bold",
    },
    ffRh: {
        fontFamily: "Helvetica-Bold",
    },
    fs7: {
        fontSize: "7pt",
    },
    fs8: {
        fontSize: "8pt",
    },
    fs9: {
        fontSize: "9pt",
    },
    fs10: {
        fontSize: "10pt",
        lineHeight: "1.4pt"
    },
    fs11: {
        fontSize: "11pt",
    },
    fs12: {
        fontSize: "12pt",
    },
    fs13: {
        fontSize: "13pt",
    },
    fs14: {
        fontSize: "14pt",
    },
    fs15: {
        fontSize: "15pt",
    },
    fs16: {
        fontSize: "16pt",
    },
    fs18: {
        fontSize: "18pt",
    },
    fs20: {
        fontSize: "20pt",
    },
    fs22: {
        fontSize: "22pt",
    },
    fs24: {
        fontSize: "24pt",
    },
    fs26: {
        fontSize: "26pt",
    },
    fs28: {
        fontSize: "28pt",
    },
    fs30: {
        fontSize: "30pt",
    },
    fs32: {
        fontSize: "32pt",
    },
    fs34: {
        fontSize: "34pt",
    },
    fs36: {
        fontSize: "36pt",
    },
    fs38: {
        fontSize: "38pt",
    },
    fs40: {
        fontSize: "40pt",
    },
    fs42: {
        fontSize: "42pt",
    },
    tLeft: {
        textAlign: "left",
    },
    tCenter: {
        textAlign: "center",
    },
    tRight: {
        textAlign: "right",
    },
    tJustify: {
        textAlign: "justify",
    },
    upper: {
        textTransform: "uppercase",
    },
    lower: {
        textTransform: "lowercase",
    },
    white: {
        color: "#FFFFFF",
    },
    red: {
        color: "#e30613",
    },
    blue: {
        color: "#4EA8DE",
    },
    green: {
        color: "#43AA8B",
    },
    lightGrey: {
        color: "#eae7e1",
    },
    grey: {
        color: "#d3ccc6",
    },
    darkGrey: {
        color: "#667385",
    },
    lightBlack: {
        color: "#2d2a2e",
    },
    darkBlack: {
        color: "#000000",
    },
    bgWhite: {
        backgroundColor: "#FFFFFF",
    },
    bgRed: {
        backgroundColor: "#e30613",
    },
    bgBlue: {
        backgroundColor: "#4EA8DE",
    },
    bgGreen: {
        backgroundColor: "#43AA8B",
    },
    bgLightGrey: {
        backgroundColor: "#eae7e1",
    },
    bgGrey: {
        backgroundColor: "#d3ccc6",
    },
    bgDarkGrey: {
        backgroundColor: "#667385",
    },
    bgLightBlack: {
        backgroundColor: "#2d2a2e",
    },
    bgDarkBlack: {
        backgroundColor: "#000000",
    },
    m1: {
        margin: "1mm",
    },
    m2: {
        margin: "2mm",
    },
    m3: {
        margin: "5mm",
    },
    m4: {
        margin: "10mm",
    },
    m5: {
        margin: "15mm",
    },
    m6: {
        margin: "20mm",
    },
    mv1: {
        marginVertical: "1mm",
    },
    mv2: {
        marginVertical: "2mm",
    },
    mv3: {
        marginVertical: "5mm",
    },
    mv4: {
        marginVertical: "10mm",
    },
    mv5: {
        marginVertical: "15mm",
    },
    mv6: {
        marginVertical: "20mm",
    },
    mh1: {
        marginHorizontal: "1mm",
    },
    mh2: {
        marginHorizontal: "2mm",
    },
    mh3: {
        marginHorizontal: "5mm",
    },
    mh4: {
        marginHorizontal: "10mm",
    },
    mh5: {
        marginHorizontal: "15mm",
    },
    mh6: {
        marginHorizontal: "20mm",
    },
    mt1: {
        marginTop: "1mm",
    },
    mt2: {
        marginTop: "2mm",
    },
    mt3: {
        marginTop: "5mm",
    },
    mt4: {
        marginTop: "10mm",
    },
    mt5: {
        marginTop: "15mm",
    },
    mt6: {
        marginTop: "20mm",
    },
    mr1: {
        marginRight: "1mm",
    },
    mr2: {
        marginRight: "2mm",
    },
    mr3: {
        marginRight: "5mm",
    },
    mr4: {
        marginRight: "10mm",
    },
    mr5: {
        marginRight: "15mm",
    },
    mr6: {
        marginRight: "20mm",
    },
    mb1: {
        marginBottom: "1mm",
    },
    mb2: {
        marginBottom: "2mm",
    },
    mb3: {
        marginBottom: "5mm",
    },
    mb4: {
        marginBottom: "10mm",
    },
    mb5: {
        marginBottom: "15mm",
    },
    mb6: {
        marginBottom: "20mm",
    },
    ml1: {
        marginLeft: "1mm",
    },
    ml2: {
        marginLeft: "2mm",
    },
    ml3: {
        marginLeft: "5mm",
    },
    ml4: {
        marginLeft: "10mm",
    },
    ml5: {
        marginLeft: "15mm",
    },
    ml6: {
        marginLeft: "20mm",
    },
    p1: {
        padding: "1mm",
    },
    p2: {
        padding: "2mm",
    },
    p3: {
        padding: "5mm",
    },
    p4: {
        padding: "10mm",
    },
    p5: {
        padding: "15mm",
    },
    p6: {
        padding: "20mm",
    },
    pv1: {
        paddingVertical: "1mm",
    },
    pv2: {
        paddingVertical: "2mm",
    },
    pv3: {
        paddingVertical: "5mm",
    },
    pv4: {
        paddingVertical: "10mm",
    },
    pv5: {
        paddingVertical: "15mm",
    },
    pv6: {
        paddingVertical: "20mm",
    },
    ph1: {
        paddingHorizontal: "1mm",
    },
    ph2: {
        paddingHorizontal: "2mm",
    },
    ph3: {
        paddingHorizontal: "5mm",
    },
    ph4: {
        paddingHorizontal: "10mm",
    },
    ph5: {
        paddingHorizontal: "15mm",
    },
    ph6: {
        paddingHorizontal: "20mm",
    },
    pt1: {
        paddingTop: "1mm",
    },
    pt2: {
        paddingTop: "2mm",
    },
    pt3: {
        paddingTop: "5mm",
    },
    pt4: {
        paddingTop: "10mm",
    },
    pt5: {
        paddingTop: "15mm",
    },
    pt6: {
        paddingTop: "20mm",
    },
    pr1: {
        paddingRight: "1mm",
    },
    pr2: {
        paddingRight: "2mm",
    },
    pr3: {
        paddingRight: "5mm",
    },
    pr4: {
        paddingRight: "10mm",
    },
    pr5: {
        paddingRight: "15mm",
    },
    pr6: {
        paddingRight: "20mm",
    },
    pb1: {
        paddingBottom: "1mm",
    },
    pb2: {
        paddingBottom: "2mm",
    },
    pb3: {
        paddingBottom: "5mm",
    },
    pb4: {
        paddingBottom: "10mm",
    },
    pb5: {
        paddingBottom: "15mm",
    },
    pb6: {
        paddingBottom: "20mm",
    },
    pl1: {
        paddingLeft: "1mm",
    },
    pl2: {
        paddingLeft: "2mm",
    },
    pl3: {
        paddingLeft: "5mm",
    },
    pl4: {
        paddingLeft: "10mm",
    },
    pl5: {
        paddingLeft: "15mm",
    },
    pl6: {
        paddingLeft: "20mm",
    },
    flex: {
        display: "flex",
    },
    row: {
        display: "flex",
        flexDirection: "row",
    },
    col: {
        display: "flex",
        flexDirection: "column",
    },
    wrap: {
        flexWrap: "wrap",
    },
    nowrap: {
        flexWrap: "nowrap",
    },
    jStart: {
        justifyContent: "flex-start",
    },
    jEnd: {
        justifyContent: "flex-end",
    },
    jCenter: {
        justifyContent: "center",
    },
    jSpaceB: {
        justifyContent: "space-between",
    },
    jSpaceA: {
        justifyContent: "space-around",
    },
    aStart: {
        alignItems: "flex-start",
    },
    aEnd: {
        alignItems: "flex-end",
    },
    aCenter: {
        alignItems: "center",
    },
    aStretch: {
        alignItems: "stretch",
    },
    fg1: {
        flexGrow: 1,
    },
    fg2: {
        flexGrow: 2,
    },
    fg3: {
        flexGrow: 3,
    },
    fg4: {
        flexGrow: 4,
    },
    fg5: {
        flexGrow: 5,
    },
    fg6: {
        flexGrow: 6,
    },
    bWhite: {
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: "#FFFFFF",
    },
    btWhite: {
        borderTopStyle: "solid",
        borderTopWidth: 1,
        borderTopColor: "#FFFFFF",
    },
    brWhite: {
        borderRightStyle: "solid",
        borderRightWidth: 1,
        borderRightColor: "#FFFFFF",
    },
    bbWhite: {
        borderBottomStyle: "solid",
        borderBottomWidth: 1,
        borderBottomColor: "#FFFFFF",
    },
    blWhite: {
        borderLeftStyle: "solid",
        borderLeftWidth: 1,
        borderLeftColor: "#FFFFFF",
    },
    bLightGrey: {
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: "#eae7e1",
    },
    btLightGrey: {
        borderTopStyle: "solid",
        borderTopWidth: 1,
        borderTopColor: "#eae7e1",
    },
    brLightGrey: {
        borderRightStyle: "solid",
        borderRightWidth: 1,
        borderRightColor: "#eae7e1",
    },
    bbLightGrey: {
        borderBottomStyle: "solid",
        borderBottomWidth: 1,
        borderBottomColor: "#eae7e1",
    },
    blLightGrey: {
        borderLeftStyle: "solid",
        borderLeftWidth: 1,
        borderLeftColor: "#eae7e1",
    },
    bGrey: {
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: "#d3ccc6",
    },
    btGrey: {
        borderTopStyle: "solid",
        borderTopWidth: 1,
        borderTopColor: "#d3ccc6",
    },
    brGrey: {
        borderRightStyle: "solid",
        borderRightWidth: 1,
        borderRightColor: "#d3ccc6",
    },
    bbGrey: {
        borderBottomStyle: "solid",
        borderBottomWidth: 1,
        borderBottomColor: "#d3ccc6",
    },
    blGrey: {
        borderLeftStyle: "solid",
        borderLeftWidth: 1,
        borderLeftColor: "#d3ccc6",
    },
    bDarkGrey: {
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: "#667385",
    },
    btDarkGrey: {
        borderTopStyle: "solid",
        borderTopWidth: 1,
        borderTopColor: "#667385",
    },
    brDarkGrey: {
        borderRightStyle: "solid",
        borderRightWidth: 1,
        borderRightColor: "#667385",
    },
    bbDarkGrey: {
        borderBottomStyle: "solid",
        borderBottomWidth: 1,
        borderBottomColor: "#667385",
    },
    blDarkGrey: {
        borderLeftStyle: "solid",
        borderLeftWidth: 1,
        borderLeftColor: "#667385",
    },
    table: {
        display: "flex",
        width: "auto",
        borderStyle: "solid",
        backgroundColor: "#f7f4f0",
        paddingHorizontal: "5mm",
        borderTopLeftRadius: "2pt",
        borderTopRightRadius: "2pt",
        borderBottomRightRadius: "2pt",
        borderBottomLeftRadius: "2pt",
    },
    tr: {
        position: "relative",
        display: "flex",
        flexDirection: "row",
        alignItems: "stretch",
        minHeight: "20pt",
    },
    tc: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        position: "relative",
    },
    td: {
        width: "100%",
        padding: "2mm",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
        fontSize: "8pt",
    },
    th: {
        width: "100%",
        paddingBottom: "2mm",
        paddingRight: "2mm",
        paddingLeft: "2mm",
        paddingTop: "4mm",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
    },
    w0: {
        width: "0%",
        minWidth: "0%",
        maxWidth: "0%",
    },
    w5: {
        width: "5%",
        minWidth: "5%",
        maxWidth: "5%",
    },
    w10: {
        width: "10%",
        minWidth: "10%",
        maxWidth: "10%",
    },
    w15: {
        width: "15%",
        minWidth: "15%",
        maxWidth: "15%",
    },
    w20: {
        width: "20%",
        minWidth: "20%",
        maxWidth: "20%",
    },
    w25: {
        width: "25%",
        minWidth: "25%",
        maxWidth: "25%",
    },
    w30: {
        width: "30%",
        minWidth: "30%",
        maxWidth: "30%",
    },
    w35: {
        width: "35%",
        minWidth: "35%",
        maxWidth: "35%",
    },
    w40: {
        width: "40%",
        minWidth: "40%",
        maxWidth: "40%",
    },
    w45: {
        width: "45%",
        minWidth: "45%",
        maxWidth: "45%",
    },
    w50: {
        width: "50%",
        minWidth: "50%",
        maxWidth: "50%",
    },
    w55: {
        width: "55%",
        minWidth: "55%",
        maxWidth: "55%",
    },
    w60: {
        width: "60%",
        minWidth: "60%",
        maxWidth: "60%",
    },
    w65: {
        width: "65%",
        minWidth: "65%",
        maxWidth: "65%",
    },
    w70: {
        width: "70%",
        minWidth: "70%",
        maxWidth: "70%",
    },
    w75: {
        width: "75%",
        minWidth: "75%",
        maxWidth: "75%",
    },
    w80: {
        width: "80%",
        minWidth: "80%",
        maxWidth: "80%",
    },
    w85: {
        width: "85%",
        minWidth: "85%",
        maxWidth: "85%",
    },
    w90: {
        width: "90%",
        minWidth: "90%",
        maxWidth: "90%",
    },
    w95: {
        width: "95%",
        minWidth: "95%",
        maxWidth: "95%",
    },
    w100: {
        width: "100%",
        minWidth: "100%",
        maxWidth: "100%",
    },
});
