"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultConfig = void 0;
exports.defaultConfig = {
    startHour: 8,
    endHour: 20,
    step: 15,
    displayMode: "week",
    day: new Date(),
    days: 7,
    dayWidth: 90,
    minimumDuration: 15,
    flex: true,
    highlightDraggedTask: true,
    highlightPadding: 1,
    ghost: true,
    lazyPatch: false,
    focusLayer: true,
    firstDayOfWeek: "monday",
    deadlineDurationReplacer: 15,
    weekday: true,
    dayNumber: true,
    enableEditor: true,
    focusHours: true
};
const store = {
    config: exports.defaultConfig,
    clickDate: null,
    creating: null,
    calendarMenu: true,
    visiblePromotions: [],
    showTasksWithoutPromo: true,
    message: ""
};
exports.default = store;
