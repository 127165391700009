"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.releveMensuelSimple = void 0;
function daysInMonth(month, year) {
    return new Date(year, month + 1, 0).getDate();
}
function shortDate(date) {
    return `${String(date.getDate()).padStart(2, "0")}/${String(date.getMonth() + 1).padStart(2, "0")}/${String(date.getFullYear())}`;
}
const commaOrAnd = (i, length) => i < length - 2 ? ", " : i === length - 2 ? " et " : "";
function minutesToHours(m) {
    const minutes = m % 60;
    const hours = (m - minutes) / 60;
    return `${hours}h${String(minutes).padStart(2, "0")}`;
}
const shortDateString = (date) => `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
const secureString = (string) => string.replace(";", "").replace("\n", "");
const excelNumber = (number) => String(number).replace(".", ",");
function releveMensuelSimple(repositories, firstMonth, firstYear, lastMonth, lastYear) {
    const firstDate = new Date();
    firstDate.setFullYear(firstYear);
    firstDate.setMonth(firstMonth);
    firstDate.setDate(1);
    firstDate.setHours(0);
    firstDate.setMinutes(0);
    firstDate.setSeconds(0);
    firstDate.setMilliseconds(0);
    const lastDate = new Date();
    lastDate.setFullYear(lastYear);
    lastDate.setMonth(lastMonth);
    lastDate.setDate(daysInMonth(lastMonth, lastYear));
    lastDate.setHours(23);
    lastDate.setMinutes(59);
    lastDate.setSeconds(59);
    lastDate.setMilliseconds(999);
    const documentArray = [
        ["sep=;"],
        ["Relevé des formateurs", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""]
    ];
    documentArray[2] = [
        "Matricule",
        "Structure",
        "Statut des documents",
        "Date de création du CDC",
        "Date d'édition du CDC",
        "Date de création du Contrat",
        "Date d'édition du Contrat",
        "Date de signature du CDC/Contrat",
        "Date de début du CDC/Contrat",
        "Date de fin du CDC/Contrat",
        "Statut formateur",
        "Nom Prénom Formateur",
        "Filière",
        "Promotions",
        "Compétences",
        "Type d'intervention",
        "Intitulé",
        "Date",
        "Durée",
        "Taux horaire",
        "Montant",
    ];
    repositories.task.forEach((task) => {
        if (!task.date)
            return;
        if (firstDate.getTime() > task.date.getTime() ||
            task.date.getTime() > lastDate.getTime() ||
            task.cancelled ||
            !task.tarif ||
            task.promotions.length === 0 ||
            task.teachers.length === 0) {
            return;
        }
        task.teachers.forEach(teacherId => {
            if (task.cancelledTeachers && task.cancelledTeachers.includes(teacherId))
                return;
            const teacher = repositories.teacher.findById(teacherId);
            if (!teacher)
                return;
            const cdc = repositories.cdc.find((cdc) => cdc.tasks.includes(task._id) && cdc.teacher === teacher._id);
            const cdcInfos = cdc?.getTableInfos();
            let contrat;
            let cdcCreateDate, cdcEditDate, docSignDate, docStartDate, docEndDate, contratCreateDate, contratEditDate = "-";
            let contratInfos;
            let docStatus = "CDC non envoyé";
            if (cdc && cdc.teacher === teacherId && cdcInfos && !(cdcInfos instanceof Error)) {
                docStatus = cdc.signatureDate
                    ? "CDC signé"
                    : cdc?.validated
                        ? "CDC validé"
                        : "CDC envoyé";
                if (cdc.created_at)
                    cdcCreateDate = shortDateString(cdc.created_at);
                if (cdc.updated_at)
                    cdcEditDate = shortDateString(cdc.updated_at);
                if (cdc.signatureDate)
                    docSignDate = shortDateString(cdc.signatureDate);
                if (cdc.startDate)
                    docStartDate = shortDateString(cdc.startDate);
                if (cdc.endDate)
                    docEndDate = shortDateString(cdc.endDate);
                contrat = repositories.contrat.find((c) => c.cdcs.includes(cdc._id));
                contratInfos = contrat?.getTableInfos();
                if (contrat && contrat.teacher === teacherId && contratInfos && !(contratInfos instanceof Error)) {
                    docStatus = contrat?.signatureDate
                        ? "Contrat signé"
                        : "Contrat édité";
                    if (contrat.created_at)
                        contratCreateDate = shortDateString(contrat.created_at);
                    if (contrat.updated_at)
                        contratEditDate = shortDateString(contrat.updated_at);
                    if (contrat.signatureDate)
                        docSignDate = shortDateString(contrat.signatureDate);
                    if (contrat.startDate)
                        docStartDate = shortDateString(contrat.startDate);
                    if (contrat.endDate)
                        docEndDate = shortDateString(contrat.endDate);
                }
            }
            const skills = repositories.skill.filter(skill => task.promotions.some(p => p.skill === skill._id));
            if (!skills[0])
                return;
            const skillString = skills.reduce((string, skill, i) => {
                return `${string}${i > 0 ? ", " : ""}${skill.getCode()}`;
            }, "");
            const promotions = repositories.promotion.filter(promo => task.promotions.some(p => p.promotion === promo._id));
            if (!promotions[0])
                return;
            const promoString = promotions.reduce((string, promo, i) => {
                return `${string}${i > 0 ? ", " : ""}${promo.name}`;
            }, "");
            const faculty = repositories.faculty.findById(promotions[0]?.faculty);
            if (!faculty)
                return;
            const site = repositories.site.findById(faculty?.site);
            if (!site)
                return;
            const tarif = repositories.tarif.findById(task.tarif);
            if (!tarif)
                return;
            const taskType = repositories.taskType.findById(tarif.type);
            if (!taskType)
                return;
            const hourCost = teacher.statut === 4 ? tarif.contratTarif : tarif.prestationTarif;
            documentArray.push([
                secureString(teacher.matricule || "-"),
                (site.name || "-"),
                docStatus,
                cdcCreateDate,
                cdcEditDate,
                contratCreateDate,
                contratEditDate,
                docSignDate,
                docStartDate,
                docEndDate,
                teacher.getStatusString(),
                secureString(`${teacher.nom} ${teacher.prenom}`),
                secureString(faculty.sigle),
                secureString(promoString),
                secureString(skillString),
                secureString(taskType.name),
                secureString(task.title),
                shortDateString(task.date),
                excelNumber(task.duration / 60),
                excelNumber(hourCost),
                excelNumber(hourCost * task.duration / 60),
            ]);
        });
    });
    let CSV = "\ufeff\ufeff";
    for (let x = 0; x < documentArray.length; x++) {
        CSV += documentArray[x].join(';');
        CSV += "\n";
    }
    const hiddenElement = document.createElement('a');
    const blob = new Blob([CSV], {
        type: 'text/csv;charset=utf-8'
    });
    const href = window.URL.createObjectURL(blob);
    hiddenElement.href = href;
    hiddenElement.target = '_blank';
    hiddenElement.download = `releve mensuel ${String(firstMonth + 1).padStart(2, "0")}-${firstYear} ${String(lastMonth + 1).padStart(2, "0")}-${lastYear}.csv`;
    hiddenElement.click();
}
exports.releveMensuelSimple = releveMensuelSimple;
