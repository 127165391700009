"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Number = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const Input_1 = require("../Input/Input");
const Number = (props) => {
    const { min, max } = props;
    function handleMouseDown() { }
    function handleChange(event) {
        let value = event.target.value;
        value = value.replace(/[^0-9\.]/g, "");
        value = parseFloat(value);
        if (max && value > max) {
            value = max;
        }
        if (min && value < min) {
            value = min;
        }
        if (typeof value !== "number" || isNaN(value)) {
            value = min ? min : 0;
        }
        event.target.value = String(value);
        if (props.onChange) {
            props.onChange(event);
        }
    }
    return (0, jsx_runtime_1.jsx)(Input_1.Input, { ...props, className: "Number", type: "text", onChange: handleChange, onMouseDown: handleMouseDown });
};
exports.Number = Number;
