"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DefaultNotification = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_fontawesome_1 = require("@fortawesome/react-fontawesome");
const free_solid_svg_icons_1 = require("@fortawesome/free-solid-svg-icons");
require("./DefaultNotification.scss");
const DefaultNotification = (props) => {
    return ((0, jsx_runtime_1.jsxs)("div", { className: `Notification${props.className ? ` ${props.className}` : ""}`, style: props.style, children: [(0, jsx_runtime_1.jsx)("div", { className: "notificationChildrenContainer", children: props.children }), (0, jsx_runtime_1.jsx)(react_fontawesome_1.FontAwesomeIcon, { onClick: function () {
                    props.close(props.id);
                }, className: "closeNotificationIcon", icon: free_solid_svg_icons_1.faTimes })] }));
};
exports.DefaultNotification = DefaultNotification;
