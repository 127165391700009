"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TabsPages = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
require("./TabsPages.scss");
const TabsPages = (props) => {
    console.log("render tabspages");
    return ((0, jsx_runtime_1.jsx)("div", { className: "TabsPages", children: (0, jsx_runtime_1.jsx)("div", { className: "slideWrapper", style: {
                width: `${props.children.length * 100}%`,
                left: `${-(props.active || 0) * 100}%`
            }, children: props.children.map((child, i) => ((0, jsx_runtime_1.jsx)("div", { className: "TabPage", children: child }, i))) }) }));
};
exports.TabsPages = TabsPages;
