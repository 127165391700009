"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useWeekTasks = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_transition_group_1 = require("react-transition-group");
const TaskContainer_1 = require("./TaskContainer/TaskContainer");
const Editor_1 = require("./Editor/Editor");
const Task_model_1 = require("../../../models/Task.model");
function useWeekTasks(user, tasks, config, store, dispatch, RenderEditor, onPost, onPatch, onDelete, lock, lockResize, lockMove) {
    if (!store.firstDayOfTheWeek)
        return [];
    const dayDurationMinutes = (config.endHour - config.startHour) * 60;
    const weekStartMinutes = config.startHour * 60;
    const delta = weekStartMinutes / dayDurationMinutes;
    const dayWidth = 100 / config.days;
    const innerDayWidth = (1 / config.days) * config.dayWidth;
    let tasksArray = [...tasks];
    let draggedTask;
    if (config.highlightDraggedTask
        && (store.editing !== null
            && store.mouseAction !== null)
        || store.editorState !== "close") {
        draggedTask = tasksArray.find(task => task._id === store.editing);
        if (store.editorState === "open") {
            tasksArray = tasksArray.filter(task => task._id !== store.editing);
        }
    }
    if (store.mouseAction === "drag_clone" && store.tempTask) {
        draggedTask = store.tempTask;
    }
    if (store.clickTask) {
        tasksArray = [...tasksArray, { ...store.clickTask, ghost: true }];
    }
    const weekStart = new Date(config.days === 1 ? config.day : store.firstDayOfTheWeek);
    weekStart.setHours(config.startHour);
    weekStart.setMinutes(0);
    weekStart.setSeconds(0);
    weekStart.setMilliseconds(0);
    const weekEnd = new Date(weekStart);
    weekEnd.setDate(weekEnd.getDate() + config.days);
    weekEnd.setHours(config.endHour);
    const tasksContainersToDisplay = [];
    tasksArray.forEach(task => {
        if (!task.date)
            return;
        const taskEnd = new Date(task.date);
        const duration = task.deadline || task.duration < config.minimumDuration
            ? config.minimumDuration : task.duration;
        taskEnd.setMinutes(taskEnd.getMinutes() + duration);
        if (overlap(task.date, taskEnd, weekStart, weekEnd)) {
            tasksContainersToDisplay.push({
                start: task.date,
                end: taskEnd,
                taskObject: task
            });
        }
    });
    tasksContainersToDisplay.sort((taskContainerA, taskContainerB) => taskContainerA.start.getTime() - taskContainerB.start.getTime()
        || taskContainerB.taskObject.duration - taskContainerA.taskObject.duration);
    const days = [];
    for (let dayIndex = 0; dayIndex < config.days; dayIndex++) {
        const dayStart = new Date(weekStart);
        dayStart.setDate(dayStart.getDate() + dayIndex);
        const dayEnd = new Date(dayStart);
        dayEnd.setHours(config.endHour);
        days.push({
            dayIndex,
            offset: dayWidth * dayIndex,
            start: dayStart,
            end: dayEnd,
            tasksContainers: []
        });
    }
    tasksContainersToDisplay.forEach(taskContainer => {
        daysLoop: for (let i = 0; i < days.length; i++) {
            if (overlap(taskContainer.start, taskContainer.end, days[i].start, days[i].end)) {
                days[i].tasksContainers.push(taskContainer);
                break daysLoop;
            }
        }
    });
    const positionnedTasksContainers = [];
    days.forEach(day => {
        const overlapingRegions = [];
        day.tasksContainers.forEach(taskContainer => {
            const pushNew = () => {
                overlapingRegions.push({
                    start: new Date(taskContainer.start),
                    end: new Date(taskContainer.end),
                    tasksContainers: [taskContainer]
                });
            };
            if (overlapingRegions.length === 0) {
                pushNew();
                return;
            }
            const overlapingRegion = overlapingRegions.find(OR => overlap(taskContainer.start, taskContainer.end, OR.start, OR.end));
            if (!overlapingRegion) {
                pushNew();
                return;
            }
            if (taskContainer.end.getTime() > overlapingRegion.end.getTime()) {
                overlapingRegion.end = new Date(taskContainer.end);
            }
            overlapingRegion.tasksContainers.push(taskContainer);
        });
        overlapingRegions.map(overlapingRegion => {
            const columns = [];
            overlapingRegion.tasksContainers.forEach(taskContainer => {
                const pushNew = () => {
                    columns.push({
                        start: new Date(taskContainer.start),
                        end: new Date(taskContainer.end),
                        tasksContainers: [taskContainer]
                    });
                };
                if (columns.length === 0) {
                    pushNew();
                    return;
                }
                let noOverlapColumn;
                if (!(taskContainer.taskObject._id === store.editing)
                    && !taskContainer.taskObject.ghost) {
                    noOverlapColumn = columns.find(col => !overlap(taskContainer.start, taskContainer.end, col.start, col.end));
                }
                else {
                    noOverlapColumn = columns.find(col => (col.tasksContainers.every(taskC => taskC.taskObject._id === taskContainer.taskObject._id
                        ||
                            !overlap(taskContainer.start, taskContainer.end, taskC.start, taskC.end))));
                }
                if (!noOverlapColumn) {
                    pushNew();
                    return;
                }
                noOverlapColumn.end = new Date(taskContainer.end);
                noOverlapColumn.tasksContainers.push(taskContainer);
            });
            columns.forEach((column, columnIndex) => {
                const columnWidth = innerDayWidth / columns.length;
                column.tasksContainers.forEach(taskContainer => {
                    const taskStartMinutes = taskContainer.start.getHours() * 60 + taskContainer.start.getMinutes();
                    const top = (taskStartMinutes / dayDurationMinutes - delta) * 100;
                    const duration = taskContainer.taskObject.deadline ? config.deadlineDurationReplacer : taskContainer.taskObject.duration;
                    const height = (duration / dayDurationMinutes) * 100;
                    const innerLeft = columnWidth * columnIndex;
                    const left = day.offset + innerLeft;
                    const width = store.editorState === "open" && taskContainer.taskObject._id === store.editing
                        && !taskContainer.taskObject.ghost
                        ? dayWidth
                        : columnWidth;
                    const positionnedTaskContainer = {
                        ...taskContainer,
                        left,
                        top,
                        width,
                        height
                    };
                    positionnedTasksContainers.push(positionnedTaskContainer);
                });
            });
        });
    });
    if (draggedTask && store.editorState === "open") {
        const taskStartMinutes = draggedTask.date.getHours() * 60 + draggedTask.date.getMinutes();
        const draggedTaskEnd = new Date(draggedTask.date);
        draggedTaskEnd.setMinutes(draggedTaskEnd.getMinutes() + draggedTask.duration);
        let dayIndex = 0;
        const navigateDay = new Date(weekStart);
        for (let i = 0; i < config.days; i++) {
            dayIndex = i;
            if (navigateDay.getDate() === draggedTask.date.getDate()
                && navigateDay.getDate() === draggedTask.date.getDate()
                && navigateDay.getDate() === draggedTask.date.getDate()) {
                break;
            }
            navigateDay.setDate(navigateDay.getDate() + 1);
        }
        const duration = draggedTask.deadline ? config.deadlineDurationReplacer : draggedTask.duration;
        positionnedTasksContainers.push({
            taskObject: draggedTask,
            start: new Date(draggedTask.date),
            end: new Date(),
            left: dayIndex * dayWidth,
            top: (taskStartMinutes / dayDurationMinutes - delta) * 100,
            width: dayWidth,
            height: (duration / dayDurationMinutes) * 100
        });
    }
    positionnedTasksContainers.sort((a, b) => a.taskObject._id > b.taskObject._id ? 1
        : a.taskObject.ghost && !b.taskObject.ghost ? 1
            : -1);
    const TasksContainersJSXElements = positionnedTasksContainers.map((taskContainer) => ((0, jsx_runtime_1.jsx)(react_transition_group_1.CSSTransition, { id: `${taskContainer.taskObject._id}${taskContainer.taskObject.ghost ? ' ghost' : ""}`, timeout: 200, classNames: "taskContainerTransition", children: (0, jsx_runtime_1.jsx)(TaskContainer_1.TaskContainer, { task: taskContainer.taskObject, left: taskContainer.left, top: taskContainer.top, width: taskContainer.width, height: taskContainer.height, ghost: taskContainer.taskObject.ghost, dragged: taskContainer.taskObject._id === store.editing, writing: false, lastEdited: taskContainer.taskObject._id === store.lastEdited, config: config, dispatch: dispatch }, `${taskContainer.taskObject._id}${taskContainer.taskObject.ghost ? ' ghost' : ""}`) }, `${taskContainer.taskObject._id}${taskContainer.taskObject.ghost ? ' ghost' : ""}`)));
    let EditorComponent;
    if (store.editorState === "open" && store.editing && RenderEditor) {
        const positionnedTaskContainer = positionnedTasksContainers.find(ptc => ptc.taskObject._id === store.editing
            && !ptc.taskObject.ghost);
        if (positionnedTaskContainer) {
            EditorComponent = ((0, jsx_runtime_1.jsx)(react_transition_group_1.CSSTransition, { timeout: 500, children: (0, jsx_runtime_1.jsx)(Editor_1.Editor, { taskContainer: positionnedTaskContainer, config: config, children: (0, jsx_runtime_1.jsx)(RenderEditor, { task: positionnedTaskContainer.taskObject, config: config, rect: {
                            left: positionnedTaskContainer.left,
                            top: positionnedTaskContainer.top,
                            width: positionnedTaskContainer.width,
                            height: positionnedTaskContainer.height
                        }, post: onPost, patch: onPatch, delete: onDelete, close: () => dispatch("close_editor", {}), clone: () => dispatch("mousedown_clone", {
                            task: new Task_model_1.Task({
                                date: positionnedTaskContainer.taskObject.date,
                                duration: positionnedTaskContainer.taskObject.duration,
                                title: positionnedTaskContainer.taskObject.title,
                                description: positionnedTaskContainer.taskObject.description,
                                tarif: positionnedTaskContainer.taskObject.tarif,
                                teachers: positionnedTaskContainer.taskObject.teachers,
                                promotions: positionnedTaskContainer.taskObject.promotions,
                                deadline: positionnedTaskContainer.taskObject.deadline,
                                author: user._id
                            })
                        }) }) }) }, "Editor"));
        }
    }
    return [TasksContainersJSXElements, EditorComponent];
}
exports.useWeekTasks = useWeekTasks;
function overlap(startA, endA, startB, endB) {
    return ((startB <= startA && startA < endB) ||
        (startB < endA && endA <= endB) ||
        (startA <= startB && startB < endA) ||
        (startA < endB && endB <= endA));
}
