"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.head = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const TeacherEmailButton_1 = require("../../../components/TeacherEmailButton/TeacherEmailButton");
exports.head = [
    {
        label: "Statut",
        key: "statut",
        size: 10,
        type: "table",
        fixed: true,
        table: {
            "1": "Formateur permanent",
            "2": "Intervenant à titre gracieux",
            "3": "Prestataire de service",
            "4": "Vacataire en CTU",
        },
        sortable: true,
    },
    {
        label: "Nom",
        key: "nom",
        size: 7,
        sortable: true,
        fixed: true,
        type: "string",
    },
    {
        label: "Prénom",
        key: "prenom",
        size: 7,
        sortable: true,
        fixed: true,
        type: "string",
    },
    {
        label: "Nom de naissance",
        key: "nom_de_naissance",
        size: 8,
        sortable: true,
        type: "string",
    },
    {
        label: "Date de naissance",
        key: "date_de_naissance",
        size: 15,
        sortable: true,
        type: "shortdate",
    },
    {
        label: "Lieu de naissance",
        key: "lieu_de_naissance",
        size: 7,
        type: "string",
    },
    {
        label: "Téléphone fixe",
        key: "telephone_fixe",
        size: 7,
        type: "string",
    },
    {
        label: "Téléphone portable",
        key: "telephone_portable",
        size: 7,
        type: "string",
    },
    {
        label: "E-mail",
        key: "email",
        size: 20,
        type: "function",
        function: (teacher) => (0, jsx_runtime_1.jsx)(TeacherEmailButton_1.TeacherEmailButton, { teacher: teacher })
    },
    {
        label: "Numéro",
        key: "numero",
        size: 5,
        type: "string",
    },
    {
        label: "Bis/Ter",
        key: "bis_ter",
        size: 5,
        type: "string",
    },
    {
        label: "Nature de la voie",
        key: "nature_de_la_voie",
        size: 8,
        type: "string",
    },
    {
        label: "Nom de la voie",
        key: "nom_de_la_voie",
        size: 10,
        type: "string",
    },
    {
        label: "Complément d'adresse",
        key: "complement_d_adresse",
        size: 10,
        type: "string",
    },
    {
        label: "Code postal",
        key: "code_postal",
        size: 4,
        sortable: true,
        type: "string",
    },
    {
        label: "Bureau distributeur",
        key: "bureau_distributeur",
        size: 10,
        sortable: true,
        type: "string",
    },
    {
        label: "Numéro de sécurité sociale",
        key: "numero_de_securite_sociale",
        size: 10,
        type: "string",
    },
    {
        label: "Clé",
        key: "cle",
        size: 5,
        sortable: true,
        type: "string",
    },
    {
        label: "Métier",
        key: "metier",
        size: 10,
        sortable: true,
        type: "string",
    },
    {
        label: "Employeur",
        key: "employeur",
        size: 13,
        sortable: true,
        type: "string",
    },
    {
        label: "Diplôme",
        key: "diplome",
        size: 15,
        sortable: true,
        type: "string",
    },
    {
        label: "Agréé par l'université",
        key: "agree_par_l_universite",
        size: 8,
        sortable: true,
        type: "boolean",
    },
    {
        label: "Chercheur",
        key: "chercheur",
        size: 10,
        sortable: true,
        type: "boolean",
    },
    {
        label: "Nom de l'association",
        key: "nom_association",
        size: 10,
        sortable: true,
        type: "string",
    },
    {
        label: "Nationalité",
        key: "nationalite",
        size: 10,
        sortable: true,
        type: "string",
    },
    {
        label: "Date d'admission du dossier",
        key: "date_dossier_adm",
        size: 10,
        sortable: true,
        type: "date",
    },
    {
        label: "Matricule",
        key: "matricule",
        size: 7,
        sortable: true,
        type: "string",
    },
    {
        label: "Structure",
        key: "structure",
        size: 7,
        sortable: true,
        type: "string",
    },
];
