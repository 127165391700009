"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TeacherEmailButton = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
require("./TeacherEmailButton.scss");
const TeacherEmailButton = (props) => {
    const [clicked, setClicked] = (0, react_1.useState)("initial");
    const onClick = (event) => {
        event.stopPropagation();
        event.preventDefault();
        navigator.clipboard.writeText(props.teacher.email || "");
        setClicked("clicked");
        setTimeout(() => setClicked("back"), 2000);
    };
    return ((0, jsx_runtime_1.jsxs)("div", { className: "TeacherEmailButton", onClick: onClick, children: [(0, jsx_runtime_1.jsx)("div", { className: `teacherEmail ${clicked === "initial" ? "initial" : clicked === "clicked" ? "hidden" : "shown"}`, children: props.teacher.email }), (0, jsx_runtime_1.jsx)("div", { className: `teacherEmail ${clicked === "initial" ? "hehe" : clicked === "clicked" ? "shown" : "hidden"}`, children: "copi\u00E9" })] }));
};
exports.TeacherEmailButton = TeacherEmailButton;
