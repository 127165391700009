"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Input = void 0;
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = tslib_1.__importStar(require("react"));
const RequiredSymbol_1 = require("../RequiredSymbol/RequiredSymbol");
require("./Input.scss");
const react_fontawesome_1 = require("@fortawesome/react-fontawesome");
const Input = (props) => {
    const [value, setValue] = (0, react_1.useState)(props.value || props.defaultValue || "");
    const ref = react_1.default.createRef();
    const timeout = (0, react_1.useRef)();
    (0, react_1.useEffect)(() => {
        if (props.autofocus) {
            if (ref && ref.current) {
                ref.current.focus();
            }
            if (props.inputRef && props.inputRef.current) {
                props.inputRef.current.focus();
            }
        }
    }, []);
    (0, react_1.useEffect)(() => {
        if (typeof props.value === "string") {
            setValue(props.value);
        }
    }, [props.value]);
    function clearInputTimeout() {
        if (timeout.current) {
            window.clearTimeout(timeout.current);
            timeout.current = 0;
        }
    }
    function handleChange(event) {
        let newValue = event.target.value;
        if (props.changeHandler) {
            const backward = newValue.length < value.length;
            newValue = props.changeHandler(event.target.value, backward);
        }
        setValue(newValue);
        const change = () => {
            if (props.onChange) {
                event.target.value = newValue;
                props.onChange(event);
            }
            if (props.onInputChange) {
                props.onInputChange(newValue);
            }
        };
        if (props.onChange || props.onInputChange) {
            if (props.delay) {
                clearInputTimeout();
                timeout.current = window.setTimeout(() => {
                    change();
                }, props.delay);
            }
            else {
                change();
            }
        }
    }
    function handleKeyDown(event) {
        if (event.key === "Enter" && event.target) {
            const target = event.target;
            target.blur();
        }
        if (props.onKeyDown) {
            props.onKeyDown(event);
        }
    }
    function handleFocus(event) {
        if (props.onFocus) {
            props.onFocus(event);
        }
    }
    function handleBlur(event) {
        clearInputTimeout();
        if (props.onChange && props.value !== value) {
            props.onChange(event);
        }
        if (props.onInputChange && props.value !== value) {
            props.onInputChange(value);
        }
        if (props.blurHandler) {
            event.target.value = props.blurHandler(event.target.value);
        }
        if (props.onBlur) {
            props.onBlur(event);
        }
    }
    function focus() {
        console.log("focus");
        if (ref && ref.current) {
            console.log(ref);
            ref.current.focus();
        }
        if (props.inputRef && props.inputRef.current) {
            console.log("inputRef");
            props.inputRef.current.focus();
        }
    }
    return ((0, jsx_runtime_1.jsxs)("div", { className: `Input${props.className ? " " + props.className : ""}`, onClick: props.inputRef && props.inputRef.current
            ? () => props.inputRef.current.focus()
            : ref && ref.current
                ? () => ref.current.focus()
                : () => false, style: props.style, children: [props.icon && (0, jsx_runtime_1.jsx)(react_fontawesome_1.FontAwesomeIcon, { icon: props.icon }), (0, jsx_runtime_1.jsx)("input", { placeholder: " ", name: props.name, id: props.id ? props.name + props.id : props.name, value: value, defaultValue: props.defaultValue, title: "", autoComplete: props.autocomplete ? props.autocomplete : "off", ref: props.inputRef || ref, type: props.type ? props.type : "text", onFocus: handleFocus, onKeyDown: handleKeyDown, onChange: handleChange, onBlur: handleBlur, required: props.required }), (0, jsx_runtime_1.jsxs)("label", { htmlFor: props.id ? props.name + props.id : props.name, onMouseDown: focus, onClick: focus, children: [props.label, props.required && (0, jsx_runtime_1.jsx)(RequiredSymbol_1.RequiredSymbol, {})] })] }));
};
exports.Input = Input;
