"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeleteListButtons = exports.DeleteListButton = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const astra_1 = require("astra");
const free_solid_svg_icons_1 = require("@fortawesome/free-solid-svg-icons");
const DeleteListButton = (props) => {
    const accordSelected = props.selected.length > 1 ? "s" : "";
    const actionVerb = props.type === "destroy"
        ? "Supprimer définitivement"
        : props.type === "restore"
            ? "Restaurer"
            : "Supprimer";
    return ((0, jsx_runtime_1.jsx)(astra_1.Button, { icon: props.type === "destroy" ? free_solid_svg_icons_1.faFire : props.type === "restore" ? free_solid_svg_icons_1.faRecycle : free_solid_svg_icons_1.faTrash, filled: true, className: `deleteButton ${props.type}`, disabled: props.selected.length === 0, name: props.selected.length === 0 ? "" : `${actionVerb} ${props.selected.length} ${props.documentName}${accordSelected}`, onClick: () => props.modal({
            title: `${actionVerb} ${props.selected.length} ${props.documentName}${accordSelected} ?`,
            resolveButton: `${actionVerb} ${props.selected.length} ${props.documentName}${accordSelected}.`,
            rejectButton: "Annuler",
        })
            .then(() => {
            props.method(props.selected)
                .then(() => {
                props.notify(`${props.selected.length} ${props.documentName}${accordSelected} ${props.type === "destroy" ? `définitivement supprimé` : props.type === "remove" ? `supprimé` : `restauré`}${props.accord}${accordSelected}.`, "green");
                props.onUse();
            })
                .catch((e) => props.notify(`Erreur: ${e}`, "red"));
        })
            .catch(() => { }) }));
};
exports.DeleteListButton = DeleteListButton;
const DeleteListButtons = (props) => {
    const accord = props.femAccord || "";
    if (props.removedListView) {
        return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(exports.DeleteListButton, { documentName: props.documentName, notify: props.notify, modal: props.modal, type: "restore", method: (selected) => props.repository.restore(selected).send(), selected: props.selected, onUse: props.onUse, accord: accord }, 'restore'), (0, jsx_runtime_1.jsx)(exports.DeleteListButton, { documentName: props.documentName, notify: props.notify, modal: props.modal, type: "destroy", method: (selected) => props.repository.destroy(selected).send(), selected: props.selected, onUse: props.onUse, accord: accord }, 'destroy')] }));
    }
    else {
        return ((0, jsx_runtime_1.jsx)(exports.DeleteListButton, { documentName: props.documentName, notify: props.notify, modal: props.modal, type: "remove", method: (selected) => props.repository.remove(selected).send(), selected: props.selected, onUse: props.onUse, accord: accord }, 'remove'));
    }
};
exports.DeleteListButtons = DeleteListButtons;
