"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Faculties = exports.Faculty = void 0;
const astra_1 = require("astra");
const repositoryName = "faculty";
class Faculty extends astra_1.Document {
    sigle;
    name;
    site;
    years;
    skillsLevels;
    constructor(faculty) {
        super(repositoryName, faculty);
        this.sigle = faculty.sigle ? faculty.sigle : "";
        this.name = faculty.name ? faculty.name : "";
        this.site = faculty.site;
        this.years = faculty.years || [2];
        this.skillsLevels = faculty.skillsLevels || [{ name: "", sigle: "" }];
    }
    getYearsArray = () => {
        return this.memo(() => {
            const yearsArray = [];
            for (let l = 1; l <= this.years.length; l++) {
                yearsArray.push(l);
            }
            return yearsArray;
        }, [this.years], "getYearsArray");
    };
    getFacultyString = () => {
        return this.memo(() => {
            return `${this.sigle} - ${this.name}`;
        }, [this.name, this.sigle], "getFacultyString");
    };
    getMainSkills = () => {
        return this.getRepositories().mainSkill.filter(ms => ms.faculty === this._id);
    };
    getSkills = () => {
        const mainSkills = this.getMainSkills();
        return this.getRepositories().skill.filter(skill => mainSkills.some(ms => ms._id === skill.mainSkill));
    };
    getSkillsBySemester = (semester) => {
        const skills = this.getSkills();
        return skills.filter(skill => skill.semester === semester);
    };
}
exports.Faculty = Faculty;
class Faculties extends astra_1.Repository {
    constructor(docs) {
        super(repositoryName, Faculties, Faculty, docs);
    }
}
exports.Faculties = Faculties;
exports.default = new Faculties();
