"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function nameBH(value) {
    if (value.length) {
        let string = value;
        string = string.replace(/[ '-]$/, "");
        return string;
    }
    else {
        return "";
    }
}
exports.default = nameBH;
