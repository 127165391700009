"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ColorPicker = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const __1 = require("..");
const ViewComposer_1 = require("../../core/ViewComposer/ViewComposer");
const ColorList_1 = require("./ColorList/ColorList");
require("./ColorPicker.scss");
function ColorPicker(props) {
    const sunContext = (0, ViewComposer_1.useSunContext)();
    const [choosenColor, choose] = (0, react_1.useState)(props.value);
    function handleChange(value) {
        if (props.onChange) {
            props.onChange(value);
        }
        choose(value);
    }
    return ((0, jsx_runtime_1.jsxs)("div", { className: `ColorPicker${props.className ? ` ${props.className}` : ""}`, style: props.style, children: [(0, jsx_runtime_1.jsxs)(__1.Button, { onClick: (event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    sunContext.modal({
                        form: (resolve, _reject) => (0, jsx_runtime_1.jsx)(ColorList_1.ColorList, { resolve: resolve })
                    })
                        .then((color) => handleChange(color))
                        .catch(() => { });
                }, style: { backgroundColor: choosenColor ? choosenColor : undefined }, className: `colorPickerButton${choosenColor && " choosen"}`, children: ["S\u00E9lectionner une couleur", props.required && (0, jsx_runtime_1.jsx)(__1.RequiredSymbol, {})] }), (0, jsx_runtime_1.jsx)("input", { type: "hidden", value: choosenColor ? choosenColor : "", name: props.name, required: props.required })] }));
}
exports.ColorPicker = ColorPicker;
