"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Col = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
require("./Col.scss");
const Col = (props) => {
    return ((0, jsx_runtime_1.jsx)("div", { className: `Col ${props.className || ""}`, style: {
            flex: props.flex6 ? 6 : props.flex5 ? 5 : props.flex4 ? 4 : props.flex3 ? 3 : props.flex2 ? 2 : 1,
            ...props.style
        }, children: props.children }));
};
exports.Col = Col;
