"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Teachers = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const free_solid_svg_icons_1 = require("@fortawesome/free-solid-svg-icons");
const react_fontawesome_1 = require("@fortawesome/react-fontawesome");
require("./Teachers.scss");
function Teachers(props) {
    return ((0, jsx_runtime_1.jsx)("div", { className: "Teachers", children: props.teachers.map(teacher => ((0, jsx_runtime_1.jsxs)("div", { className: "Teacher", children: [(0, jsx_runtime_1.jsx)("div", { className: "remove", onClick: () => props.onRemove && props.onRemove(teacher), children: (0, jsx_runtime_1.jsx)(react_fontawesome_1.FontAwesomeIcon, { icon: free_solid_svg_icons_1.faTimes }) }), teacher.prenom, " ", teacher.nom.toUpperCase()] }))) }));
}
exports.Teachers = Teachers;
