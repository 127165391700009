"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SemestersTree = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
require("./SemestersTree.scss");
const astra_1 = require("astra");
const free_solid_svg_icons_1 = require("@fortawesome/free-solid-svg-icons");
const SemestersTree = (props) => {
    const addYear = () => {
        props.onChange && props.onChange([...props.value, 2]);
    };
    const removeYear = () => {
        props.onChange && props.onChange(props.value.slice(0, props.value.length - 1));
    };
    const addSemester = (yearIndex) => {
        props.onChange && props.onChange(props.value.map((year, i) => i === yearIndex ? year + 1 : year));
    };
    const removeSemester = (yearIndex) => {
        props.onChange && props.onChange(props.value.map((year, i) => i === yearIndex ? year - 1 : year));
    };
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)("ul", { className: "SemestersTree", children: props.value.map((year, yearIndex) => {
                    const semesters = [];
                    for (let i = 1; i <= year; i++) {
                        semesters.push(i);
                    }
                    const semesterName = year === 1 ? "Année scolaire" : year === 2 ? "Semestre" : year === 3 ? "Trimestre" : "Partie";
                    return ((0, jsx_runtime_1.jsxs)("li", { className: "Year", children: [(0, jsx_runtime_1.jsxs)(astra_1.Row, { children: [(0, jsx_runtime_1.jsxs)("p", { children: ["Ann\u00E9e ", yearIndex + 1] }), props.edit && yearIndex === props.value.length - 1 && props.value.length > 1 && ((0, jsx_runtime_1.jsx)(astra_1.Button, { icon: free_solid_svg_icons_1.faMinus, filled: true, className: "removeButton", onClick: removeYear }))] }), (0, jsx_runtime_1.jsxs)("ul", { children: [semesters.length > 1 && semesters.map((semester, semesterIndex) => {
                                        return ((0, jsx_runtime_1.jsxs)("li", { className: "Semester", children: [(0, jsx_runtime_1.jsxs)("p", { children: [semesterName, " ", semester] }), props.edit && semesterIndex === semesters.length - 1 && semesters.length > 1 && ((0, jsx_runtime_1.jsx)(astra_1.Button, { icon: free_solid_svg_icons_1.faMinus, filled: true, className: "removeButton", onClick: () => removeSemester(yearIndex) }))] }, semesterIndex));
                                    }), props.edit && ((0, jsx_runtime_1.jsx)("li", { className: "Semester", children: (0, jsx_runtime_1.jsx)(astra_1.Button, { icon: free_solid_svg_icons_1.faPlus, filled: true, className: "addButton", onClick: () => addSemester(yearIndex) }) }))] })] }, yearIndex));
                }) }), props.edit && ((0, jsx_runtime_1.jsx)(astra_1.Button, { icon: free_solid_svg_icons_1.faPlus, filled: true, className: "addButton", name: "Ann\u00E9e", onClick: addYear }))] }));
};
exports.SemestersTree = SemestersTree;
