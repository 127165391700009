"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TopBar = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const BurgerButton_1 = require("../../components/BurgerButton/BurgerButton");
require("./TopBar.scss");
const TopBar = (props) => {
    return ((0, jsx_runtime_1.jsxs)("header", { className: `TopBar ${props.className ? props.className : ""}`, children: [(0, jsx_runtime_1.jsx)(BurgerButton_1.BurgerButton, { active: props.leftBarState, onClick: () => {
                    props.toggleLeftBar && props.toggleLeftBar(!props.leftBarState);
                } }), (0, jsx_runtime_1.jsx)("div", { className: "childrenContainer", children: props.children })] }));
};
exports.TopBar = TopBar;
