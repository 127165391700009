"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CDCs = exports.CDC = void 0;
const astra_1 = require("astra");
const minutesToHourString = (minutes) => {
    const totalMinutes = minutes % 60;
    const totalHours = (minutes - totalMinutes) / 60;
    return `${totalHours}h${totalMinutes > 0 ? String(totalMinutes).padStart(2, "0") : ""}`;
};
const shortDate = (date) => `${String(date.getDate()).padStart(2, "0")}/${String(date.getMonth() + 1).padStart(2, "0")}/${date.getFullYear()}`;
const repositoryName = "cdc";
class CDC extends astra_1.Document {
    teacher;
    faculty;
    tasks;
    signatureDate;
    validated;
    author;
    startDate;
    endDate;
    constructor(cdc) {
        super(repositoryName, cdc);
        this.teacher = cdc.teacher;
        this.faculty = cdc.faculty;
        this.tasks = cdc.tasks ? cdc.tasks : [];
        this.author = cdc.author;
        this.validated = cdc.validated ? true : false;
        this.signatureDate = cdc.signatureDate ? new Date(cdc.signatureDate) : undefined;
        this.startDate = new Date(cdc.startDate);
        this.endDate = new Date(cdc.endDate);
    }
    getTableInfos = () => this.memo(() => {
        if (!this.getRepositories().cdc.loaded ||
            !this.getRepositories().contrat.loaded ||
            !this.getRepositories().faculty.loaded ||
            !this.getRepositories().mainSkill.loaded ||
            !this.getRepositories().promotion.loaded ||
            !this.getRepositories().site.loaded ||
            !this.getRepositories().skill.loaded ||
            !this.getRepositories().tarif.loaded ||
            !this.getRepositories().task.loaded ||
            !this.getRepositories().taskType.loaded ||
            !this.getRepositories().teacher.loaded) {
            return new Error("Chargement");
        }
        const tableInfos = {
            CDC: this,
            _id: this._id,
            totalMinutes: 0,
            totalTarif: 0,
            tasksArray: [],
            taskTypesArray: [],
        };
        tableInfos.faculty = this.getRepositories().faculty.find((f) => f._id === this.faculty);
        if (!tableInfos.faculty)
            return new Error("Filière introuvable.");
        tableInfos.facultyString = tableInfos.faculty.getFacultyString();
        tableInfos.site = this.getRepositories().site.find((s) => s._id === tableInfos.faculty?.site);
        if (!tableInfos.site)
            return new Error("Site introuvable.");
        tableInfos.siteString = tableInfos.site.toString();
        tableInfos.teacher = this.getRepositories().teacher.find((t) => t._id === this.teacher);
        if (!tableInfos.teacher)
            return new Error("Professeur introuvable.");
        tableInfos.shortTeacherString = `${tableInfos.teacher.prenom} ${tableInfos.teacher.nom}`;
        tableInfos.statut = tableInfos.teacher.statut === 1 ? "Formateur permanent"
            : tableInfos.teacher.statut === 2 ? "Intervenant à titre gracieux"
                : tableInfos.teacher.statut === 3 ? "Prestataire de service"
                    : "Vacataire",
            tableInfos.contrat = this.getRepositories().contrat.find((c) => c.cdcs.includes(this._id));
        tableInfos.taskTypesArray = this.tasks.reduce((array, task_id) => {
            const task = this.getRepositories().task.find(t => t._id === task_id);
            if (!task)
                return array;
            tableInfos.tasksArray.push(task);
            if (task.duration) {
                tableInfos.totalMinutes += task.duration;
                const tarif = this.getRepositories().tarif.find((t) => t._id === task.tarif);
                if (tarif) {
                    const taskType = this.getRepositories().taskType.find((t) => t._id === tarif.type);
                    const hourCost = tableInfos.teacher?.statut === 4 ? tarif.contratTarif : tarif.prestationTarif;
                    tableInfos.totalTarif += hourCost * (task.duration / 60);
                    if (taskType && !array.some(tt => tt._id === taskType._id)) {
                        array.push(taskType);
                    }
                }
            }
            return array;
        }, []);
        if (!this.startDate || !this.endDate)
            return new Error("Date des tâches invalide.");
        return tableInfos;
    }, [
        this.getRepositories().cdc,
        this.getRepositories().contrat,
        this.getRepositories().faculty,
        this.getRepositories().mainSkill,
        this.getRepositories().promotion,
        this.getRepositories().site,
        this.getRepositories().skill,
        this.getRepositories().tarif,
        this.getRepositories().task,
        this.getRepositories().taskType,
        this.getRepositories().teacher,
    ], "getTableInfos");
    getDocInfos = (tableInfos) => this.memo(() => {
        if (!this.getRepositories().cdc.loaded ||
            !this.getRepositories().contrat.loaded ||
            !this.getRepositories().faculty.loaded ||
            !this.getRepositories().mainSkill.loaded ||
            !this.getRepositories().promotion.loaded ||
            !this.getRepositories().site.loaded ||
            !this.getRepositories().skill.loaded ||
            !this.getRepositories().tarif.loaded ||
            !this.getRepositories().task.loaded ||
            !this.getRepositories().taskType.loaded ||
            !this.getRepositories().teacher.loaded) {
            return new Error("Chargement");
        }
        if (!tableInfos.faculty)
            return new Error("Filière introuvable.");
        if (!tableInfos.site)
            return new Error("Site introuvable");
        if (!tableInfos.teacher)
            return new Error("Professeur introuvable.");
        const teacherInfos = tableInfos.teacher.getInfos();
        const exampleTask = tableInfos.tasksArray.find((t) => t._id === this.tasks[0]);
        if (!exampleTask)
            return new Error("Cours introuvables.");
        const tasks = tableInfos.tasksArray
            .map((task) => {
            const promotionString = task.promotions && task.promotions.length > 0
                ? this.getRepositories().promotion
                    .filter(promotion => task.promotions.some(promoSkill => promoSkill.promotion === promotion._id))
                    .map((p, i, a) => p.name.split(" ")[0] + (i === a.length - 1 ? "" : ", "))
                : "Ø";
            let hourString = "";
            if (!task.deadline) {
                const taskEnd = new Date(task.date);
                taskEnd.setMinutes(taskEnd.getMinutes() + task.duration);
                hourString =
                    String(task.date.getHours()).padStart(2, "0") +
                        "h" +
                        String(task.date.getMinutes()).padStart(2, "0") +
                        (!task.deadline
                            ? " - " +
                                String(taskEnd.getHours()).padStart(2, "0") +
                                "h" +
                                String(taskEnd.getMinutes()).padStart(2, "0")
                            : "");
            }
            let typeSigle = "";
            const tarif = this.getRepositories().tarif.find((t) => t._id === task.tarif);
            if (tarif) {
                const type = this.getRepositories().taskType.find((t) => t._id === tarif.type);
                if (type) {
                    typeSigle = type.sigle;
                }
            }
            return {
                ...task,
                promotionString,
                hourString,
                description: task.description ? task.description : "",
                typeSigle,
                totalMinutes: minutesToHourString(task.duration),
            };
        })
            .filter((t) => t);
        const skills = tasks
            .reduce((array, task) => {
            task.promotions.forEach(promoSkill => {
                if (promoSkill.skill && !array.includes(promoSkill.skill)) {
                    array.push(promoSkill.skill);
                }
            });
            return array;
        }, [])
            .map((skill) => this.getRepositories().skill.find((sk) => sk._id === skill))
            .filter((skill) => skill !== undefined)
            .map((skill) => ({
            ...skill,
            sigle: skill.getCode()
        }));
        const mainSkills = this.getRepositories().mainSkill
            .filter(mainSkill => skills.some(skill => skill.mainSkill === mainSkill._id))
            .map(mainSkill => {
            const children = skills.filter(skill => skill.mainSkill === mainSkill._id);
            return {
                ...mainSkill,
                children
            };
        });
        const contrat = this.getRepositories().contrat.find((c) => c.cdcs.includes(this._id));
        let startDate;
        let endDate;
        tasks.forEach((task) => {
            if (!startDate || task.date.getTime() < startDate.getTime()) {
                startDate = new Date(task.date);
            }
            if (!endDate || task.date.getTime() > endDate.getTime()) {
                endDate = new Date(task.date);
            }
        });
        if (!startDate || !endDate)
            return new Error("Date des tâches invalide.");
        const startDateString = startDate.toLocaleDateString("fr", {
            day: "numeric",
            month: "long",
            year: "numeric",
        });
        let totalMinutes = 0;
        let totalTarif = 0;
        const taskTypes = tasks.reduce((array, task) => {
            if (task.duration) {
                totalMinutes += task.duration;
                const tarif = this.getRepositories().tarif.find((t) => t._id === task.tarif);
                if (tarif) {
                    const taskType = this.getRepositories().taskType.find((t) => t._id === tarif.type);
                    const hourCost = tableInfos.teacher.statut === 4 ? tarif.contratTarif : tarif.prestationTarif;
                    totalTarif += hourCost * (task.duration / 60);
                    if (taskType) {
                        const typeItem = array.find((t) => t._id === taskType._id);
                        if (typeItem) {
                            typeItem.totalMinutes += task.duration;
                        }
                        else {
                            array.push({ ...taskType, totalMinutes: task.duration });
                        }
                    }
                }
            }
            return array;
        }, []);
        const totalMinutesString = minutesToHourString(totalMinutes);
        const title = `${this.validated ? "Cahier des charges" : "Proposition d'intervention"} de ${teacherInfos.teacherString} du ${shortDate(startDate)}${startDate.getTime() < endDate.getTime()
            ? ` au ${shortDate(endDate)}`
            : ""}`;
        return {
            ...tableInfos,
            title,
            facultyString: tableInfos.faculty.getFacultyString(),
            siteString: tableInfos.site.toString(),
            contrat,
            ...teacherInfos,
            tasks,
            skills,
            mainSkills,
            taskTypes,
            startDateString,
            totalMinutesString,
            author: this.getRepositories().user.findById(this.author)
        };
    }, [
        this.getRepositories().cdc,
        this.getRepositories().contrat,
        this.getRepositories().faculty,
        this.getRepositories().mainSkill,
        this.getRepositories().promotion,
        this.getRepositories().site,
        this.getRepositories().skill,
        this.getRepositories().tarif,
        this.getRepositories().task,
        this.getRepositories().taskType,
        this.getRepositories().teacher,
    ], "getDocInfos");
}
exports.CDC = CDC;
class CDCs extends astra_1.Repository {
    constructor(docs) {
        super(repositoryName, CDCs, CDC, docs);
    }
}
exports.CDCs = CDCs;
exports.default = new CDCs();
