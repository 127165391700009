"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MiniWeek = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const MiniDay_1 = require("../MiniDay/MiniDay");
require("./MiniWeek.scss");
const MiniWeek = (props) => {
    const activeWeek = props.week.includes(props.date);
    const miniDays = props.week.days.map((day, index) => {
        return ((0, jsx_runtime_1.jsx)(MiniDay_1.MiniDay, { day: day, date: props.date, setDate: props.setDate, mode: props.mode, activeWeek: activeWeek, start: props.start, end: props.end, monthNavDate: props.monthNavDate, gotTask: props.gotTask }, index));
    });
    return (0, jsx_runtime_1.jsx)("div", { className: "MiniWeek", children: miniDays });
};
exports.MiniWeek = MiniWeek;
