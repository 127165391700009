"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTeachersCDCs = void 0;
function getTeachersCDCs(user, repositories) {
    let teachersCDCs = repositories.teacher.map(teacher => {
        const teacherCDCs = repositories.cdc.filter(cdc => cdc.teacher === teacher._id);
        const teacherTasks = repositories.task.filter((task) => task.teachers.includes(teacher._id)
            && (!task.cancelledTeachers
                || !task.cancelledTeachers.includes(teacher._id))
            && !task.cancelled);
        const unsentTeacherTasks = teacherTasks.filter(task => {
            const taskPromos = repositories.promotion.filter(p => task.promotions.some(ps => ps.promotion === p._id));
            const userTask = repositories.mainSkill.some(ms => user &&
                taskPromos.some(p => p.faculty === ms.faculty) &&
                ms.referents.includes(user?._id));
            return task.tarif &&
                task.promotions.length > 0 &&
                !teacherCDCs.some(cdc => cdc.tasks.includes(task._id)) &&
                !!userTask;
        });
        const teacherFaculties = unsentTeacherTasks.reduce((teacherFaculs, task) => {
            const taskPromotions = task.promotions
                .map(promoSkill => repositories.promotion.findById(promoSkill.promotion))
                .filter(p => p);
            taskPromotions.forEach((taskPromo) => {
                const promoFaculty = repositories.faculty.findById(taskPromo.faculty);
                if (promoFaculty) {
                    const teacherFacultyIndex = teacherFaculs.findIndex(facul => facul.faculty === promoFaculty);
                    if (teacherFacultyIndex === -1) {
                        teacherFaculs.push({ faculty: promoFaculty, tasks: [task] });
                    }
                    else if (!teacherFaculs[teacherFacultyIndex].tasks.some(t => t._id === task._id)) {
                        teacherFaculs[teacherFacultyIndex].tasks.push(task);
                    }
                }
            });
            return teacherFaculs;
        }, [])
            .filter(f => f.tasks.length > 0);
        return {
            teacher,
            faculties: teacherFaculties.filter(faculty => faculty.tasks.length > 0)
        };
    })
        .filter(t => t.faculties.length > 0);
    return teachersCDCs.filter(cdc => cdc.faculties.length > 0);
}
exports.getTeachersCDCs = getTeachersCDCs;
