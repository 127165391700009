"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const astra_1 = require("astra");
const CDCsPage_1 = require("./pages/CDCsPage/CDCsPage");
const ContratsPage_1 = require("./pages/ContratsPage/ContratsPage");
require("./style.scss");
const Main = (view) => {
    (0, react_1.useEffect)(() => {
        if (view.router.params.contrat) {
            view.update({ tabPage: 1 });
        }
    }, [view.router.params.contrat]);
    return ((0, jsx_runtime_1.jsx)("div", { className: "Documents", children: (0, jsx_runtime_1.jsx)(astra_1.TabsPages, { active: view.store.tabPage, children: [
                (0, jsx_runtime_1.jsx)(CDCsPage_1.CDCsPage, { ...view }),
                (0, jsx_runtime_1.jsx)(ContratsPage_1.ContratsPage, { ...view })
            ] }) }));
};
exports.default = Main;
