"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.remove = void 0;
function remove(repo, ids) {
    const newRepoArray = [];
    const newRepoArchives = [];
    const newRepoRemovedDocuments = [];
    repo.forEach(doc => {
        if (ids.includes(doc._id)) {
            doc.removed = true;
            newRepoRemovedDocuments.push(doc);
        }
        else {
            newRepoArray.push(doc);
        }
    });
    repo.archives.forEach(doc => {
        if (ids.includes(doc._id)) {
            doc.removed = true;
            newRepoRemovedDocuments.push(doc);
        }
        else {
            newRepoArchives.push(doc);
        }
    });
    const newRepoInstance = repo.set(newRepoArray);
    newRepoInstance.removed.push(...newRepoRemovedDocuments);
    newRepoInstance.archives = [...newRepoArchives];
    return newRepoInstance;
}
exports.remove = remove;
