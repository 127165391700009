"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Profile = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const astra_1 = require("astra");
require("./Profile.scss");
function Profile(props) {
    const user = props.session.user;
    if (user) {
        return ((0, jsx_runtime_1.jsxs)("div", { className: "Profile", onClick: () => props.modal((resolve, reject) => (0, jsx_runtime_1.jsx)(ProfileModal, { resolve: resolve, reject: reject, session: props.session }))
                .then(() => { })
                .catch(() => { }), children: [(0, jsx_runtime_1.jsx)("div", { className: "picture", children: (0, jsx_runtime_1.jsxs)("span", { children: [user.firstName ? user.firstName[0]?.toUpperCase() : "", user.lastName ? user.lastName[0]?.toUpperCase() : ""] }) }), (0, jsx_runtime_1.jsxs)("p", { className: "name", children: [user.firstName, " ", user?.lastName.toUpperCase()] }), user.roles.length > 0 && ((0, jsx_runtime_1.jsx)("p", { className: "roles", children: user.roles.map(role => ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)("span", { children: props.repositories.role.findById(role)?.name }), (0, jsx_runtime_1.jsx)("br", {})] }))) }))] }));
    }
    else {
        return ((0, jsx_runtime_1.jsx)("div", {}));
    }
}
exports.Profile = Profile;
function ProfileModal(props) {
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: (0, jsx_runtime_1.jsx)(astra_1.Button, { name: "Me d\u00E9connecter", onClick: () => {
                props.session.logout();
                props.reject();
            } }) }));
}
