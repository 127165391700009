"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.taskOverlap = exports.overlap = void 0;
const Task_model_1 = require("../models/Task.model");
const commaOrAnd_1 = require("./commaOrAnd");
function overlap(startA, endA, startB, endB) {
    return ((startB <= startA && startA < endB) ||
        (startB < endA && endA <= endB) ||
        (startA <= startB && startB < endA) ||
        (startA < endB && endB <= endA));
}
exports.overlap = overlap;
const getEndDate = (task) => {
    const taskEndDate = new Date(task.date);
    taskEndDate.setMinutes(taskEndDate.getMinutes() + task.duration);
    return taskEndDate;
};
function taskOverlap(repositories, notify, task, patch) {
    const patchedTask = new Task_model_1.Task({ ...task, ...patch });
    if ("deadline" in patch ||
        "date" in patch ||
        "duration" in patch ||
        "cancelled" in patch ||
        "cancelledTeachers" in patch ||
        ("teachers" in patch && patch.teachers.length > 0)) {
        const sameTeachersTask = repositories.task.find(t => {
            if (t._id !== patchedTask._id &&
                !patchedTask.deadline && !t.deadline &&
                !patchedTask.cancelled && !t.cancelled &&
                patchedTask.teachers.some(teacherIdA => !patchedTask.cancelledTeachers.includes(teacherIdA)
                    && t.teachers.some(teacherIdB => !t.cancelledTeachers.includes(teacherIdB)
                        && teacherIdA === teacherIdB))) {
                const patchedTaskStartDate = new Date(patchedTask.date);
                const patchedTaskEndDate = getEndDate(patchedTask);
                const otherTaskStartDate = new Date(t.date);
                const otherTaskEndDate = getEndDate(t);
                return overlap(patchedTaskStartDate, patchedTaskEndDate, otherTaskStartDate, otherTaskEndDate);
            }
            else {
                return false;
            }
        });
        if (sameTeachersTask) {
            const sameTeachersId = patchedTask.teachers.filter(teacherId => sameTeachersTask.teachers.includes(teacherId));
            const sameTeachers = repositories.teacher.filter(teacher => sameTeachersId.includes(teacher._id));
            const sameTeachersString = sameTeachers.reduce((string, teacher, i, a) => {
                return `${string}${(teacher.prenom?.charAt(0) || "") + (teacher.prenom?.slice(1, teacher.nom.length - 1) || "")}${teacher.nom.toUpperCase()}${(0, commaOrAnd_1.commaOrAnd)(i, a.length)}`;
            }, "");
            const sameTeachersTaskAuthor = repositories.user.find(u => u._id === sameTeachersTask.author);
            const sameTeachersTaskEndDate = getEndDate(sameTeachersTask);
            notify(`${sameTeachersString} ${sameTeachers.length > 1 ? "sont" : "est"} déjà demandé${sameTeachers.length > 1 ? "s" : ""}${sameTeachersTask ? ` de ${String(sameTeachersTask.date.getHours()).padStart(2, "0")}h${String(sameTeachersTask.date.getMinutes()).padStart(2, "0")} à ${String(sameTeachersTaskEndDate.getHours()).padStart(2, "0")}h${String(sameTeachersTaskEndDate.getMinutes()).padStart(2, "0")}${sameTeachersTaskAuthor ? " " : ""}` : ""}${sameTeachersTaskAuthor ? `par ${sameTeachersTaskAuthor.firstName.charAt(0) + sameTeachersTaskAuthor.firstName.slice(1, sameTeachersTaskAuthor.firstName.length - 1)} ${sameTeachersTaskAuthor.lastName.toUpperCase()}` : ""}.`, "red");
            return true;
        }
        return false;
    }
}
exports.taskOverlap = taskOverlap;
