"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CDCsPage = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const free_solid_svg_icons_1 = require("@fortawesome/free-solid-svg-icons");
const react_1 = require("react");
const react_2 = require("react");
const astra_1 = require("astra");
const cdcsTableHead_1 = require("../../../../../../../utils/cdcsTableHead");
const commaOrAnd = (i, length) => i < length - 2 ? ", " : i === length - 2 ? " et " : "";
function CDCsPage(props) {
    const [selected, setSelected] = (0, react_1.useState)(props.teacherSites?.cdcs.map(cdc => cdc._id) || []);
    (0, react_2.useEffect)(() => {
        if (props.teacherSites && props.teacherSites.cdcs.length > 0) {
            setSelected(props.teacherSites?.cdcs.map(task => task._id));
        }
    }, [props.teacherSites?.cdcs.length]);
    if (props.teacherSites) {
        const array = props.teacherSites.cdcs
            .map(cdc => cdc.getTableInfos())
            .filter(cdcI => !(cdcI instanceof Error));
        return ((0, jsx_runtime_1.jsxs)("div", { className: "TeachersPages", children: [(0, jsx_runtime_1.jsx)(astra_1.Button, { icon: free_solid_svg_icons_1.faArrowLeft, onClick: props.back }), (0, jsx_runtime_1.jsx)("h3", { children: "Cahier des charges" }), (0, jsx_runtime_1.jsx)(astra_1.Table, { array: array.map(cdcI => ({ ...cdcI, selected: selected.includes(cdcI.CDC._id) })), head: (0, cdcsTableHead_1.cdcsTableHead)(true), onClick: (cdcInfo) => array &&
                        setSelected(array
                            .map((cdcI) => cdcI.CDC._id)
                            .filter(cdcId => (cdcId !== cdcInfo.CDC._id && selected.includes(cdcId))
                            || (cdcId === cdcInfo.CDC._id && !selected.includes(cdcId)))), flex: true }), (0, jsx_runtime_1.jsx)(astra_1.Button, { name: "Cr\u00E9er le contrat de travail", className: "addButton", onClick: () => props.onPick(selected.map(cdcId => props.teacherSites?.cdcs?.find(cdc => cdc._id === cdcId))
                        .filter(c => c)), disabled: selected.length === 0 })] }));
    }
    else {
        return (0, jsx_runtime_1.jsx)("div", {});
    }
}
exports.CDCsPage = CDCsPage;
