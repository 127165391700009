"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Cell = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_fontawesome_1 = require("@fortawesome/react-fontawesome");
const free_solid_svg_icons_1 = require("@fortawesome/free-solid-svg-icons");
const free_regular_svg_icons_1 = require("@fortawesome/free-regular-svg-icons");
const Button_1 = require("../../Button/Button");
function getContent(row, col, rowIndex, remove, onSelect, disabled, selected) {
    if (col.type) {
        switch (col.type.toLowerCase()) {
            case "color": {
                return (0, jsx_runtime_1.jsx)("div", { style: { backgroundColor: row[col.key], width: "100%", height: "100%" } });
            }
            case "number":
            case "string":
            case "text": {
                return row[col.key];
            }
            case "boolean": {
                return row[col.key] ? (0, jsx_runtime_1.jsx)(react_fontawesome_1.FontAwesomeIcon, { icon: free_solid_svg_icons_1.faCheck, className: "true" }) : "";
            }
            case "longdate": {
                if (row[col.key]) {
                    if (row[col.key].getTime) {
                        return row[col.key].toLocaleDateString("fr", {
                            weekday: "long",
                            day: "numeric",
                            month: "long",
                            year: "numeric",
                        });
                    }
                    else {
                        return "Date invalide";
                    }
                }
                else {
                    return "";
                }
            }
            case "date": {
                if (row[col.key]) {
                    if (row[col.key].getTime) {
                        return row[col.key].toLocaleDateString("fr", {
                            weekday: "short",
                            day: "numeric",
                            month: "short",
                            year: "numeric",
                        });
                    }
                    else {
                        return "Date invalide";
                    }
                }
                else {
                    return "";
                }
            }
            case "shortdate": {
                if (row[col.key]) {
                    if (row[col.key].getTime) {
                        return row[col.key].toLocaleDateString("fr", {
                            day: "2-digit",
                            month: "2-digit",
                            year: "2-digit",
                        });
                    }
                    else {
                        return "Date invalide";
                    }
                }
                else {
                    return "";
                }
            }
            case "table": {
                if (!Array.isArray(row[col.key]) && col.table && col.table[row[col.key]]) {
                    return col.table[row[col.key]];
                }
                else if (Array.isArray(row[col.key]) && col.table) {
                    return row[col.key].reduce((string, next, i) => {
                        return `${string}${col.table?.[next]}${i < row[col.key].length - 1 ? ", " : ""}`;
                    }, "");
                }
                else {
                    return row[col.key];
                }
            }
            case "function": {
                if (col.function && typeof col.function === "function") {
                    return col.function(row, rowIndex);
                }
                else {
                    return String(row[col.key]);
                }
            }
            case "feature": {
                if (col.key === "remove") {
                    return ((0, jsx_runtime_1.jsx)(Button_1.Button, { disabled: disabled, icon: free_solid_svg_icons_1.faTrashAlt, className: "removeRowButton", onClick: (event) => {
                            event.stopPropagation();
                            if (remove) {
                                remove(row, rowIndex);
                            }
                        }, filled: true }));
                }
                if (col.key === "rowSelected") {
                    return ((0, jsx_runtime_1.jsx)(Button_1.Button, { disabled: disabled, icon: selected ? free_solid_svg_icons_1.faCheckSquare : free_regular_svg_icons_1.faSquare, className: `selectRowButton ${selected ? "selected" : ""}`, onClick: (event) => {
                            event.stopPropagation();
                            if (onSelect) {
                                onSelect(row._id);
                            }
                        }, filled: true }));
                }
                break;
            }
            default: {
                return String(row[col.key]);
            }
        }
    }
    else {
        return String(row[col.key]);
    }
}
function Cell(props) {
    const Tag = props.col.fixed ? "th" : "td";
    let style = {};
    if (props.col.fixed) {
        style.left = String(props.left);
    }
    if (props.order) {
        style.order = props.order;
    }
    if (props.col.size) {
        style.minWidth = String(props.col.size) + "rem";
        style.maxWidth = String(props.col.size) + "rem";
    }
    else {
        style.flex = 1;
        style.minWidth = "5rem";
    }
    if (props.style) {
        style = { ...style, ...props.style };
    }
    const content = getContent(props.row, props.col, props.rowIndex, props.remove, props.onSelect, props.disabled, props.selected);
    return ((0, jsx_runtime_1.jsxs)(Tag, { className: `${Tag}${props.col.fixed ? " fixed" : " free"} ${props.col.key}${props.first ? " first" : ""}${props.lastFixed ? " lastFixed" : ""}`, style: style, children: [props.col.prefix, content] }, props.colIndex));
}
exports.Cell = Cell;
;
