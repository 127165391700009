"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function nameCH(value) {
    if (value.length) {
        let string = value;
        string = string.replace(/^[ '-]+|[^A-Za-zÀ-ÖØ-öø-ÿ '-]+/, "");
        string = string.replace(/(^[A-Za-zÀ-ÖØ-öø-ÿ]{0,1})([A-Za-zÀ-ÖØ-öø-ÿ]*)/, (_match, $1, $2) => {
            return $1.toUpperCase() + $2.toLowerCase();
        });
        string = string.replace(/([ '-]+)([A-Za-zÀ-ÖØ-öø-ÿ]{0,1})([A-Za-zÀ-ÖØ-öø-ÿ]*)/g, (_match, $1, $2, $3) => {
            return $1[0] + $2.toUpperCase() + $3.toLowerCase();
        });
        return string;
    }
    else {
        return "";
    }
}
exports.default = nameCH;
