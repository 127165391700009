"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TaskContainer = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_fontawesome_1 = require("@fortawesome/react-fontawesome");
const free_solid_svg_icons_1 = require("@fortawesome/free-solid-svg-icons");
const react_transition_group_1 = require("react-transition-group");
require("./TaskContainer.scss");
const getPadding = (percent, padding) => `calc(${percent}% + ${padding}px)`;
const height = {
    l: 15,
    m: 7,
    s: 3
};
const width = {
    l: 11,
    m: 7,
    s: 4,
};
let teacherIndex = 0;
const coef = 1.8;
function TaskContainer(props) {
    const [heightClass, widthClass] = (0, react_1.useMemo)(() => {
        return [
            props.height >= height.l ? "hl"
                : props.height < height.l && props.height > height.m ? "hm"
                    : props.height < height.m && props.height > height.s ? "hs"
                        : "hss",
            props.width >= width.l ? "wl"
                : props.width < width.l && props.width > width.m ? "wm"
                    : props.width < width.m && props.width > width.s ? "ws"
                        : "wss"
        ];
    }, [props.height, props.width]);
    const type = props.task.getType();
    const [teachersStates, teacherStateCount] = props.task.getTeachersStates();
    const [autresTeacher, autresAccord, displayedTeacherLines] = (0, react_1.useMemo)(() => {
        let at = Math.ceil((height.m + (teacherStateCount - 1) * coef - props.height) / coef);
        at = at < 0 ? 0 : at;
        const aa = at > 1 ? "s" : "";
        const dtl = teacherStateCount - at + (at > 0 ? 1 : 0);
        return [at, aa, dtl];
    }, [props.height, props.width, teacherStateCount]);
    const locked = props.task.cancelled || Boolean(props.task.cdcs && props.task.cdcs.length > 0);
    const mouseDownTop = (event) => {
        if (event.button !== 0)
            return;
        props.dispatch("mousedown_top", { taskId: props.task._id, event });
    };
    const mouseDownMid = (event) => {
        if (event.button !== 0)
            return;
        props.dispatch("mousedown_mid", { taskId: props.task._id, event });
    };
    const mouseDownBot = (event) => {
        if (event.button !== 0)
            return;
        props.dispatch("mousedown_bot", { taskId: props.task._id, event });
    };
    const mouseUp = (event) => {
        if (event.button !== 0)
            return;
        event.stopPropagation();
        props.dispatch("mouseup_task", { taskId: props.task._id });
    };
    return ((0, jsx_runtime_1.jsxs)("div", { draggable: false, className: `TaskContainer ${props.task.cancelled ? "cancelled" : ""} ${widthClass} ${heightClass} ${props.ghost ? "ghost" : ""} ${props.dragged && !props.ghost ? "dragged" : ""} ${props.lastEdited ? "lastEdited" : ""}`, style: {
            left: props.dragged ? getPadding(props.left, -props.config.highlightPadding) : props.left + "%",
            top: props.dragged ? getPadding(props.top, -props.config.highlightPadding) : props.top + "%",
            width: props.dragged ? getPadding(props.width, props.config.highlightPadding * 2) : props.width + "%",
            height: props.dragged ? getPadding(props.height, props.config.highlightPadding * 2) : props.height + "%",
        }, children: [(0, jsx_runtime_1.jsx)("div", { className: `Task ${props.task.cancelled ? "cancelled" : ""}`, children: (0, jsx_runtime_1.jsxs)("div", { className: "TaskContent", style: type?.color ? { backgroundColor: type.color } : {}, children: [(props.task.cancelled || teacherStateCount > 0 || type?.sigle || props.task.title.length > 0)
                            && ((0, jsx_runtime_1.jsxs)("div", { className: "TaskTopRow", children: [props.width >= width.s && (type?.sigle || props.task.title.length > 0) && ((0, jsx_runtime_1.jsxs)("p", { className: "title", children: [props.width >= width.s && type && ((0, jsx_runtime_1.jsx)("span", { className: "sigle", children: type.sigle })), props.width >= width.s && type && ((0, jsx_runtime_1.jsx)("span", { className: "hyphen", children: " - " })), props.width >= width.s && ((0, jsx_runtime_1.jsx)("span", { className: "titre", children: props.task.title || "(sans titre)" }))] })), props.task.cancelled ? ((0, jsx_runtime_1.jsx)("div", { className: "stateIcons", children: (0, jsx_runtime_1.jsx)("div", { className: "stateIcon", style: type?.color ? { backgroundColor: type.color } : {}, children: (0, jsx_runtime_1.jsx)(react_fontawesome_1.FontAwesomeIcon, { icon: free_solid_svg_icons_1.faBan }) }) })) :
                                        teacherStateCount > 0
                                            && (props.width < width.m
                                                || props.height < height.m + (teacherStateCount - 1) * coef) && ((0, jsx_runtime_1.jsxs)("div", { className: "stateIcons", children: [(0, jsx_runtime_1.jsx)(State, { states: teachersStates.pending, icon: free_solid_svg_icons_1.faHourglass }), (0, jsx_runtime_1.jsx)(State, { states: teachersStates.cdcSent, icon: free_solid_svg_icons_1.faPaperPlane }), (0, jsx_runtime_1.jsx)(State, { states: teachersStates.cdcValidated, icon: free_solid_svg_icons_1.faCheck }), (0, jsx_runtime_1.jsx)(State, { states: teachersStates.cdcSigned, icon: free_solid_svg_icons_1.faPenAlt }), (0, jsx_runtime_1.jsx)(State, { states: teachersStates.contratSent, icon: free_solid_svg_icons_1.faFileContract }), (0, jsx_runtime_1.jsx)(State, { states: teachersStates.contratSigned, icon: free_solid_svg_icons_1.faPenNib }), (0, jsx_runtime_1.jsx)(State, { states: teachersStates.cancelled, icon: free_solid_svg_icons_1.faBan })] }))] })), props.task.promotions.length > 0
                            && props.height >= height.s
                            && props.width >= width.s
                            && ((0, jsx_runtime_1.jsx)("p", { className: "promotions", children: props.task.getPromotionsString() })), teacherStateCount > 0
                            && props.width >= width.m
                            && props.height >= height.m
                            && ((0, jsx_runtime_1.jsxs)("div", { className: `teachers hideable ${teacherStateCount > 0
                                    && props.width >= width.m
                                    && props.height >= height.m
                                    ? "shown" : "hidden"}`, style: {
                                    flexBasis: `calc(${displayedTeacherLines}em + ${displayedTeacherLines * 2}px)`
                                }, children: [TeacherState(teachersStates.pending, free_solid_svg_icons_1.faHourglass, props.height), TeacherState(teachersStates.cdcSent, free_solid_svg_icons_1.faPaperPlane, props.height), TeacherState(teachersStates.cdcValidated, free_solid_svg_icons_1.faCheck, props.height), TeacherState(teachersStates.cdcSigned, free_solid_svg_icons_1.faPenAlt, props.height), TeacherState(teachersStates.contratSent, free_solid_svg_icons_1.faFileContract, props.height), TeacherState(teachersStates.contratSigned, free_solid_svg_icons_1.faPenNib, props.height), TeacherState(teachersStates.cancelled, free_solid_svg_icons_1.faBan, props.height), props.height < height.m + (teacherStateCount - 1) * coef && props.height >= height.m && ((0, jsx_runtime_1.jsx)("p", { className: "teacherState", children: props.height < height.m ? ((0, jsx_runtime_1.jsxs)("span", { children: [autresTeacher, " formateur", autresAccord] })) : ((0, jsx_runtime_1.jsxs)("span", { children: ["...et ", autresTeacher, " autre", autresAccord, " formateur", autresAccord] })) }))] })), props.task.description.length > 0
                            && props.height >= height.m + (teacherStateCount) * coef
                            && props.width >= width.m && ((0, jsx_runtime_1.jsx)("p", { className: "description", children: props.task.description }))] }) }), (0, jsx_runtime_1.jsxs)("div", { className: "eventListeners", draggable: false, onMouseUp: mouseUp, children: [!props.task.deadline && !locked
                        && (0, jsx_runtime_1.jsx)("div", { className: "eventListener top", onMouseDown: mouseDownTop, draggable: false }), (0, jsx_runtime_1.jsx)("div", { className: `eventListener mid${locked ? " locked" : ""}`, onMouseDown: mouseDownMid, draggable: false }), !props.task.deadline && !locked
                        && (0, jsx_runtime_1.jsx)("div", { className: "eventListener bot", onMouseDown: mouseDownBot, draggable: false })] })] }));
}
exports.TaskContainer = TaskContainer;
const TeacherState = (states, icon, taskHeight) => {
    if (states.length > 0) {
        return states
            .filter(state => taskHeight >= height.m + state.index * coef)
            .map(state => ((0, jsx_runtime_1.jsx)(react_transition_group_1.CSSTransition, { timeout: 200, classNames: "taskTextTransition", children: (0, jsx_runtime_1.jsxs)("div", { className: "teacherState", children: [(0, jsx_runtime_1.jsxs)("p", { children: [state.teacher.prenom, " ", state.teacher.nom] }), (0, jsx_runtime_1.jsx)(react_fontawesome_1.FontAwesomeIcon, { icon: icon })] }) }, state.teacher._id)));
    }
    else
        return (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, {});
};
const State = (props) => {
    if (props.states.length > 0) {
        return ((0, jsx_runtime_1.jsxs)("div", { className: "stateIcon", children: [props.states.length, (0, jsx_runtime_1.jsx)(react_fontawesome_1.FontAwesomeIcon, { icon: props.icon })] }));
    }
    else
        return (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, {});
};
