"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function default_1(object) {
    if (typeof object === "object") {
        return { ...object, token: localStorage.getItem("token") };
    }
    else {
        return { token: localStorage.getItem("token") };
    }
}
exports.default = default_1;
