"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.cdcsTableHead = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_fontawesome_1 = require("@fortawesome/react-fontawesome");
const free_solid_svg_icons_1 = require("@fortawesome/free-solid-svg-icons");
const commaOrAnd_1 = require("./commaOrAnd");
const minutesToHourString_1 = require("./minutesToHourString");
const cdcsTableHead = (selection) => {
    let head = [
        {
            label: "État",
            key: "validated",
            type: "function",
            size: 5,
            sortable: true,
            function: (cdcInfos) => {
                return ((0, jsx_runtime_1.jsx)(react_fontawesome_1.FontAwesomeIcon, { icon: cdcInfos.contrat?.signatureDate
                        ? free_solid_svg_icons_1.faPenNib
                        : cdcInfos.contrat
                            ? free_solid_svg_icons_1.faFileContract
                            : cdcInfos.CDC.signatureDate
                                ? free_solid_svg_icons_1.faPenAlt
                                : cdcInfos.CDC.validated
                                    ? free_solid_svg_icons_1.faCheck
                                    : free_solid_svg_icons_1.faPaperPlane, className: `CDCState ${cdcInfos.contrat?.signatureDate
                        ? "signed"
                        : cdcInfos.contrat
                            ? "contrat"
                            : cdcInfos.CDC.signatureDate
                                ? "processed"
                                : cdcInfos.CDC.validated
                                    ? "validated"
                                    : ""}` }));
            },
            sortingFilter: (cdcInfos) => {
                const sortingValue = cdcInfos.contrat?.signatureDate
                    ? 5
                    : cdcInfos.contrat
                        ? 4
                        : cdcInfos.CDC.signatureDate
                            ? 3
                            : cdcInfos.CDC.validated
                                ? 2
                                : 1;
                return sortingValue;
            }
        },
        {
            label: "Site",
            key: "siteString",
            type: "function",
            size: 7,
            sortable: true,
        },
        {
            label: "Filière",
            key: "facultyString",
            type: "string",
            size: 20,
            sortable: true,
        },
        {
            label: "Formateur",
            key: "shortTeacherString",
            type: "string",
            size: 15,
            sortable: true,
        },
        {
            label: "Statut",
            key: "statut",
            type: "string",
            size: 10,
            sortable: true,
        },
        {
            label: "Nombre de tâches",
            key: "tasksArray",
            type: "function",
            function: (tableInfos) => tableInfos.tasksArray.length,
            sortingFilter: (tableInfos) => tableInfos.tasksArray.length,
            size: 10,
            sortable: true,
        },
        {
            label: "Temps de travail",
            key: "totalMinutes",
            type: "function",
            size: 10,
            function: (cdcInfos) => (0, minutesToHourString_1.minutesToHourString)(cdcInfos.totalMinutes),
            sortable: true,
        },
        {
            label: "Coût",
            key: "totalTarif",
            type: "function",
            size: 10,
            function: (cdcInfos) => `${cdcInfos.totalTarif.toFixed(2)}€`,
            sortable: true,
        },
        {
            label: "Dates",
            key: "startDate",
            type: "function",
            sortable: true,
            size: 15,
            function: (cdcInfos) => cdcInfos.CDC.startDate && cdcInfos.CDC.endDate ?
                cdcInfos.CDC.startDate.getDate() === cdcInfos.CDC.endDate.getDate()
                    && cdcInfos.CDC.startDate.getDate() === cdcInfos.CDC.endDate.getDate()
                    && cdcInfos.CDC.startDate.getDate() === cdcInfos.CDC.endDate.getDate()
                    ? `${String(cdcInfos.CDC.startDate.getDate()).padStart(2, "0")}/${String(cdcInfos.CDC.startDate.getMonth() + 1).padStart(2, "0")}/${cdcInfos.CDC.startDate.getFullYear()}`
                    : `${String(cdcInfos.CDC.startDate.getDate()).padStart(2, "0")}/${String(cdcInfos.CDC.startDate.getMonth() + 1).padStart(2, "0")}/${cdcInfos.CDC.startDate.getFullYear()} -
                            ${String(cdcInfos.CDC.endDate.getDate()).padStart(2, "0")}/${String(cdcInfos.CDC.endDate.getMonth() + 1).padStart(2, "0")}/${cdcInfos.CDC.endDate.getFullYear()}`
                : "erreur",
            sortingFilter: cdcInfos => cdcInfos.CDC.startDate?.getTime()
        },
        {
            label: "Types",
            key: "types",
            type: "function",
            size: 10,
            function: (cdcInfos) => cdcInfos.taskTypesArray.reduce((string, tt, i, a) => `${string}${tt.sigle}${(0, commaOrAnd_1.commaOrAnd)(i, a.length)}`, ""),
        },
        {
            label: "Date de création",
            key: "created_at",
            type: "function",
            size: 10,
            function: (cdcInfos) => `${String(cdcInfos.CDC.created_at.getDate()).padStart(2, "0")}/${String(cdcInfos.CDC.created_at.getMonth() + 1).padStart(2, "0")}/${cdcInfos.CDC.created_at.getFullYear()}`,
        },
        {
            label: "Date de dernière modification",
            key: "updated_at",
            type: "function",
            size: 10,
            function: (cdcInfos) => `${String(cdcInfos.CDC.updated_at.getDate()).padStart(2, "0")}/${String(cdcInfos.CDC.updated_at.getMonth() + 1).padStart(2, "0")}/${cdcInfos.CDC.updated_at.getFullYear()}`,
        },
    ];
    if (selection) {
        head = [
            {
                label: "",
                key: "selected",
                type: "boolean",
                size: 4
            },
            ...head
        ];
    }
    return head;
};
exports.cdcsTableHead = cdcsTableHead;
