"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function nameCH(value) {
    if (value.length) {
        let string = value;
        string = string.replace(/[^0-9]/g, "");
        string = string.replace(/^[^12]/g, "");
        string = string.replace(/^([0-9]{1}) ?([0-9]{2})? ?([0-9]{2})? ?([0-9]{2})? ?([0-9]{3})? ?([0-9]{3})? ?([0-9]{2})?/g, (_match, $1, $2, $3, $4, $5, $6, $7) => {
            let tel = "";
            [$1, $2, $3, $4, $5, $6, $7].forEach((number) => {
                tel += number ? number + " " : "";
            });
            return tel;
        });
        string = string.replace(/^([0-9 ]{22})(.)*/g, "$1");
        string = string.trim();
        return string;
    }
    else {
        return "";
    }
}
exports.default = nameCH;
