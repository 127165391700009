"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CheckBoxes = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const CheckBox_1 = require("../CheckBox/CheckBox");
require("./CheckBoxes.scss");
const CheckBoxes = (props) => {
    const toggle = (name, c) => {
        const checked = props.value
            ? [...props.value]
            : props.array.filter((item) => item.active).map((item) => item.name);
        const index = checked.indexOf(name);
        if (index >= 0) {
            checked.splice(index, 1);
            if (props.onChange) {
                props.onChange(checked);
            }
        }
        else {
            const newCheckedArray = props.array.reduce((newArray, item) => {
                if (checked.includes(item.name) || item.name === name) {
                    newArray.push(item.name);
                }
                return newArray;
            }, []);
            if (props.onChange) {
                props.onChange(newCheckedArray);
            }
        }
    };
    return ((0, jsx_runtime_1.jsx)("div", { className: `CheckBoxes${props.className ? " " + props.className : ""}`, style: props.style, children: props.array.map((item) => ((0, jsx_runtime_1.jsx)(CheckBox_1.CheckBox, { wrap: props.wrap, id: `CheckBox ${item.label}`, onChange: (checked) => toggle(item.name, checked), value: props.value ? props.value.includes(item.name) : item.active, label: item.label, name: props.name }, item.name ? item.name : item.label))) }));
};
exports.CheckBoxes = CheckBoxes;
