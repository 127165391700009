"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const calendarInfos_1 = require("../constants/calendarInfos");
class DayModel {
    date;
    start;
    end;
    weekDay;
    day;
    month;
    year;
    visible;
    key;
    constructor(date) {
        this.date = new Date(date);
        const dayFR = date.toLocaleDateString("fr", calendarInfos_1.dateFormatFR).split(" ");
        this.weekDay = dayFR[0];
        this.day = dayFR[1];
        this.month = dayFR[2];
        this.year = dayFR[3];
        this.visible = undefined;
        this.key = String(date.getFullYear() + date.getMonth() + date.getDate());
        const navDate = new Date(date);
        navDate.setHours(0);
        navDate.setMinutes(0);
        navDate.setSeconds(0);
        this.start = new Date(navDate);
        navDate.setHours(23);
        navDate.setMinutes(59);
        navDate.setSeconds(59);
        this.end = new Date(navDate);
    }
    includes = (date) => this.start.getTime() <= date.getTime() &&
        date.getTime() <= this.end.getTime();
}
exports.default = DayModel;
