"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.forceDestroy = void 0;
function forceDestroy(repo, ids) {
    const newRepoInstance = repo.set(repo.filter((doc) => !ids.includes(doc._id)));
    newRepoInstance.removed = newRepoInstance.removed.filter((doc) => !ids.includes(doc._id));
    newRepoInstance.archives = newRepoInstance.archives.filter((doc) => !ids.includes(doc._id));
    return newRepoInstance;
}
exports.forceDestroy = forceDestroy;
