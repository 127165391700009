"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TabsButtons = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
require("./TabsButtons.scss");
const TabsButtons = (props) => {
    return ((0, jsx_runtime_1.jsxs)("div", { className: `TabsButtons ${props.className || ""}`, children: [props.pages.map((b, i) => ((0, jsx_runtime_1.jsx)("a", { onClick: () => props.onClick && props.onClick(i), className: props.active === i ? "active" : "", children: b }, i))), (0, jsx_runtime_1.jsx)("div", { className: "Line", style: {
                    width: `calc(100%/${props.pages.length})`,
                    left: `calc((100%/${props.pages.length}) * ${props.active})`
                } })] }));
};
exports.TabsButtons = TabsButtons;
