"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const formatNumber_1 = require("../../utils/formatNumber");
function replaceOccurrence(string, regex, n, replace) {
    let i = 0;
    return string.replace(regex, function (match) {
        i += 1;
        if (i === n)
            return replace;
        return match;
    });
}
function dateCHFork(string, backward) {
    const [_d, _m, y] = string.split("/");
    string = string.slice(0, 10);
    string = string.replace(/[^0-9\/]/g, "/");
    string = string.replace(/\/+/g, "/");
    string = string.replace(/^\//, "");
    let monthInt;
    string = string.replace(/([0-9]+\/)([0-9]+)(.*)/g, (_m, day, month, end) => {
        month = month.slice(0, 2);
        month = parseInt(month, 10) > 12 ? "12" : month;
        monthInt = parseInt(month, 10);
        return `${day}${month}${end ? end : ""}`;
    });
    string = string.replace(/^[0-9]+/g, (day) => {
        day = day.slice(0, 2);
        const maxDayInMonth = new Date((y && y.length === 4 ? parseInt(y, 10) : undefined) || 2020, monthInt || 1, 0).getDate();
        day = parseInt(day, 10) > maxDayInMonth ? String(maxDayInMonth) : day;
        return day;
    });
    string = string.replace(/([0-9]+)(\/)/g, (_m, number, slash) => {
        return `${number.length === 1 ? (0, formatNumber_1.formatNumber)(number < 1 ? 1 : number) : number}${slash}`;
    });
    string = !backward
        ? string.replace(/^[0-9]{2}$/g, (match) => `${match}/`)
        : string;
    string = !backward
        ? string.replace(/^[0-9]{2}\/[0-9]{2}$/g, (match) => `${match}/`)
        : string;
    string = replaceOccurrence(string, /\//g, 3, "");
    return string;
}
exports.default = dateCHFork;
