"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Search = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const __1 = require("..");
require("./Search.scss");
function Search(props) {
    const [content, setContent] = (0, react_1.useState)("");
    (0, react_1.useEffect)(() => {
        if (typeof props.value === "string") {
            setContent(props.value);
        }
    }, [props.value]);
    function handleChange(event) {
        const string = event.target.value;
        if (props.onChange) {
            props.onChange(event);
        }
        if (props.onInputChange) {
            props.onInputChange(string);
        }
        setContent(string);
        if (string.length > 0) {
            const searchPromise = new Promise((resolve, reject) => {
                const words = string.trim().toLowerCase().split(" ");
                const searchScore = props.array.map((item) => {
                    let score = 0;
                    props.searchKeys.forEach((key) => {
                        words.forEach((word) => {
                            if (item[key]) {
                                const objectProperty = String(item[key]).trim().toLowerCase();
                                if (objectProperty.includes(word)) {
                                    score += 1;
                                }
                                if (word.includes(objectProperty)) {
                                    score += 1;
                                }
                                if (objectProperty === word.toLowerCase()) {
                                    score += 1;
                                }
                                if (objectProperty === string.trim().toLowerCase()) {
                                    score += 1;
                                }
                            }
                        });
                    });
                    return score;
                });
                const maxScore = Math.max(...searchScore);
                let searchResults = [];
                if (string.length > 0 && maxScore > 0) {
                    for (let n = maxScore; n > 0; n -= 1) {
                        searchScore.forEach((score, index) => {
                            if (score === n) {
                                searchResults.push(props.array[index]);
                            }
                        });
                    }
                }
                resolve(searchResults);
            });
            searchPromise
                .then((results) => {
                if (props.callback) {
                    props.callback(results, string);
                }
            });
        }
        else {
            setContent(" ");
            if (props.callback) {
                props.callback(props.array, string);
            }
        }
    }
    return ((0, jsx_runtime_1.jsx)(__1.Input, { value: typeof props.value === "string" ? props.value : content, className: `Search${props.className ? " " + props.className : ""}`, name: props.name, icon: props.icon, onKeyDown: props.onKeyDown, onFocus: props.onFocus, onBlur: props.onBlur, onChange: handleChange, label: props.label, style: props.style, inputRef: props.reference, autofocus: props.autofocus }));
}
exports.Search = Search;
