"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DaysNames = void 0;
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = tslib_1.__importStar(require("react"));
require("./DaysNames.scss");
const arePropsEqual = (prev, next) => prev.store.firstDayOfTheWeek && next.store.firstDayOfTheWeek &&
    prev.store.firstDayOfTheWeek.getTime() === next.store.firstDayOfTheWeek.getTime() &&
    prev.config.days === next.config.days;
const DaysNames = (props) => {
    const [days, setDays] = (0, react_1.useState)([]);
    (0, react_1.useEffect)(() => {
        if (props.store.firstDayOfTheWeek) {
            const newDays = [];
            for (let dayIndex = 0; dayIndex < props.config.days; dayIndex++) {
                const newDate = new Date(props.config.days === 1 ? props.config.day : props.store.firstDayOfTheWeek);
                newDate.setDate(newDate.getDate() + dayIndex);
                newDays.push(newDate);
            }
            setDays(newDays);
        }
    }, [props.config.days, props.store.firstDayOfTheWeek, props.config.day]);
    return ((0, jsx_runtime_1.jsx)("div", { className: "DaysNames", draggable: false, children: days.map((day, i) => ((0, jsx_runtime_1.jsxs)("p", { className: `DayName`, draggable: false, children: [props.config.weekday && (0, jsx_runtime_1.jsxs)("span", { draggable: false, className: "weekday", children: [day.toLocaleDateString("fr", { weekday: "long" }).substring(0, 3), "."] }), props.config.dayNumber && (0, jsx_runtime_1.jsx)("span", { draggable: false, className: "numday", children: day.getDate() }), (0, jsx_runtime_1.jsx)("span", { draggable: false, className: "borders calendarBorder" })] }, i))) }));
};
exports.DaysNames = DaysNames;
exports.default = react_1.default.memo(exports.DaysNames, arePropsEqual);
