"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CheckBox = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_fontawesome_1 = require("@fortawesome/react-fontawesome");
const free_solid_svg_icons_1 = require("@fortawesome/free-solid-svg-icons");
const react_transition_group_1 = require("react-transition-group");
require("./CheckBox.scss");
const CheckBox = (props) => {
    const [checked, setChecked] = (0, react_1.useState)(typeof props.value === "boolean"
        ? props.value
        : typeof props.defaultValue === "boolean"
            ? props.defaultValue
            : false);
    (0, react_1.useEffect)(() => {
        if (typeof props.value === "boolean") {
            setChecked(props.value);
        }
    }, [props.value]);
    function handleChange(event) {
        if (event) {
            event.stopPropagation();
        }
        const c = !checked;
        if (props.onChange) {
            props.onChange(c);
        }
        setChecked(c);
    }
    return ((0, jsx_runtime_1.jsxs)("div", { className: `CheckBox${props.className ? ` ${props.className}` : ""}${checked ? " checked" : ""}${props.wrap ? " wrap" : ""}`, style: props.style, onClick: handleChange, children: [(0, jsx_runtime_1.jsx)("input", { id: `CheckBox ${props.label}`, type: "checkbox", onChange: handleChange, checked: checked, value: checked ? "on" : "off", name: props.name }), (0, jsx_runtime_1.jsx)("div", { className: "square", children: (0, jsx_runtime_1.jsx)(react_transition_group_1.TransitionGroup, { children: checked && ((0, jsx_runtime_1.jsx)(react_transition_group_1.CSSTransition, { timeout: 100, children: (0, jsx_runtime_1.jsx)(react_fontawesome_1.FontAwesomeIcon, { icon: free_solid_svg_icons_1.faCheck }) }, props.name)) }) }), props.label && ((0, jsx_runtime_1.jsx)("label", { htmlFor: `CheckBox ${props.label}`, onClick: handleChange, children: props.label }))] }));
};
exports.CheckBox = CheckBox;
