"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Calendar = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
require("./Calendar.scss");
const MonthCalendar_1 = require("./MonthCalendar/MonthCalendar");
const WeekCalendar_1 = require("./WeekCalendar/WeekCalendar");
function Calendar(props) {
    return ((0, jsx_runtime_1.jsxs)("div", { className: "Calendar", children: [(0, jsx_runtime_1.jsx)("p", { children: "test" }), props.config?.displayMode === "week"
                ? ((0, jsx_runtime_1.jsx)(WeekCalendar_1.WeekCalendar, { user: props.user, config: props.config, tasks: props.tasks, onPost: props.onPost, onPatch: props.onPatch, onDelete: props.onDelete, onClick: props.onClick, renderEditor: props.renderEditor }))
                : ((0, jsx_runtime_1.jsx)(MonthCalendar_1.MonthCalendar, { user: props.user, config: props.config, tasks: props.tasks, onPost: props.onPost, onPatch: props.onPatch, onDelete: props.onDelete, onClick: props.onClick }))] }));
}
exports.Calendar = Calendar;
