"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const astra_1 = require("astra");
const free_solid_svg_icons_1 = require("@fortawesome/free-solid-svg-icons");
const react_fontawesome_1 = require("@fortawesome/react-fontawesome");
require("./style.scss");
const components_1 = require("../../components");
const BottomBar = (view) => {
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)("div", { className: "menuButton", children: (0, jsx_runtime_1.jsx)(react_fontawesome_1.FontAwesomeIcon, { onClick: () => view.update({ menu: !view.store.menu }), icon: view.store.menu ? free_solid_svg_icons_1.faFolderOpen : free_solid_svg_icons_1.faFolder }) }), (0, jsx_runtime_1.jsx)(astra_1.TabsButtons, { onClick: (page) => view.update({ page }), pages: ["Types", "Tarifs"], active: view.store.page }), (0, jsx_runtime_1.jsx)(components_1.RepositoriesLoadingBar, { repositories: view.repositories, session: view.session })] }));
};
exports.default = BottomBar;
