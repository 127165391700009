"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const astra_1 = require("astra");
require("./style.scss");
const react_fontawesome_1 = require("@fortawesome/react-fontawesome");
const free_solid_svg_icons_1 = require("@fortawesome/free-solid-svg-icons");
const components_1 = require("../../components");
const TopBar = (view) => {
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(react_fontawesome_1.FontAwesomeIcon, { icon: free_solid_svg_icons_1.faCalendarAlt, onClick: () => view.update({ calendarMenu: !view.store.calendarMenu }) }), (0, jsx_runtime_1.jsx)(astra_1.Select, { name: "days", value: view.store.config.days, label: "Mode", labels: ["Jour", "Semaine", "Semaine complète"], values: [1, 5, 7], onChange: (days) => view.update({ config: { ...view.store.config, days } }) }), (0, jsx_runtime_1.jsx)(components_1.RepositoriesLoadingBar, { repositories: view.repositories, session: view.session })] }));
};
exports.default = TopBar;
