"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Modal = void 0;
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = tslib_1.__importStar(require("react"));
const Button_1 = require("../../components/Button/Button");
const react_fontawesome_1 = require("@fortawesome/react-fontawesome");
const free_solid_svg_icons_1 = require("@fortawesome/free-solid-svg-icons");
require("./Modal.scss");
const Modal = (props) => {
    const formRef = react_1.default.createRef();
    const [error, setError] = (0, react_1.useState)("");
    function send(callback) {
        const inputs = formRef.current.getElementsByTagName("input");
        const inputsArray = [...inputs].concat([...formRef.current.getElementsByTagName("textarea")]);
        if (inputsArray.some((input) => input.required && !input.value)) {
            setError("Veuillez remplir tous les champs requis pour continuer.");
        }
        else {
            const values = {};
            inputsArray.forEach((input) => {
                if (input.type === "file") {
                    values[input.name] = input.files;
                }
                if (input.type === "checkbox" || input.type === "radio") {
                    values[input.name] = input.value === "on" ? true : false;
                }
                else {
                    values[input.name] =
                        input.value.charAt(0) === "[" && input.value.charAt(input.value.length - 1) === "]"
                            ? JSON.parse(input.value)
                            : input.value === "true"
                                ? true
                                : input.value === "false"
                                    ? false
                                    : input.value;
                }
            });
            if (callback) {
                return callback(values);
            }
        }
    }
    return ((0, jsx_runtime_1.jsxs)("div", { className: `Modal ${props.resolving ? "resolving" : ""} ${props.className || ""}`, children: [(0, jsx_runtime_1.jsx)("div", { className: "background", onClick: props.reject }), props.raw && props.form ? ((0, jsx_runtime_1.jsx)("form", { ref: formRef, children: typeof props.form === "function" ? (props.form(props.resolve || (() => undefined), props.reject || (() => undefined))) : (react_1.default.cloneElement(props.form, { ...props.form.props, resolve: props.resolve, reject: props.reject })) }))
                : ((0, jsx_runtime_1.jsxs)("div", { className: `modalContent`, children: [(0, jsx_runtime_1.jsx)(react_fontawesome_1.FontAwesomeIcon, { icon: free_solid_svg_icons_1.faTimes, className: "quitIcon", onClick: props.reject }), props.title && (0, jsx_runtime_1.jsx)("h2", { children: props.title }), props.message && (0, jsx_runtime_1.jsx)("p", { className: "message", children: props.message }), error && (0, jsx_runtime_1.jsx)("p", { className: "modalError", children: error }), props.form && ((0, jsx_runtime_1.jsx)("form", { ref: formRef, children: typeof props.form === "function" ? (props.form(props.resolve || (() => undefined), props.reject || (() => undefined))) : (react_1.default.cloneElement(props.form, { ...props.form.props, resolve: props.resolve, reject: props.reject })) })), (props.rejectButton || props.resolveButton || !props.form) && ((0, jsx_runtime_1.jsxs)("div", { className: "row buttonsRow", children: [props.rejectButton && ((0, jsx_runtime_1.jsx)(Button_1.Button, { className: "rejectButton", name: props.rejectButton ? props.rejectButton : "Ok", onClick: props.reject })), (props.resolveButton || !props.form) && ((0, jsx_runtime_1.jsx)(Button_1.Button, { className: "resolveButton", name: props.resolveButton ? props.resolveButton : "Ok", onClick: (event) => props.form ? send(props.resolve) : props.resolve ? props.resolve(event) : false, filled: true }))] }))] }))] }));
};
exports.Modal = Modal;
