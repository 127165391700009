"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PromoSkillPicker = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const free_solid_svg_icons_1 = require("@fortawesome/free-solid-svg-icons");
const react_1 = require("react");
const react_2 = require("react");
const astra_1 = require("astra");
require("./PromoSkillPicker.scss");
const PromoSkillPicker = (props) => {
    const sunContext = (0, astra_1.useSunContext)();
    const getSemesterSkills = (promotionId) => {
        const promotion = sunContext.repositories.promotion.findById(promotionId);
        if (!promotion)
            return [];
        const faculty = sunContext.repositories.faculty.findById(promotion.faculty);
        if (!faculty)
            return [];
        const inYearSemester = promotion.semesters.findIndex(sem => sem.start <= props.date && props.date <= sem.end) + 1;
        if (inYearSemester === 0)
            return [];
        let semester = 0;
        for (let year = 1; year < Number(promotion.year); year += 1) {
            semester += faculty.years[year];
        }
        semester += inYearSemester;
        if (semester === 0)
            return [];
        const sameSemesterSkills = sunContext.repositories.skill.filter((skill) => {
            if (skill.semester !== semester) {
                return false;
            }
            if (sunContext.repositories.skill.some((s) => s.parent === skill._id)) {
                return false;
            }
            const mainSkill = sunContext.repositories.mainSkill.find(ms => ms._id === skill.mainSkill);
            if (!mainSkill || mainSkill.faculty !== faculty._id) {
                return false;
            }
            return true;
        });
        return sameSemesterSkills;
    };
    const [value, setValue] = (0, react_1.useState)(props.value || []);
    (0, react_2.useEffect)(() => {
        if (props.value) {
            setValue(props.value);
        }
    }, [props.value]);
    const promotionsList = [...props.schooInfos.user.promotions, ...props.schooInfos.others.promotions].filter(p => !p.archived);
    const availablePromotions = promotionsList.filter(promotion => {
        return !value.some(promoSkill => {
            return (promoSkill.promotion === promotion._id);
        });
    });
    function set(newValue) {
        setValue(newValue);
        if (props.onChange) {
            props.onChange(newValue);
        }
    }
    function add() {
        if (availablePromotions.length > 0) {
            const newValue = [...value, { promotion: availablePromotions[0]._id, skill: undefined }];
            set(newValue);
        }
    }
    function patch(index, patch) {
        const newValue = value.map((ps, i) => {
            if (i === index) {
                return { ...ps, ...patch };
            }
            else {
                return ps;
            }
        });
        set(newValue);
    }
    function remove(index) {
        const newValue = value.filter((_ps, i) => i !== index);
        set(newValue);
    }
    const skills = [...props.schooInfos.user.skills, ...props.schooInfos.others.skills];
    return ((0, jsx_runtime_1.jsxs)("div", { className: "PromoSkillPicker", children: [(0, jsx_runtime_1.jsxs)("div", { className: "rows", children: [(0, jsx_runtime_1.jsxs)(astra_1.Row, { flex: true, className: "title", children: [(0, jsx_runtime_1.jsx)("p", { children: "Promotions" }), (0, jsx_runtime_1.jsx)("p", { children: "Comp\u00E9tences" })] }), value.map((promoSkill, i) => {
                        const thisPromotion = sunContext.repositories.promotion.findById(promoSkill.promotion);
                        const promotionsArray = [thisPromotion, ...availablePromotions];
                        const skillsArray = getSemesterSkills(promoSkill.promotion);
                        return ((0, jsx_runtime_1.jsxs)(astra_1.Row, { flex: true, children: [(0, jsx_runtime_1.jsx)(astra_1.Select, { labels: promotionsArray.map(p => p.name), values: promotionsArray.map(p => p._id), name: "promotion", value: promoSkill.promotion, onChange: (promotion) => patch(i, { promotion }) }), (0, jsx_runtime_1.jsx)(astra_1.Select, { labels: skillsArray.map(s => s.getCode()
                                        + " · " + s.title), values: skillsArray.map(s => s._id), name: "skill", value: promoSkill.skill, onChange: (skill) => patch(i, { skill }) }), (0, jsx_runtime_1.jsx)(astra_1.Button, { icon: free_solid_svg_icons_1.faMinus, filled: true, className: "removeButton", onClick: () => remove(i) })] }, i));
                    })] }), (0, jsx_runtime_1.jsx)(astra_1.Button, { icon: free_solid_svg_icons_1.faPlus, filled: true, className: "addButton", onClick: availablePromotions.length > 0 ? add : () => { }, disabled: availablePromotions.length === 0 })] }));
};
exports.PromoSkillPicker = PromoSkillPicker;
