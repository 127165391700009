"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.exportTeachers = void 0;
const safeString = (string) => string ? string.replace("\n", "").replace(";", ".") : "";
function exportTeachers(repositories) {
    const documentArray = [];
    documentArray[0] = ["sep=;"];
    documentArray[1] = [
        "Statut",
        "Nom",
        "Prénom",
        "Nom de naissance",
        "Date de naissance",
        "Lieu de naissance",
        "Téléphone fixe",
        "Téléphone portable",
        "E-mail",
        "Numéro",
        "Bis/Ter",
        "Nature de la voie",
        "Nom de la voie",
        "Complément d'adresse",
        "Code postal",
        "Bureau distributeur",
        "Numéro de sécurité sociale",
        "Clé",
        "Métier",
        "Employeur",
        "Diplôme",
        "Agréé par l'université",
        "Chercheur",
        "Nom de l'assiociation",
        "Date d'admission",
        "Matricule",
        "Structure"
    ];
    repositories.teacher.forEach(teacher => {
        documentArray[documentArray.length] = [
            teacher.statut === 1
                ? "Formateur permanent"
                : teacher.statut === 2
                    ? "Intervenant à titre gracieux"
                    : teacher.statut === 3
                        ? "Prestataire de service"
                        : "Vacataire en CTU",
            safeString(teacher.nom),
            safeString(teacher.prenom),
            safeString(teacher.nom_de_naissance),
            teacher.date_de_naissance
                ? `${teacher.date_de_naissance.getDate()}/${teacher.date_de_naissance.getMonth() + 1}/${teacher.date_de_naissance.getFullYear()}`
                : "",
            safeString(teacher.lieu_de_naissance),
            safeString(teacher.telephone_fixe),
            safeString(teacher.telephone_portable),
            safeString(teacher.email),
            safeString(teacher.numero),
            safeString(teacher.bis_ter),
            safeString(teacher.nature_de_la_voie),
            safeString(teacher.nom_de_la_voie),
            safeString(teacher.complement_d_adresse),
            safeString(teacher.code_postal),
            safeString(teacher.bureau_distributeur),
            safeString(teacher.numero_de_securite_sociale),
            safeString(teacher.cle),
            safeString(teacher.metier),
            safeString(teacher.employeur),
            safeString(teacher.diplome),
            teacher.agree_par_l_universite ? "oui" : "non",
            teacher.chercheur ? "oui" : "non",
            safeString(teacher.nom_association),
            teacher.date_dossier_adm
                ? `${teacher.date_dossier_adm.getMonth() + 1}/${teacher.date_dossier_adm.getFullYear()}`
                : "",
            safeString(teacher.matricule),
            safeString(teacher.structure)
        ];
    });
    let CSV = "";
    for (let x = 0; x < documentArray.length; x++) {
        for (let y = 0; y < documentArray[x].length; y++) {
            CSV += documentArray[x][y];
            if (y < documentArray[x].length - 1) {
                CSV += ";";
            }
        }
        CSV += "\n";
    }
    const hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURI(CSV);
    hiddenElement.target = '_blank';
    hiddenElement.download = `formateurs_export.csv`;
    hiddenElement.click();
}
exports.exportTeachers = exportTeachers;
