"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConfirmDeleteModal = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const astra_1 = require("astra");
const react_fontawesome_1 = require("@fortawesome/react-fontawesome");
const free_solid_svg_icons_1 = require("@fortawesome/free-solid-svg-icons");
require("./ConfirmDeleteModal.scss");
const ConfirmDeleteModal = (props) => {
    const [state, setState] = (0, react_1.useState)({
        inputString: "",
        matching: false,
        resolving: false
    });
    const onInputChange = (string) => {
        setState({
            inputString: string,
            matching: string.toLowerCase().trim() === props.match.toLowerCase().trim(),
            resolving: state.resolving
        });
    };
    const onSubmit = () => {
        if (state.matching && !state.resolving) {
            setState({ ...state, resolving: true });
            props.resolvingFunction();
        }
    };
    return ((0, jsx_runtime_1.jsxs)("div", { className: "ConfirmDeleteModal", children: [(0, jsx_runtime_1.jsx)("h4", { children: props.title }), state.resolving ? ((0, jsx_runtime_1.jsx)("p", { children: props.resolvingText })) : ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)("p", { children: props.instruction }), (0, jsx_runtime_1.jsx)("p", { className: `matchString${state.matching ? " ok" : ""}`, children: props.match }), (0, jsx_runtime_1.jsx)(astra_1.Input, { value: state.inputString, name: "confirmDeleteString", onInputChange: onInputChange })] })), (0, jsx_runtime_1.jsxs)(astra_1.Row, { children: [(0, jsx_runtime_1.jsx)(astra_1.Button, { name: "Annuler", onClick: props.reject, disabled: state.resolving }), (0, jsx_runtime_1.jsx)(astra_1.Button, { className: `addButton${state.resolving ? " resolving" : ""}`, name: "Confirmer", disabled: !state.matching, onClick: onSubmit, children: state.resolving ? ((0, jsx_runtime_1.jsx)(react_fontawesome_1.FontAwesomeIcon, { className: "buttonSpinner", icon: free_solid_svg_icons_1.faCircleNotch })) : ("Confirmer") })] })] }));
};
exports.ConfirmDeleteModal = ConfirmDeleteModal;
