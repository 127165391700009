"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.patch = void 0;
function patch(repo, patches) {
    console.log('patch controller');
    const newRepoInstance = repo.clone();
    patches.forEach(patch => {
        if (patch._id) {
            const docIndex = newRepoInstance.findIndex((doc) => doc._id === patch._id);
            if (docIndex >= 0) {
                for (const patchKey in patch) {
                    const key = patchKey;
                    newRepoInstance[docIndex][key] = patch[key];
                }
            }
        }
    });
    const newerRpoInstance = newRepoInstance.set(newRepoInstance.map((doc) => {
        if (patches.some(d => d._id === doc._id)) {
            return new newRepoInstance.__DocumentClass(doc);
        }
        else {
            return doc;
        }
    }));
    return newerRpoInstance;
}
exports.patch = patch;
