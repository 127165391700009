"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Button = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_fontawesome_1 = require("@fortawesome/react-fontawesome");
require("./Button.scss");
const Button = (props) => {
    return ((0, jsx_runtime_1.jsxs)("button", { className: `Button ${props.active ? "active" : ""} ${props.filled ? "filled" : "outlined"} ${props.className ? " " + props.className : ""}${props.disabled ? " disabled" : ""}${!props.children && !props.name && props.icon ? " onlyIcon" : ""}`, name: props.name, id: props.name, value: props.name, onClick: (event) => {
            props.disabled && event.stopPropagation();
            props.onClick && props.onClick(event);
        }, onMouseDown: (event) => {
            props.disabled && event.stopPropagation();
            props.onMouseDown && props.onMouseDown(event);
        }, style: props.style, type: props.type ? props.type : "button", ref: props.reference, children: [props.icon && (0, jsx_runtime_1.jsx)(react_fontawesome_1.FontAwesomeIcon, { icon: props.icon }), props.children ? props.children : props.name] }));
};
exports.Button = Button;
