"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TeachersPage = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const astra_1 = require("astra");
const rowPerPage = 20;
function TeachersPage(props) {
    const [state, setState] = (0, react_1.useState)({ page: 1, searchString: "", searchResult: [] });
    const stateRef = (0, react_1.useRef)(state);
    stateRef.current = state;
    const tableHead = [
        {
            label: "Nom",
            key: "teacher",
            type: "function",
            function: (teacherCDCS => (0, jsx_runtime_1.jsx)("span", { className: !teacherCDCS.teacher.dossierValide ? "teacherRed" : "", children: `${teacherCDCS.teacher.prenom} ${teacherCDCS.teacher.nom.toUpperCase()}` }))
        },
    ];
    const teacherArray = props.teachersCDCs.map(t => t.teacher);
    const array = state.searchString.length > 0 ? state.searchResult : teacherArray;
    const tableArray = array.map(t => props.teachersCDCs.find(tc => tc.teacher._id === t._id))
        .filter(t => t);
    return ((0, jsx_runtime_1.jsxs)("div", { className: "TeachersPages", children: [(0, jsx_runtime_1.jsx)("h3", { children: "Formateur\u00B7rice" }), (0, jsx_runtime_1.jsx)(astra_1.Search, { label: "Recherche", name: "teachers", array: teacherArray, searchKeys: ["prenom", "nom", "email", "metier"], value: state.searchString, onInputChange: (searchString) => setState({ ...stateRef.current, searchString, page: 1 }), callback: (searchResult) => searchResult && setState({ ...stateRef.current, searchResult, page: 1 }) }), (0, jsx_runtime_1.jsx)(astra_1.Pagination, { pageAmount: Math.ceil(tableArray.length / rowPerPage), page: stateRef.current.page, onClick: (page) => setState({ ...stateRef.current, page }), previousButton: "Pr\u00E9c\u00E9dent", nextButton: "Suivant", primarySeparator: "-", balance: true }), props.teachersCDCs.length > 0 ? ((0, jsx_runtime_1.jsx)(astra_1.Table, { array: tableArray, head: tableHead, onClick: props.onPick, flex: true, rowPerPage: rowPerPage, page: state.page }))
                : ((0, jsx_runtime_1.jsx)("div", { children: (0, jsx_runtime_1.jsx)("h3", { children: "Aucun cahier des charges n'est en attente." }) }))] }));
}
exports.TeachersPage = TeachersPage;
