"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class asyncAction {
    DO;
    DONE;
    FAIL;
    constructor(action) {
        this.DO = `${action}_DO`;
        this.DONE = `${action}_DONE`;
        this.FAIL = `${action}_FAIL`;
    }
}
exports.default = asyncAction;
