"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.archive = void 0;
function archive(repo, ids) {
    const newRepoArray = [];
    const newRepoArchivedDocuments = [];
    repo.forEach(doc => {
        if (ids.includes(doc._id)) {
            doc.archived = true;
            newRepoArchivedDocuments.push(doc);
        }
        else {
            newRepoArray.push(doc);
        }
    });
    const newRepoInstance = repo.set(newRepoArray);
    newRepoInstance.archives.push(...newRepoArchivedDocuments);
    return newRepoInstance;
}
exports.archive = archive;
