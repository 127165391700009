"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Select = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const Button_1 = require("../Button/Button");
require("./Select.scss");
const ViewComposer_1 = require("../../core/ViewComposer/ViewComposer");
const Select = (props) => {
    const sunContext = (0, ViewComposer_1.useSunContext)();
    const buttonRef = (0, react_1.useRef)();
    const [selection, setSelection] = (0, react_1.useState)(selectionState(props.value));
    function handleChange(newSelection) {
        setSelection(newSelection);
        console.log("handleChange", newSelection);
        props.onChange && props.onChange(props.multiple ? newSelection : newSelection[0]);
    }
    (0, react_1.useEffect)(() => {
        setSelection(selectionState(props.value));
    }, [props.value]);
    function open() {
        const rect = buttonRef.current?.getBoundingClientRect();
        if (rect) {
            sunContext.modal({
                className: `SelectModal ${props.className || ""}`,
                raw: true,
                delay: 0,
                form: (resolve, reject) => (0, jsx_runtime_1.jsx)(SelectListModal, { ...props, resolve: resolve, reject: reject, value: selection, buttonRect: rect, buttonRef: buttonRef, onChange: handleChange })
            })
                .then(() => { })
                .catch(() => { });
        }
    }
    const text = props.text || props.values.reduce((string, value, i) => {
        if (selection.includes(value)) {
            const valueIndex = props.values.findIndex(v => v === value);
            let label = props.labels[valueIndex];
            label = props.displayFilter ? props.displayFilter(label) : label;
            return `${string}${label}${i < selection.length - 1 ? ", " : ""}`;
        }
        else
            return string;
    }, "");
    return ((0, jsx_runtime_1.jsxs)("div", { className: `Select ${props.className || ""}`, children: [props.label && ((0, jsx_runtime_1.jsx)("label", { htmlFor: text, children: props.label })), (0, jsx_runtime_1.jsx)(Button_1.Button, { name: text, onClick: open, reference: buttonRef }), (0, jsx_runtime_1.jsx)("input", { type: "hidden", value: selection ? (props.multiple ? JSON.stringify(selection) : selection[0]) : "", name: props.name, required: props.required })] }));
};
exports.Select = Select;
function SelectListModal(props) {
    const [state, setState] = (0, react_1.useState)({
        rect: props.buttonRect,
        selection: selectionState(props.value)
    });
    (0, react_1.useEffect)(() => {
        setState({
            ...state,
            selection: selectionState(props.value)
        });
    }, [props.value]);
    const resize = () => {
        const newRect = props.buttonRef.current?.getBoundingClientRect();
        if (newRect)
            setState({ ...state, rect: newRect });
    };
    (0, react_1.useEffect)(() => {
        window.addEventListener("resize", resize);
        return () => {
            window.removeEventListener("resize", resize);
        };
    }, []);
    function handleClick(index) {
        let newSelection = [];
        if (props.multiple) {
            const alreadySelected = state.selection.includes(props.values[index]);
            if (alreadySelected) {
                newSelection = props.values.filter((v, i) => i !== index &&
                    state.selection.includes(v));
            }
            else {
                newSelection = props.values.filter((v, i) => i === index ||
                    state.selection.includes(v));
            }
        }
        else {
            newSelection = [props.values[index]];
            props.resolve();
        }
        setState({ ...state, selection: newSelection });
        props.onChange && props.onChange(newSelection);
    }
    const rect = props.buttonRef.current?.getBoundingClientRect() ? state.rect : props.buttonRect;
    const style = {
        left: `${rect.left}px`,
        minWidth: `${rect.width}px`,
    };
    if (rect.top < window.innerHeight / 2) {
        style.top = `${rect.top + rect.height}px`;
    }
    else {
        style.bottom = `${window.innerHeight - rect.bottom + rect.height}px`;
    }
    return ((0, jsx_runtime_1.jsx)("ul", { className: "SelectList", style: style, children: props.labels.map((label, i) => ((0, jsx_runtime_1.jsx)("li", { onClick: () => handleClick(i), className: state.selection.includes(props.values[i]) ? "active" : "", children: label }, i))) }));
}
function selectionState(value) {
    return Array.isArray(value)
        ? value
        : value
            ? [value]
            : [];
}
