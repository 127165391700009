"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Roles = exports.Role = void 0;
const astra_1 = require("astra");
const astra_2 = require("astra");
const repositoryName = "role";
class Role extends astra_2.Role {
    constructor(doc) {
        super(repositoryName, doc);
    }
}
exports.Role = Role;
class Roles extends astra_1.Repository {
    constructor(docs) {
        super(repositoryName, Roles, Role, docs);
    }
}
exports.Roles = Roles;
exports.default = new Roles();
