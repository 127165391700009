"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const document_class_1 = require("./document.class");
const useRepositories_1 = require("../core/ViewComposer/useRepositories");
class Repository extends Array {
    __repositoryName;
    __RepositoryClass;
    __DocumentClass;
    repositories;
    _cache;
    archives;
    removed;
    loaded;
    constructor(repositoryName, RepositoryClass, DocumentClass, docs) {
        if (docs && Array.isArray(docs)) {
            super(...docs.map(doc => new DocumentClass(doc)));
            this.loaded = true;
        }
        else {
            super();
            this.loaded = false;
        }
        this.archives = [];
        this.removed = [];
        this.__repositoryName = repositoryName;
        this.__RepositoryClass = RepositoryClass;
        this.__DocumentClass = DocumentClass;
        this.repositories = document_class_1.RepositoriesGlobalRef;
        this._cache = {};
    }
    getRepositories() {
        return this.repositories.current;
    }
    set(docs) {
        console.log("set 1", docs);
        const newInstance = new this.__RepositoryClass(docs);
        newInstance.archives = [...this.archives];
        newInstance.removed = [...this.removed];
        return newInstance;
    }
    clear() {
        const newInstance = new this.__RepositoryClass();
        newInstance.archives = [];
        newInstance.removed = [];
        return newInstance;
    }
    setArchives(docs) {
        const newInstance = this.clone();
        newInstance.archives = docs.map(doc => new this.__DocumentClass(doc));
        return newInstance;
    }
    setRemoved(docs) {
        const newInstance = this.clone();
        newInstance.removed = docs.map(doc => new this.__DocumentClass(doc));
        return newInstance;
    }
    clone() {
        const newInstance = new this.__RepositoryClass([...this]);
        newInstance.archives = [...this.archives];
        newInstance.removed = [...this.removed];
        return newInstance;
    }
    document(doc) {
        return new this.__DocumentClass(doc);
    }
    localyRemove(_ids) {
        return this.set(this.filter(doc => !_ids.includes(doc._id)));
    }
    post(doc_s, ...moreDocs) {
        let docs;
        if (moreDocs.length === 0 && typeof doc_s === "object" && !(doc_s instanceof Array)) {
            docs = [doc_s];
        }
        else if (moreDocs.length === 0 && doc_s instanceof Array && doc_s.every((doc) => typeof doc === "object")) {
            docs = doc_s;
        }
        else if (moreDocs.length > 0 &&
            moreDocs instanceof Array &&
            moreDocs.every((doc) => typeof doc === "object") &&
            !(doc_s instanceof Array)) {
            docs = [doc_s, ...moreDocs];
        }
        else {
            throw new Error(`No overload match this call (Repository ${this.__repositoryName}.post(doc_s: ${typeof doc_s}, moreDocs: ${typeof moreDocs})).`);
        }
        docs = docs.map(doc => new this.__DocumentClass(doc));
        docs.forEach(doc => {
            delete doc.repositories;
        });
        return useRepositories_1.useRepositoryCallbacks.applyPost(this.__repositoryName, docs);
    }
    patch(arg1, patchObject) {
        let patches;
        if (!patchObject && typeof arg1 === "object" && !(Array.isArray(arg1)) && arg1._id) {
            const updateObject = arg1;
            patches = [updateObject];
        }
        else if (!patchObject && typeof arg1 === "object" && Array.isArray(arg1)) {
            const updateObjects = arg1.filter(o => typeof o !== "string");
            patches = updateObjects;
        }
        else if (typeof arg1 === "string" &&
            typeof patchObject === "object" &&
            !(patchObject instanceof Array) &&
            !(patchObject instanceof Repository)) {
            const _id = arg1;
            patches = [{ _id, ...patchObject }];
        }
        else if (arg1 instanceof Array && typeof patchObject === "object") {
            const _ids = arg1;
            patches = _ids.map(_id => ({ _id, ...patchObject }));
        }
        else {
            throw new Error(`No overload match this call (Repository ${this.__repositoryName}.patch(arg1: ${typeof arg1}, patchObject: ${typeof patchObject})).`);
        }
        return useRepositories_1.useRepositoryCallbacks.applyPatch(this.__repositoryName, patches);
    }
    remove(_id_s) {
        let ids;
        if (typeof _id_s === "string") {
            ids = [_id_s];
        }
        else if (_id_s instanceof Array) {
            ids = _id_s;
        }
        else {
            throw new Error(`No overload match this call (Repository ${this.__repositoryName}.remove(_id_s: ${typeof _id_s}))`);
        }
        return useRepositories_1.useRepositoryCallbacks.applyRemove(this.__repositoryName, ids);
    }
    restore(_id_s) {
        let ids;
        if (typeof _id_s === "string") {
            ids = [_id_s];
        }
        else if (_id_s instanceof Array) {
            ids = _id_s;
        }
        else {
            throw new Error(`No overload match this call (Repository ${this.__repositoryName}.remove(_id_s: ${typeof _id_s}))`);
        }
        return useRepositories_1.useRepositoryCallbacks.applyRestore(this.__repositoryName, ids);
    }
    destroy(_id_s) {
        let ids;
        if (typeof _id_s === "string") {
            ids = [_id_s];
        }
        else if (_id_s instanceof Array) {
            ids = _id_s;
        }
        else {
            throw new Error(`No overload match this call (Repository ${this.__repositoryName}.remove(_id_s: ${typeof _id_s}))`);
        }
        return useRepositories_1.useRepositoryCallbacks.applyDestroy(this.__repositoryName, ids);
    }
    forceDestroy(_id_s) {
        let ids;
        if (typeof _id_s === "string") {
            ids = [_id_s];
        }
        else if (_id_s instanceof Array) {
            ids = _id_s;
        }
        else {
            throw new Error(`No overload match this call (Repository ${this.__repositoryName}.remove(_id_s: ${typeof _id_s}))`);
        }
        return useRepositories_1.useRepositoryCallbacks.applyForceDestroy(this.__repositoryName, ids);
    }
    archive(_id_s) {
        let ids;
        if (typeof _id_s === "string") {
            ids = [_id_s];
        }
        else if (_id_s instanceof Array) {
            ids = _id_s;
        }
        else {
            throw new Error(`No overload match this call (Repository ${this.__repositoryName}.remove(_id_s: ${typeof _id_s}))`);
        }
        return useRepositories_1.useRepositoryCallbacks.applyArchive(this.__repositoryName, ids);
    }
    unarchive(_id_s) {
        let ids;
        if (typeof _id_s === "string") {
            ids = [_id_s];
        }
        else if (_id_s instanceof Array) {
            ids = _id_s;
        }
        else {
            throw new Error(`No overload match this call (Repository ${this.__repositoryName}.remove(_id_s: ${typeof _id_s}))`);
        }
        return useRepositories_1.useRepositoryCallbacks.applyUnarchive(this.__repositoryName, ids);
    }
    getAll() {
        return useRepositories_1.useRepositoryCallbacks.applyGetAll(this.__repositoryName, undefined);
    }
    getArchives() {
        return useRepositories_1.useRepositoryCallbacks.applyGetArchives(this.__repositoryName, undefined);
    }
    getRemoved() {
        return useRepositories_1.useRepositoryCallbacks.applyGetRemoved(this.__repositoryName, undefined);
    }
    findById(_id) {
        return this.find(doc => doc._id === _id);
    }
    findByProperty(property, value) {
        return this.find(doc => doc[property] === value);
    }
    filterByProperty(property, value) {
        return this.filter(doc => doc[property] === value);
    }
    getIdsMap() {
        return this.map(doc => doc._id);
    }
    memo(func, funcsDependencies, memoId) {
        const resetMemo = () => {
            const returnValue = func();
            this._cache[memoId] = {
                funcsLastDependenciesValues: funcsDependencies,
                funcsLastReturnData: returnValue
            };
            return returnValue;
        };
        if (!this._cache[memoId]) {
            return resetMemo();
        }
        else {
            if (areArraysEqual(this._cache[memoId].funcsLastDependenciesValues, funcsDependencies)) {
                return this._cache[memoId].funcsLastReturnData;
            }
            else {
                return resetMemo();
            }
        }
    }
}
exports.default = Repository;
function areArraysEqual(a, b) {
    for (let i = 0; i < a.length; i++) {
        if (a[i] !== b[i])
            return false;
    }
    return true;
}
