"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.unarchive = void 0;
function unarchive(repo, ids) {
    const newRepoDocuments = [];
    const newRepoArchivedArray = [];
    repo.archives.forEach(doc => {
        if (ids.includes(doc._id)) {
            doc.archived = false;
            newRepoDocuments.push(doc);
        }
        else {
            newRepoArchivedArray.push(doc);
        }
    });
    const newRepoInstance = repo.set(repo.concat(newRepoDocuments));
    newRepoInstance.archives = newRepoArchivedArray;
    return newRepoInstance;
}
exports.unarchive = unarchive;
