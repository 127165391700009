"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NewContratForm = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const astra_1 = require("astra");
const TeachersPage_1 = require("./formPages/TeachersPage");
const SitesPage_1 = require("./formPages/SitesPage");
const CDCsPage_1 = require("./formPages/CDCsPage");
const getTeachersContrats_1 = require("./getTeachersContrats");
require("./newContratFrom.scss");
const Contrat_model_1 = require("../../../../../../models/Contrat.model");
function NewContratForm(props) {
    const [state, setState] = (0, react_1.useState)({
        page: 0,
        teacherContrats: null,
        teacherSites: null,
        cdcs: null
    });
    const [teachersContrats] = (0, react_1.useState)((0, getTeachersContrats_1.getTeachersContrats)(props.repositories));
    return ((0, jsx_runtime_1.jsx)(astra_1.TabsPages, { active: state.page, children: [
            (0, jsx_runtime_1.jsx)(TeachersPage_1.TeachersPage, { teachersContrats: teachersContrats, onPick: (teacherContrats) => setState({ ...state, teacherContrats, page: 1 }) }),
            (0, jsx_runtime_1.jsx)(SitesPage_1.SitesPage, { teacherContrats: state.teacherContrats, onPick: (teacherSites) => setState({ ...state, teacherSites, page: 2 }), back: () => setState({ ...state, page: 0, teacherContrats: null }) }),
            (0, jsx_runtime_1.jsx)(CDCsPage_1.CDCsPage, { repositories: props.repositories, teacherSites: state.teacherSites, onPick: (cdcs) => {
                    if (state.teacherContrats && state.teacherSites && props.user && cdcs.length > 0) {
                        let startDate, endDate;
                        cdcs.forEach((cdc) => {
                            if (!startDate || startDate > cdc.startDate) {
                                startDate = new Date(cdc.startDate);
                            }
                            if (!endDate || endDate < cdc.endDate) {
                                endDate = new Date(cdc.endDate);
                            }
                        });
                        props.resolve(new Contrat_model_1.Contrat({
                            teacher: state.teacherContrats.teacher._id,
                            cdcs: cdcs.map(cdc => cdc._id),
                            startDate,
                            endDate,
                        }));
                    }
                }, back: () => setState({ ...state, page: 1, teacherSites: null }) }),
        ] }));
}
exports.NewContratForm = NewContratForm;
