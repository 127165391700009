"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const astra_1 = require("astra");
const user_tab_1 = require("./tabs/user.tab");
const roles_tab_1 = require("./tabs/roles.tab");
require("./style.scss");
const Main = (view) => {
    return ((0, jsx_runtime_1.jsx)("div", { className: "Utilisateurs", children: (0, jsx_runtime_1.jsxs)(astra_1.TabsPages, { active: view.store.tab, children: [(0, jsx_runtime_1.jsx)(user_tab_1.UsersTab, { ...view }), (0, jsx_runtime_1.jsx)(roles_tab_1.RolesTab, { ...view })] }) }));
};
exports.default = Main;
