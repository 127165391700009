"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PeriodsPicker = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const astra_1 = require("astra");
const free_solid_svg_icons_1 = require("@fortawesome/free-solid-svg-icons");
require("./PeriodsPicker.scss");
const PeriodsPicker = (props) => {
    const calculDefaultArray = (currentValue) => {
        currentValue = currentValue || props.value;
        const defaultArray = currentValue && Array.isArray(currentValue) ? currentValue : [];
        if (props.fixedAmount && defaultArray.length !== props.fixedAmount) {
            if (defaultArray.length > props.fixedAmount) {
                return defaultArray.slice(0, props.fixedAmount);
            }
            else {
                const delta = props.fixedAmount - defaultArray.length;
                for (let i = 1; i <= delta; i++) {
                    defaultArray.push({ start: new Date(), end: new Date() });
                }
            }
            if (props.onChange) {
                props.onChange(defaultArray);
            }
        }
        return defaultArray;
    };
    const [periods, setPeriods] = (0, react_1.useState)(calculDefaultArray());
    const handleSet = (newPeriods) => {
        if (props.sort) {
            newPeriods.sort((a, b) => !a.start || !b.start ? 0
                : !a.start.getTime || !b.start.getTime ? 0
                    : a.start.getTime() - b.start.getTime());
        }
        if (props.onChange) {
            props.onChange(newPeriods);
        }
        setPeriods(newPeriods);
    };
    const handleChange = (index, patch) => {
        handleSet(periods.map((period, i) => {
            if (i !== index)
                return period;
            return { ...period, ...patch };
        }));
    };
    const handleRemove = (index) => {
        handleSet(periods.filter((_p, i) => index !== i));
    };
    const handleAdd = () => {
        handleSet([...periods, { start: new Date(), end: new Date() }]);
    };
    (0, react_1.useEffect)(() => {
        if (props.value && Array.isArray(props.value)) {
            setPeriods(calculDefaultArray(periods));
        }
    }, [props.value, props.fixedAmount]);
    return ((0, jsx_runtime_1.jsxs)("div", { className: "PeriodsPicker", children: [periods.map((period, i) => {
                return ((0, jsx_runtime_1.jsxs)(astra_1.Row, { flex: true, children: [(0, jsx_runtime_1.jsx)(astra_1.DatePicker, { label: "D\u00E9but", name: "start", value: period.start, start: props.start, end: props.end, onChange: (start) => handleChange(i, { start: start || new Date() }) }), (0, jsx_runtime_1.jsx)(astra_1.DatePicker, { label: "Fin", name: "end", value: period.end, start: props.start, end: props.end, onChange: (end) => handleChange(i, { end: end || new Date() }) }), !props.fixedAmount && ((0, jsx_runtime_1.jsx)(astra_1.Button, { icon: free_solid_svg_icons_1.faTrash, className: "removeButton", onClick: () => handleRemove(i) }))] }));
            }), !props.fixedAmount && ((0, jsx_runtime_1.jsx)(astra_1.Button, { icon: free_solid_svg_icons_1.faPlus, className: "addButton", onClick: () => handleAdd() }))] }));
};
exports.PeriodsPicker = PeriodsPicker;
