"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchSelect = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
require("./SearchSelect.scss");
const Search_1 = require("../Search/Search");
const ViewComposer_1 = require("../../core/ViewComposer/ViewComposer");
function SearchSelect(props) {
    const sunContext = (0, ViewComposer_1.useSunContext)();
    const [state, setState] = (0, react_1.useState)({ value: "", searchResults: [], modalIsAlreadyOpened: false });
    const stateRef = (0, react_1.useRef)(state);
    stateRef.current = state;
    const inputRef = (0, react_1.useRef)();
    function handleCallback(searchResults, value) {
        console.log("MAIN callback", searchResults?.length, value);
        searchResults ||= [];
        value ||= " ";
        searchResults = searchResults.slice(0, props.limit || 10);
        setState({ ...stateRef.current, searchResults, value: value || "" });
        if (searchResults.length > 0 && typeof value === "string") {
            openFocusMode(searchResults, value);
        }
    }
    function openFocusMode(searchResults, value) {
        console.log("openFocusMode", stateRef.current.modalIsAlreadyOpened);
        const rect = inputRef.current?.getBoundingClientRect();
        if (rect && !stateRef.current.modalIsAlreadyOpened) {
            sunContext.modal({
                className: `SearchSelectModal ${props.className || ""}`,
                raw: true,
                delay: 0,
                form: (resolve, reject) => (0, jsx_runtime_1.jsx)(SearchSelectListModal, { ...props, resolve: resolve, reject: reject, searchResults: searchResults, value: stateRef.current.value, inputRect: rect, inputRef: inputRef, callback: handleCallback })
            })
                .then((item) => {
                props.onSelect && props.onSelect(item);
                setState({ ...stateRef.current, modalIsAlreadyOpened: false });
            })
                .catch(() => {
                setState({ ...stateRef.current, modalIsAlreadyOpened: false });
            });
            setState({ ...stateRef.current, modalIsAlreadyOpened: true });
        }
    }
    console.log("MAIN render", stateRef.current.value);
    return ((0, jsx_runtime_1.jsx)("div", { className: "SearchSelect", children: (0, jsx_runtime_1.jsx)(Search_1.Search, { ...props, value: stateRef.current.value, callback: handleCallback, reference: inputRef }) }));
}
exports.SearchSelect = SearchSelect;
function SearchSelectListModal(props) {
    const [state, setState] = (0, react_1.useState)({
        rect: props.inputRect,
        value: props.value,
        searchResults: props.searchResults
    });
    const stateRef = (0, react_1.useRef)(state);
    stateRef.current = state;
    const resize = () => {
        const newRect = props.inputRef.current?.getBoundingClientRect();
        if (newRect)
            setState({ ...stateRef.current, rect: newRect });
    };
    (0, react_1.useEffect)(() => {
        window.addEventListener("resize", resize);
        return () => {
            window.removeEventListener("resize", resize);
        };
    }, []);
    function handleClick(index) {
        console.log("handleclick");
        props.resolve(state.searchResults[index]);
    }
    function handleCallback(searchResults, value) {
        console.log("MODAL callback", searchResults?.length, value);
        searchResults ||= [];
        value ||= "";
        searchResults = searchResults.slice(0, props.limit || 10);
        setState({ ...stateRef.current, searchResults, value });
        props.callback(searchResults, value);
    }
    const rect = props.inputRef.current?.getBoundingClientRect() ? stateRef.current.rect : props.inputRect;
    const style = {
        left: `${rect.left}px`,
    };
    const inputStyle = {
        ...style,
        top: `${rect.top}px`,
        width: `${rect.width}px`,
        height: `${rect.height}px`
    };
    const listStyle = {
        ...style,
        minWidth: `${rect.width}px`,
    };
    if (rect.top < window.innerHeight / 2) {
        listStyle.top = `${rect.top + rect.height}px`;
    }
    else {
        listStyle.bottom = `${window.innerHeight - rect.bottom + rect.height}px`;
    }
    console.log("MODAL render", stateRef.current.value);
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(Search_1.Search, { ...props, style: inputStyle, callback: handleCallback, value: stateRef.current.value, autofocus: true }), stateRef.current.searchResults.length > 0 && ((0, jsx_runtime_1.jsx)("ul", { className: "SelectList", style: listStyle, children: stateRef.current.searchResults.map((item, i) => ((0, jsx_runtime_1.jsx)("li", { onClick: () => handleClick(i), children: props.display(item) }, i))) }))] }));
}
